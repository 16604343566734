export default {
  mutzarim: [
    { sug_mutzar: 1, str_sug_mutzar: "ביטוח מנהלים - 1" },
    { sug_mutzar: 5, str_sug_mutzar: "ביטוח מנהלים - 5" },
    { sug_mutzar: 2, str_sug_mutzar: "קרן פנסיה - 2" },
    { sug_mutzar: 3, str_sug_mutzar: "קופת גמל - 3" },
    { sug_mutzar: 4, str_sug_mutzar: "קרן השתלמות - 4" },
    { sug_mutzar: 9, str_sug_mutzar: "קופת גמל להשקעה - 9" }
  ],
  kisuim: [
    { sug_kisui: 1, str_sug_kisui: "1 - כיסוי למקרה מוות" },
    { sug_kisui: 2, str_sug_kisui: "2 - נכות מקצועית" },
    { sug_kisui: 3, str_sug_kisui: "3 - מוות מתאונה" },
    { sug_kisui: 4, str_sug_kisui: "4 - נכות מתאונה" },
    { sug_kisui: 5, str_sug_kisui: "5 - אובדן כושר עבודה" },
    { sug_kisui: 5.99, str_sug_kisui: "5.99 - מטריה - אובדן כושר עבודה" },
    { sug_kisui: 6, str_sug_kisui: "6 - שחרור" },
    { sug_kisui: 7, str_sug_kisui: "7 - מחלות קשות" },
    { sug_kisui: 8, str_sug_kisui: "8 - תוכנית משולבת חיסכון" },
    { sug_kisui: 9, str_sug_kisui: "9 - מוות + אבדן כושר עבודה" },
    { sug_kisui: 10, str_sug_kisui: "10 - אחר" },
    { sug_kisui: 2.1, str_sug_kisui: "2.1 - פנסיית שארים" },
    { sug_kisui: 2.5, str_sug_kisui: "2.5 - פנסיית נכות" },
    { sug_kisui: undefined, str_sug_kisui: "לא ידוע" }
  ],
  relations: [
    "בעל",
    "אישה",
    "בן זוג",
    "בת זוג",
    "אח",
    "אחות",
    "בן",
    "בת",
    "אב",
    "אם",
    "נכד",
    "נכדה",
    "סבא",
    "סבתא",
    "גרוש",
    "גרושה"
  ],
  sug_zika: [
    { value: 1, text: "הורים"},
    { value: 2, text: "בן/בת זוג"},
    { value: 3, text: "ילד/ה"},
    { value: 4, text: "אח/אחות"},
    { value: 5, text: "מעניק מתנה"},
    { value: 6, text: "אפוטרופוס"},
    { value: 7, text: "מעסיק"},
    { value: 8, text: "אחר"},
    { value: 9, text: "בנק מלווה (עבור משכנתאות)"},
    { value: 10, text: "מוסדי מלווה (עבור משכנתאות)"},
    { value: 11, text: "מלווה אחר (עבור משכנתאות)"}
  ],
  min: [
    { value: 1, display: "זכר" },
    { value: 2, display: "נקבה" }
  ],
  signatureTypes: [
    { name: "client_signature", display: "חתימת לקוח", relation: ["לקוח"] },
    { name: "advisor_signature", display: "חתימת יועץ", relation: ["יועץ"] },
    {
      name: "parent_signature",
      display: "חתימת הורה",
      relation: ["אב", "אם"]
    },
    {
      name: "both_parents_signature",
      display: "חתימת שני הורים",
      relation: ["אב", "אם"]
    },
    { name: "father_signature", display: "חתימת אב", relation: ["אב"] },
    { name: "mother_signature", display: "חתימת אם", relation: ["אם"] },
    {
      name: "spouse_signature",
      display: "חתימת בן/בת זוג",
      relation: ["בעל", "אישה", "בן זוג", "בת זוג"]
    }
  ],
  statusOptions: {
    completed: { color: "success", display: "הושלם" },
    stuck: { color: "error", display: "תקוע" },
    inProgress: { color: "orange", display: "עובדים על זה" },
    "": { color: "grey lighten-1", display: "בחר סטאטוס" }
  },
  priorityOptions: {
    urgent: { color: "deep-orange accent-4", display: "דחוף", level: 6 },
    important: { color: "orange darken-3", display: "חשוב", level: 5 },
    notice: { color: "amber lighten-2", display: "לתשומת לבך", level: 4 },
    "non-urgent": { color: "lime accent-4", display: "לא דחוף", level: 3 },
    info: { color: "light-green darken-1", display: "לידיעה", level: 2 },
    low: { color: "teal lighten-1", display: "בזמנך הפנוי", level: 1 },
    "": { color: "grey lighten-1", display: "בחר דחיפות", level: 0 }
  },
  clientApprovalOptions: {
    approved: { color: "success", display: "לקוח אישר" },
    decliend: { color: "deep-orange accent-4", display: "לקוח סירב" },
    "": { color: "grey lighten-1", display: "אישור לקוח" }
  },
  subscriptions: {
    none: {
      color: "grey",
      text: "ללא מנוי",
      icon: "mdi-cancel",
      hasExp: false
    },
    intro: {
      color: "purple",
      text: "היכרות",
      icon: "mdi-handshake-outline",
      hasExp: true
    },
    free: {
      color: "blue",
      text: "מתמשך חינם",
      icon: "mdi-currency-usd-off",
      hasExp: true
    },
    regular: {
      color: "green",
      text: "מתמשך",
      icon: "mdi-repeat",
      hasExp: true
    },
    vip: {
      color: "orange",
      text: "ליווי מתמשך",
      icon: "mdi-octagram",
      hasExp: true
    }
  }
};
