import { checkIfConditionsMatch, compare } from "../../utils/expresssions.js";

import fieldResolvers from "./fieldResolvers.js";

class Context {
  constructor(id, display, keyField) {
    this.id = id;
    this.display = display;
    this.keyField = keyField;
  }

  populate(/* report, analysis */) {}
}

class CustomContext extends Context {
  constructor(id, display, keyField, populate) {
    super(id, display, keyField);
    this.populate = populate;
  }
}

class MutzarimContext extends Context {
  constructor(id, display, keyField, { groupType, before, reference }) {
    super(id, display, keyField);
    this.groupType = groupType;
    this.before = before;
    this.reference = reference;
  }

  populate(report, analysis, reference) {
    let items = [];
    let reportToUse = this.reference ? reference : report;

    if (!reportToUse || !reportToUse.polisot) {
      return items;
    }

    for (let polisa of reportToUse.polisot) {
      for (let group of polisa.groups) {
        if (group.type == this.groupType) {
          let noChange =
            group.action == "select" || group.action == "no change";
          if (this.before || noChange) {
            items.push(...group.items);
          } else {
            items.push(...group.items_after);
          }
        }
      }
    }
    return items;
  }
}

function addIndexFieldToRecords(records) {
  return records.map((record, index) => ({ ...record, index: index + 1 }));
}

function populateIncomes(report, simulation) {
  let items = addIndexFieldToRecords(
    report.info.budget ? report.info.budget.incomes : []
  );

  if (simulation && simulation.products_at_prisha) {
    for (let company of simulation.products_at_prisha) {
      if (company.kitzba_chodshei_neto > 0) {
        items.push({
          category: "קצבה חודשית",
          shem_tashlum: company.shem_yatzran,
          taarich_techilat_tashlum: "מגיל פרישה",
          taarich_sium_tashlum: "",
          schum_tashlum: company.kitzba_chodshei_neto,
          tadirut_tashlum: "חודשי",
          achuz_tashlum: 100,
          achuz_tashlum_death: 0
        });
      }
      if (company.honi > 0) {
        items.push({
          category: "הכנסה חד פעמית",
          shem_tashlum: company.shem_yatzran,
          taarich_techilat_tashlum: "בגיל פרישה",
          taarich_sium_tashlum: "",
          schum_tashlum: company.honi,
          tadirut_tashlum: "חד פעמי",
          achuz_tashlum: 100,
          achuz_tashlum_death: 0
        });
      }
    }
  }

  return addIndexFieldToRecords(items);
}

function populateRecommendations(report) {
  let result = [];

  let details = {
    title: "המלצות כלליות"
  };
  if (report.recommendations) {
    result.push(...report.recommendations.map((r) => ({ ...r, ...details })));
  }
  for (let polisa of report.polisot) {
    for (let group of polisa.groups) {
      let firstRow;
      let polisaRecommendations = [];
      if (group.items) {
        for (let row of group.items) {
          if (!firstRow) {
            firstRow = row;
          }
          if (row.recommendations) {
            polisaRecommendations.push(...row.recommendations);
          }
        }
      }
      if (group.items_after) {
        for (let row of group.items_after) {
          if (!firstRow) {
            firstRow = row;
          }
          if (row.recommendations) {
            polisaRecommendations.push(...row.recommendations);
          }
        }
      }

      if (polisaRecommendations.length > 0) {
        let details = {
          title: `מעקב מוצר #${firstRow.index} - ${firstRow.str_sug_mutzar} - ${firstRow.shem_yatzran} - ${firstRow.mispar_heshbon}`,
          polisa_index: firstRow.index,
          sug_mutzar: firstRow.sug_mutzar,
          str_sug_mutzar: firstRow.str_sug_mutzar,
          shem_yatzran: firstRow.shem_yatzran,
          mispar_heshbon: firstRow.mispar_heshbon,
          action: group.action
        };
        result.push(
          ...polisaRecommendations.map((r) => ({ ...r, ...details }))
        );
      }
    }
  }

  return result;
}

function flatten(object, field) {
  let values = object[field];
  delete object[field];
  for (let key in values) {
    object[`${field}_${key}`] = values[key];
  }
}

function populatePeriods(periods) {
  let records = [];

  if (!periods) {
    return records;
  }

  let keys = Object.keys(periods);
  for (let key of keys) {
    let period = {
      ...periods[key],
      id: key
    };

    flatten(period, "start_withdraw");
    flatten(period, "start_incomes");
    flatten(period, "start_expenses");
    flatten(period, "end_withdraw");
    flatten(period, "end_incomes");
    flatten(period, "end_expenses");

    if (period.length_in_months > 0) {
      records.push(period);
    }
  }
  return records;
}
class FilteredContext {
  constructor(context, filters) {
    this.parent = context;
    this.filters = filters;

    this.filterData();
  }

  filterData() {
    this.contexts = {};

    for (let contextName in this.parent.contexts) {
      let items = this.parent.contexts[contextName];
      let filters = this.filters[contextName];
      if (filters) {
        let filterFunction = this.buildFilterFunction(contextName, filters);
        items = items.filter(filterFunction);
      }
      this.contexts[contextName] = items;
    }
  }

  buildFilterFunction(category, filter) {
    return this.parent.buildFilterFunction(category, filter);
  }

  applyFilterByConditions(category, conditions) {
    if (conditions && conditions.length > 0) {
      return this.getFilteredContext({
        [category]: {
          conditions
        }
      });
    } else {
      return this;
    }
  }

  getFilteredContext(filters) {
    if (filters && Object.keys(filters).length > 0) {
      return new FilteredContext(this, filters);
    } else {
      return this;
    }
  }

  aggregate(array, aggregation) {
    switch (aggregation) {
      case "min":
        if (array.length == 0) {
          return undefined;
        }
        return Math.min(...array.map((x) => +x));
      case "max":
        if (array.length == 0) {
          return undefined;
        }
        return Math.max(...array.map((x) => +x));
      case "count":
        return array.length;
      case "distinct": {
        let keys = {};
        for (let item of array) {
          keys[item] = true;
        }
        return Object.keys(keys).length;
      }
      case "sum":
        if (array.length == 0) {
          return undefined;
        }
        return array.reduce((s, x) => s + (+x || 0), 0);
      case "average":
        if (array.length == 0) {
          return undefined;
        }
        return array.reduce((s, x) => s + (+x || 0), 0) / array.length;
      case "first":
        return array[0];
      case "last":
        return array[array.length - 1];
      case "values": {
        let flattenArray = array
          .map((x) => (Array.isArray(x) ? x : [x]))
          .reduce((a, b) => a.concat(b), []);
        return [...new Set(flattenArray)].join(", ");
      }
      default:
        return array[0];
    }
  }

  aggregateField(items, fieldOrCallback, aggregation) {
    let extractValue;
    if (typeof fieldOrCallback == "string") {
      extractValue = (item) => item[fieldOrCallback];
    } else {
      extractValue = fieldOrCallback;
    }
    if (!items) {
      return undefined;
    } else {
      let values = [];
      for (let item of items) {
        let value = extractValue(item);
        if (value !== undefined) {
          values.push(value);
        }
      }
      return this.aggregate(values, aggregation);
    }
  }

  getFieldDetails(category, field) {
    return this.parent.getFieldDetails(category, field);
  }

  enrichFieldsWithDetails(category, fields) {
    return fields.map((field) => {
      let fieldDetails = this.getFieldDetails(category, field.field);
      if (fieldDetails) {
        return {
          ...field,
          display: field.display || fieldDetails.display,
          fieldType: fieldDetails.fieldType,
          ltr: fieldDetails.ltr
        };
      } else {
        return field;
      }
    });
  }

  resolveValue(category, field, aggregation, items) {
    let fieldDetails = this.getFieldDetails(category, field);
    items = items || this.contexts[category];

    if (fieldDetails && fieldDetails.resolve) {
      return fieldDetails.resolve(items, aggregation, this);
    } else {
      return this.aggregateField(
        items,
        (fieldDetails && fieldDetails.getValue) || field,
        aggregation
      );
    }
  }

  resolveValueAndDisplay(category, field, aggregation, items) {
    let fieldDetails = this.getFieldDetails(category, field);
    let value = this.resolveValue(category, field, aggregation, items);

    if (!fieldDetails) {
      return {
        field: field,
        fieldValue: value,
        context: category,
        aggregation: aggregation
      };
    }

    let result = {
      field: field,
      display: fieldDetails.display,
      ltr: fieldDetails.ltr,
      fieldValue: value,
      context: category,
      aggregation: aggregation,
      fieldType: fieldDetails.fieldType
    };

    if (aggregation == "count" || aggregation == "distinct") {
      result.fieldType = "count";
    } else if (aggregation == "sum" || aggregation == "average") {
      //don't try to resolve value...
    } else {
      if (fieldDetails.resolveDisplayValue) {
        result.fieldDisplay = fieldDetails.resolveDisplayValue(
          result.fieldValue,
          this
        );
      } else if (fieldDetails.displayValues) {
        let displayValue = fieldDetails.displayValues.find(
          (v) => v.value == result.fieldValue
        );
        if (displayValue) {
          result.fieldDisplay = displayValue.display;
        } else if (fieldDetails.missingValue) {
          result.fieldDisplay = fieldDetails.missingValue;
        }
      }
    }

    return result;
  }

  groupContextBy(category, groupByField, items = null) {
    items = items || this.contexts[category] || [];

    if (groupByField) {
      let groups = {};
      for (let item of items) {
        let groupKey = this.resolveValue(category, groupByField, "first", [
          item
        ]);
        if (!(groupKey in groups)) {
          groups[groupKey] = [];
        }
        groups[groupKey].push(item);
      }
      items = Object.values(groups);
    } else {
      items = items.map((item) => [item]);
    }
    return items;
  }

  /**
   * resolve fields into array
   * @param {string} category category to resolve on
   * @param {FieldDefinition[]} fields array of fields definitions
   * @param {any[] | undefined} items items to resolve on or undefined to use category default items
   * @returns array of resolved values.
   */
  resolveFieldsForItems(category, fields, items = null) {
    items = items || this.contexts[category];

    let results = fields.map((field) =>
      this.resolveValueAndDisplay(
        category,
        field.field,
        field.aggregation,
        items
      )
    );

    return results;
  }

  /**
   * resolve fields into an object
   * @param {string} category category to resolve on
   * @param {FieldDefinition[]} fields array of fields definitions
   * @param {any[] | undefined} items items to resolve on or undefined to use category default items
   * @returns object[field-name] = field display value.
   */
  extractFieldsForItems(category, fields, items = null) {
    let values = this.resolveFieldsForItems(category, fields, items);
    let result = {};
    for (let value of values) {
      result[value.field] = value.fieldDisplay || value.fieldValue;
    }
    return result;
  }

  interpolateElements(elements, items = undefined) {
    if (elements === undefined || elements === null || elements === "") {
      return undefined;
    }

    if (typeof elements == "string") {
      return [{ type: "string", value: elements }];
    }

    let result = [];

    for (let element of elements) {
      if (typeof element == "string") {
        result.push({ type: "string", value: element });
      } else if (typeof element == "object" && "field" in element) {
        let resolvedElement = this.resolveValueAndDisplay(
          element.context,
          element.field,
          element.aggregation,
          items
        );
        let value = resolvedElement.fieldDisplay || resolvedElement.fieldValue;

        if (value !== undefined && value !== null) {
          result.push({ ...resolvedElement, type: "element" });
        }
      } else {
        throw new Error("unsupported element: " + JSON.stringify(element));
      }
    }

    return result;
  }

  interpolate(elements, items = undefined) {
    if (elements === undefined || elements === null) {
      return undefined;
    }

    if (typeof elements == "string") {
      return elements;
    }

    let resolvedElements = this.interpolateElements(elements, items);

    let result = "";

    for (let resolvedElement of resolvedElements) {
      switch (resolvedElement.type) {
        case "string":
          result += resolvedElement.value;
          break;
        case "element":
          {
            let value =
              resolvedElement.fieldDisplay || resolvedElement.fieldValue;

            if (resolvedElement.fieldType == "money") {
              let digits = Math.abs(resolvedElement.fieldValue < 1) ? 2 : 0;
              value =
                resolvedElement.fieldValue.toLocaleString("us", {
                  minimumFractionDigits: digits,
                  maximumFractionDigits: digits
                }) + " \u20AA";
            }

            if (value !== undefined && value !== null) {
              result += value;
            }
          }
          break;
      }
    }

    return result;
  }

  extractFieldFilterValue(fieldFilter) {
    let filterContext = this.applyFilterByConditions(
      fieldFilter.context,
      fieldFilter.conditions
    );
    let value = filterContext.resolveValue(
      fieldFilter.context,
      fieldFilter.field,
      fieldFilter.aggregation
    );
    return value;
  }

  checkFieldFilter(fieldFilter) {
    let value = this.extractFieldFilterValue(fieldFilter);
    return compare(fieldFilter.op, value, fieldFilter.value);
  }
}

export default function install(Vue) {
  let storeModule = new Vue({
    data() {
      return {
        report: undefined,
        analysis: undefined,
        reference: undefined,
        referenceAnalysis: undefined,
        fieldResolvers: fieldResolvers
      };
    },
    computed: {
      ready() {
        return this.report !== undefined && this.analysis !== undefined;
      },
      reportHasItems() {
        if (this.report == undefined) {
          return false;
        }
        for (let polisa of this.report.polisot) {
          for (let group of polisa.groups) {
            if (group.items && group.items.length > 0) {
              return true;
            }
          }
        }
        return false;
      },
      contextTypes() {
        return [
          new CustomContext(
            "clients",
            "לקוח",
            "mispar_zihuy_lakoach",
            (report) => [report.info]
          ),
          new MutzarimContext("mutzarim_before", "מוצרים (מצב קיים)", "id", {
            groupType: "polisa",
            before: true,
            reference: false
          }),
          new MutzarimContext("bituchim_before", "ביטוחים (מצב קיים)", "id", {
            groupType: "bituach",
            before: true,
            reference: false
          }),
          new MutzarimContext("halvaot_before", "הלוואת (מצב קיים)", "id", {
            groupType: "halvaa",
            before: true,
            reference: false
          }),
          new MutzarimContext("mutzarim_after", "מוצרים (המלצות)", "id", {
            groupType: "polisa",
            before: false,
            reference: false
          }),
          new MutzarimContext("bituchim_after", "ביטוחים (המלצות)", "id", {
            groupType: "bituach",
            before: false,
            reference: false
          }),
          new MutzarimContext("halvaot_after", "הלוואת (המלצות)", "id", {
            groupType: "halvaot",
            before: false,
            reference: false
          }),
          new MutzarimContext("mutzarim_reference", "מוצרים (מצב קודם)", "id", {
            groupType: "polisa",
            before: true,
            reference: true
          }),
          new MutzarimContext(
            "bituchim_reference",
            "ביטוחים (מצב קודם)",
            "id",
            {
              groupType: "bituach",
              before: true,
              reference: true
            }
          ),
          new MutzarimContext("halvaot_reference", "הלוואת (מצב קודם)", "id", {
            groupType: "halvaot",
            before: true,
            reference: true
          }),
          new CustomContext("incomes", "הכנסות", "index", (report, analysis) =>
            populateIncomes(report, analysis.before.simulation)
          ),
          new CustomContext("expenses", "הוצאות", "index", (report) =>
            addIndexFieldToRecords(
              report.info.budget ? report.info.budget.expenses : []
            )
          ),
          new CustomContext(
            "relatives",
            "קרובי משפחה",
            "id",
            (report) => report.info.relatives || []
          ),
          new CustomContext(
            "periods_before",
            "סימולציה לתקופה (מצב קיים)",
            "id",
            (report, analysis) =>
              populatePeriods(
                analysis.before.simulation && analysis.before.simulation.periods
              )
          ),
          new CustomContext(
            "periods_after",
            "סימולציה לתקופה (המלצות)",
            "id",
            (report, analysis) =>
              populatePeriods(
                analysis.after.simulation && analysis.after.simulation.periods
              )
          ),
          new CustomContext(
            "coverage_before",
            "כיסוי ביטוחי (מצב קיים)",
            "id",
            (report, analysis) => [analysis.before.death_coverage]
          ),
          new CustomContext(
            "coverage_after",
            "כיסוי ביטוחי (המלצות)",
            "id",
            (report, analysis) => [analysis.after.death_coverage]
          ),
          new CustomContext(
            "months_before",
            "תחזית חודשית (מצב קיים)",
            "id",
            (report, analysis) => analysis.before.prisha_months
          ),
          new CustomContext(
            "months_after",
            "תחזית חודשית (המלצות)",
            "id",
            (report, analysis) => analysis.after.prisha_months
          ),
          new CustomContext("recommendations", "המלצות", "index", (report) =>
            addIndexFieldToRecords(populateRecommendations(report))
          )
        ];
      },
      aggregations() {
        return [
          { value: "sum", text: "סכום" },
          { value: "count", text: "מספר רשומות" },
          { value: "distinct", text: "מספר ערכים שונים" },
          { value: "min", text: "מינימום" },
          { value: "max", text: "מקסימום" },
          { value: "average", text: "ממוצע" },
          { value: "first", text: "רשומה ראשונה" },
          { value: "last", text: "רשומה אחרונה" },
          { value: "values", text: "רשימת ערכים" }
        ];
      },
      operators() {
        return [
          { text: "שווה ל.. (=)", display: "=", value: "eq" },
          { text: "שונה מ.. (!=)", display: "≠", value: "ne" },
          { text: "גדול מ.. (>)", display: ">", value: "gt" },
          { text: "גדול שווה ל.. (>=)", display: "≥", value: "ge" },
          { text: "קטן מ.. (<)", display: "<", value: "lt" },
          { text: "קטן שווה ל.. (<=)", display: "≤", value: "le" },
          { text: "בין.. (between)", display: "בין", value: "bitween" },
          { text: "אחד מ.. (in)", display: "אחד מ", value: "in" }
        ];
      },
      contextTypesDisplay() {
        return this.contextTypes.map((context) => {
          return {
            value: context.id,
            text: context.display
          };
        });
      },
      contexts() {
        let contexts = {};

        if (!this.report) {
          return contexts;
        }

        let report = this.report;
        let analysis = this.analysis;
        let reference = this.reference;
        let referenceAnalysis = this.referenceAnalysis;

        for (let context of this.contextTypes) {
          contexts[context.id] = context.populate(
            report,
            analysis,
            reference,
            referenceAnalysis
          );
        }

        return contexts;
      }
    },
    methods: {
      getFieldDetails(category, field) {
        let fields = this.fieldResolvers[category];
        return fields ? fields[field] : undefined;
      },

      getFilteredContext(filters) {
        return new FilteredContext(this, filters || {});
      },

      buildFilterFunction(category, filters) {
        let conditions = [];
        for (let field in filters) {
          if (field == "conditions") {
            conditions.push((item) =>
              checkIfConditionsMatch(filters.conditions, item.conditions)
            );
          } else {
            let value = filters[field];
            let fieldDetails = this.getFieldDetails(category, field);
            if (fieldDetails.getValue) {
              conditions.push((item) => fieldDetails.getValue(item) == value);
            } else {
              conditions.push((item) => item[field] == value);
            }
          }
        }

        return (item) => {
          if (!conditions.every((cond) => cond(item))) {
            return false;
          }
          return true;
        };
      },

      getContextType(id) {
        return this.contextTypes.find((context) => context.id == id);
      },

      async loadReport(clientId, reportId) {
        if (
          this.report &&
          this.reportKey &&
          this.reportKey.clientId == clientId &&
          this.reportKey.reportId == reportId
        ) {
          return this.report;
        }

        let api = this.$actions.clients.client(clientId).report(reportId);
        await this.loadReportUsing(api);
        this.reportKey = { clientId, reportId };
        return this.report;
      },

      async loadCurrentReport() {
        if (this.report && this.reportKey && this.reportKey == this.report.id) {
          return this.report;
        }

        await this.loadReportUsing(this.$actions.currentReport);
        this.reportKey = this.report.id;
        return this.report;
      },

      async loadReportUsing(api) {
        this.report = undefined;
        this.analysis = undefined;

        let content = await api.content();

        this.report = content.report;
        this.analysis = content.analysis;
        this.reference = content.reference;
        this.referenceAnalysis = content.referenceAnalysis;
      },

      resetState() {
        this.report = undefined;
        this.analysis = undefined;
        this.reportKey = {};
      }
    }
  });

  storeModule.$account.$on("logout", () => storeModule.resetState());

  return storeModule;
}
