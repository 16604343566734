<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <context-selector v-model="card.contextA"></context-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <context-selector v-model="card.contextB"></context-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-switch
            class="mt-0"
            inset
            dense
            hide-details
            v-model="card.useFilters"
            label="סינון נתונים"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row dense v-if="card.useFilters !== false">
        <v-col>
          <conditions-editor v-model="card.conditions"></conditions-editor>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <field-selector
            label="קיבוץ שורות לפי שדה"
            v-model="card.groupBy"
            :context="card.contextA"
            nullable
          ></field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <complex-string-editor
            v-model="card.groupByTitle"
            @start-edit="startEditComplexString(card, 'groupByTitle')"
            label="כותרת לקבוצת שורות"
          ></complex-string-editor>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <field-selector
            label="קיבוץ שורות לפי שדה (משני)"
            v-model="card.groupBy2"
            :context="card.contextA"
            nullable
          ></field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <complex-string-editor
            v-model="card.groupBy2Title"
            @start-edit="startEditComplexString(card, 'groupBy2Title')"
            label="כותרת לקבוצת שורות (משני)"
          ></complex-string-editor>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <ordered-list-editor
            key="list"
            :list="card.fields"
            new-item-label="הוסף עמודה"
            :create-new-item="newListField"
          >
            <template v-slot="{ item }">
              <v-row dense>
                <v-col>
                  <field-selector
                    v-model="item.field"
                    :context="card.contextA"
                  ></field-selector>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <aggregation-selector
                    v-model="item.aggregation"
                  ></aggregation-selector>
                </v-col>
              </v-row>
              <v-row dense
                ><v-col>
                  <v-text-field
                    dense
                    outlined
                    v-model="item.display"
                    label="כותרת"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-checkbox label="פתוח" v-model="item.opened" hide-details dense></v-checkbox>
                </v-col>
              </v-row>
            </template>
          </ordered-list-editor>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AggregationSelector from "../AggregationSelector.vue";
import ComplexStringEditor from "../ComplexStringEditor.vue";
import ConditionsEditor from "../ConditionsEditor.vue";
import ContextSelector from "../ContextSelector.vue";
import FieldSelector from "../FieldSelector.vue";
import OrderedListEditor from "../OrderedListEditor.vue";

export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {
    FieldSelector,
    AggregationSelector,
    ConditionsEditor,
    OrderedListEditor,
    ContextSelector,
    ComplexStringEditor
  },
  data() {
    return {};
  },
  methods: {
    newListField() {
      let context = this.$store.dataContext.getContextType(this.card.context);
      let field = context.keyField;
      return {
        field: field,
        aggregation: "first"
      };
    },
    startEditComplexString(item, field) {
      this.$emit("edit-complex-string", item, field);
    }
  }
};
</script>

<style></style>
