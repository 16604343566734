const listCardResolver = {
  supported(card) {
    return card.type == "CHIPS";
  },
  resolveCard(context, card) {
    let filteredContext = context.applyFilterByConditions(
      card.context,
      card.conditions
    );

    let items = filteredContext.groupContextBy(card.context, card.groupBy);

    let allFields = card.fields.slice();
    if (card.groupBy) {
      allFields.unshift({
        groupBy: true,
        field: card.groupBy,
        aggregation: "first"
      });
    }

    let fields = filteredContext.enrichFieldsWithDetails(
      card.context,
      allFields
    );

    let finalItems = items.map((itemsGroup) => {
      let item = {
        key: undefined,
        fields: []
      };
      for (let field of fields) {
        let result = filteredContext.resolveValueAndDisplay(
          card.context,
          field.field,
          field.aggregation,
          itemsGroup
        );
        if (field.class) {
          result.class = field.class;
        }
        if (field.groupBy) {
          item.key = result.fieldValue;
        } else {
          item.fields.push(result);
        }
      }
      return item;
    });

    return {
      items: finalItems,
      ...card,
      title: filteredContext.interpolateElements(card.title)
    };
  }
};

export default listCardResolver;
