import moment from "moment";

export function moneyField(display, options = {}) {
  return {
    display,
    fieldType: "money",
    ...options
  };
}

export function percentField(display, options = {}) {
  return {
    display,
    fieldType: "percent",
    ...options
  };
}

export function dateField(display, options = {}) {
  return {
    display,
    fieldType: "date",
    ...options
  };
}

export function lookupField(display, valueDisplay, options = {}) {
  return {
    display,
    displayValues: Object.keys(valueDisplay).map((key) => ({
      value: key,
      display: valueDisplay[key]
    })),
    ...options
  };
}

export function timeInYearsBetweenField(display, from, to) {
  return {
    display,
    getValue(item) {
      let toDate = to ? new moment(item[to], "YYYY-MM-DD") : new moment();
      let fromDate = from ? new moment(item[from], "YYYY-MM-DD") : new moment();
      let years = toDate.diff(fromDate, "year", true);
      return years.toFixed(1);
    },
    fieldType: "years"
  };
}

export function dateFromGil(display, gilField) {
  return {
    display,
    getValue(item) {
      let taarich_leyda = new moment(item.taarich_leyda, "YYYY-MM-DD");
      let gil = +item[gilField];
      return taarich_leyda.add(gil, "years").format("YYYY-MM-DD");
    },
    fieldType: "date"
  };
}

export function weightedAverage(field, weightField) {
  return function (items, aggregation, context) {
    if (aggregation == "average") {
      let total_weights = context.aggregateField(items, weightField, "sum");
      if (total_weights > 0) {
        let field_total = context.aggregateField(
          items,
          (item) => +item[field] * +item[weightField],
          "sum"
        );
        return field_total / total_weights;
      } else {
        return 0;
      }
    } else {
      return context.aggregateField(items, field, aggregation);
    }
  };
}

export function weightedPercentField(
  display,
  field,
  weightField,
  options = {}
) {
  return {
    display,
    fieldType: "percent",
    resolve: weightedAverage(field, weightField),
    ...options
  };
}
