<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <v-select
            label="סגנון"
            v-model="card.layout"
            :items="chipsLayout"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <field-selector
            label="קיבוץ שורות לפי שדה"
            v-model="card.groupBy"
            :context="card.context"
            nullable
          ></field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <ordered-list-editor
            key="chip"
            :list="card.fields"
            new-item-label="הוסף שדה"
            :create-new-item="newListField"
          >
            <template v-slot="{ item }">
              <v-row dense>
                <v-col>
                  <field-selector
                    v-model="item.field"
                    :context="card.context"
                  ></field-selector>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <aggregation-selector
                    v-model="item.aggregation"
                  ></aggregation-selector>
                </v-col>
              </v-row>
              <v-row dense
                ><v-col>
                  <v-text-field
                    dense
                    outlined
                    v-model="item.class"
                    label="CSS"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </ordered-list-editor>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AggregationSelector from "../AggregationSelector.vue";
import FieldSelector from "../FieldSelector.vue";
import OrderedListEditor from "../OrderedListEditor.vue";

export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {
    FieldSelector,
    AggregationSelector,
    OrderedListEditor
  },
  data() {
    return {
      chipsLayout: [
        { value: "", text: "רגיל" },
        { value: "centered", text: "מרכז" }
      ]
    };
  },
  methods: {
    newListField() {
      let context = this.$store.dataContext.getContextType(this.card.context);
      let field = context.keyField;
      return {
        field: field,
        aggregation: "first"
      };
    }
  }
};
</script>

<style></style>
