import { dateField, lookupField, moneyField, percentField } from "./utils";

let halvaotResolver = {
  id: {
    display: "מזהה הלוואה",
    getValue(item) {
      return `${item.index}:${item.mispar_heshbon}`;
    }
  },
  mispar_siduri_shel_hahalvaa: { display: "מספר הלוואה", ltr: true },
  mispar_heshbon: { display: "מספר חשבון", ltr: true },
  sug_mutzar: lookupField("סוג מוצר", {
    1: "ביטוח מנהלים",
    2: "קרן פנסיה",
    3: "קופת גמל",
    4: "קרן השתלמות",
    5: "ביטוח מנהלים",
    6: "ביטוח חיים",
    7: "ביטוח חיים",
    8: "ביטוח קולקטיב",
    9: "קופת גמל להשקעה"
  }),

  shem_yatzran: { display: "חברה מנהלת" },
  kod_mezahe_yatzran: { display: "ח.פ חברה מנהלת" },
  yatzran_logo: {
    display: "לוגו חברה מנהלת",
    getValue(item) {
      return item.kod_mezahe_yatzran;
    },
    fieldType: "logo"
  },
  taarich_kabalat_halvaa: dateField("תאריך קבלת הלוואה"),
  taarich_siyum_halvaa: dateField("תאריך סיום הלוואה"),

  tags: { display: "קטגוריות", type: "chips", multiple: true },

  index: { display: "מס מעקב" },
  maslul_index: { display: "אינדקס מסלול השקעה" },
  taarich_nechonut: dateField("תאריך נכונות מידע"),

  polisa_str: { display: "מספר מערכת" },

  schum_halvaa: moneyField("סכום הלוואה"),
  yitrat_halvaa: moneyField("יתרה לתשלום"),

  yesh_halvaa_bamutzar: lookupField("כלול במוצר פנסיוני", {
    1: "כן",
    2: "לא"
  }),

  ramat_halvaa: lookupField("הלוואה מתוך", {
    1: "מוצר",
    2: "כל המוצרים של שלך"
  }),

  tkufat_halvaa: { display: "תקופת הלוואה (חודשים)" },

  ribit: percentField("ריבית"),

  sug_ribit: lookupField("סוג ריבית", {
    1: "קבועה",
    2: "משתנה"
  }),

  sug_hatznmada: lookupField("סוג הצמדה", {
    1: "לא צמוד",
    2: "צמוד למדד חודשי",
    3: "צמוד למדד חצי ",
    4: "אחר"
  }),

  tadirut_hechzer_halvaa: lookupField("תדירות החזר הלוואה", {
    1: "חודשי",
    2: "רבעוני",
    3: "חצי שנתי",
    4: "שנתי",
    5: "אחר"
  }),

  sug_hechzer: lookupField("סוג החזר", {
    0: "לא התקבל מידע",
    1: "שפיצר",
    2: "ריבית שוטפת"
  }),

  schum_hechzer_tkufati: moneyField("החזר תקופתי ב-₪")
};

export default halvaotResolver;
