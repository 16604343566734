import { dateField, lookupField, timeInYearsBetweenField } from "./utils";

let relativesResolver = {
  id: { display: "ת'ז" },
  min: lookupField("מין", { 1: "זכר" }, { 2: "נקבה" }),
  relation: { display: "קרבה" },
  shem_prati: { display: "שם פרטי" },
  shem_mishpacha: { display: "שם מפשחה" },
  taarich_leyda: dateField("תאריך לידה"),
  gil: timeInYearsBetweenField("גיל", "taarich_leyda")
};

export default relativesResolver;
