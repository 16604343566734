<template>
  <v-chip :small="small" :color="`${color} lighten-3`">
    <v-icon left :color="color" :small="small" @click="emitToggle()">{{
      icon
    }}</v-icon
    >{{ filter }}
    <v-icon v-if="canRemove" right :small="small" @click="emitRemove($event)"
      >mdi-close</v-icon
    >
  </v-chip>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    small: { type: Boolean, default: false },
    canRemove: { type: Boolean, default: false }
  },
  computed: {
    positive() {
      return !this.value.startsWith("!");
    },
    color() {
      return this.positive ? "green" : "red";
    },
    icon() {
      return this.positive
        ? "mdi-check-circle-outline"
        : "mdi-circle-off-outline";
    },
    filter() {
      return this.positive ? this.value : this.value.substr(1);
    }
  },
  methods: {
    emitToggle() {
      this.$emit(
        "input",
        this.positive ? "!" + this.value : this.value.substr(1)
      );
    },
    emitRemove(event) {
      this.$emit("remove", event);
    }
  }
};
</script>

<style></style>
