<template>
  <div class="login d-flex justify-center align-center" style="height: 100vh">
    <div class="background">
      <!-- <v-img
        src="@/assets/backgrounds/background2-min.jpg"
        height="100%"
        position="top right"
      ></v-img> -->
    </div>
    <div
      style="position: absolute"
      class="logo text-center d-flex justify-center align-center flex-column"
      :class="{ done, interactive, fullscreen }"
    >
      <div class="image">
        <v-img
          src="@/assets/logo.png"
          :height="fullscreen ? 150 : 300"
          contain
          @load="animate"
        ></v-img>
      </div>
      <div class="slogan display-1">
        <span class="logo-grey">מחוברים לעולם הפנסיוני פיננסי שלך</span>
        <!-- <span class="blue--text">זמין יותר</span>
        <div class="box"></div>
        <span class="red--text">פשוט יותר</span>
        <div class="box"></div>
        <span class="green--text">חכם יותר</span> -->
      </div>
      <div class="gap"></div>
      <user-authentication-card
        class="login-card elevation-0 after pa-5"
        :can-register="false"
        @verification-passed="navigateAfterAuth"
        :width="fullscreen ? '95%' : 550"
      >
        <template #title>
          <div class="display-1 text-center d-justify-center">ברוכים הבאים</div>
        </template>
      </user-authentication-card>
      <div class="text-center">
        <small class="google-copy-rights grey--text"
          >This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          apply.
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import UserAuthenticationCard from "@/components/auth/UserAuthenticationCard.vue";

export default {
  components: { UserAuthenticationCard },
  data() {
    return {
      done: false,
      interactive: false
    };
  },
  computed: {
    fullscreen() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  methods: {
    animate() {
      window.setTimeout(() => {
        this.done = true;
        window.setTimeout(() => {
          this.interactive = true;
        }, 500);
      }, 100);
    },
    async navigateAfterAuth() {
      if (this.$account.returnTo && this.$account.returnTo != "/login") {
        this.$router.replace(this.$account.returnTo);
      } else if (this.$account.isAdmin) {
        this.$router.push({ name: "admin-home" });
      } else {
        this.$router.push({ name: "user-home" });
      }
    }
  }
};
</script>

<style lang="stylus">
.login {
  .background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: radial-gradient(transparent 30%, rgba(40, 40, 70, 0.3)), white;
  }

  .logo {
    opacity: 0;
    transition: all 0.9s ease-out;
    transform: scale(0.5);

    .slogan {
      margin-top: -60px;

      .logo-grey {
        color: #868686;
        letter-spacing: 3.7px;
      }
    }

    .after {
      opacity: 0;
      // transform: translateY(-50px);
      transition: all 0.9s cubic-bezier(.21,0,.38,1.06);
      max-height: 10px;
      overflow: hidden;
    }

    .google-copy-rights {
      transition: all 0.7s ease-out;
      transition-delay: 0.5s;
      opacity: 0;

      a:not(:hover) {
        color: #668;
        text-decoration: none;
      }
    }

    .box {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #888;
      margin: 0 20px;
    }

    .gap {
      margin-top: 50px;
    }

    &.done {
      opacity: 1;
      transform: scale(1);
    }

    &.done.interactive {
      .after {
        transform: translateY(0);
        opacity: 1;
        max-height: 520px;
      }

      .google-copy-rights {
        opacity: 1
      }

    }

    &.fullscreen {
      width: 100%;

      .image {
        width: 80%;
        margin: 0 auto;
      }

      .slogan {
        margin-top: 0px;
        font-size: 24px !important;
        line-height: 32px !important;

        .logo-grey {
          letter-spacing: 0px;
          font-size: 20px;
          top: -32px;
          position relative;
        }
      }

      .box {
        width: 18px;
        height: 18px;
        margin: 0px 10px;
        position: relative;
        top: 2px;
      }
    }
  }
}
</style>
