<template>
  <v-main class="welcome-page" :class="{ done, mobile }">
    <div class="content-box">
      <div
        class="text-center questions"
        :class="{ done }"
        :style="{ maxHeight3: `${(100 * displayedQuestion.length) / 2}px` }"
      >
        <div class="question" v-for="(q, i) in displayedQuestion" :key="i">
          {{ q.text }}
        </div>
        <div
          class="question typing"
          :class="{ new: newQuestion }"
          v-if="showTyping"
          :key="displayedQuestion.length"
        >
          &nbsp;
          <div class="d-inline-block mx-5 dot-typing"></div>
        </div>
      </div>
      <div
        style="position: absolute; width: 100%"
        class="logo text-center d-flex justify-center align-center flex-column"
        :class="{ done, interactive }"
      >
        <div class="after display-3">
          שלום {{ invite.shem_prati }} {{ invite.shem_mishpacha }}
        </div>
        <div class="after title mt-4" v-if="inviteFound">
          אנו שמחים שקבלת את ההזמנה של
          <span class="font-italic font-weight-bold">
            {{ invite.inviter_shem_prati }}
            {{ invite.inviter_shem_mishpacha }}</span
          >
          להתנסות במערכת
        </div>
        <div class="after title" v-else>ברוכים הבאים</div>
        <div class="gap"></div>
        <div class="image">
          <v-img
            src="@/assets/logo.png"
            :height="mobile ? '100px' : '300px'"
            contain
          ></v-img>
        </div>
        <div class="under-image">
          <span class="logo-grey">מחוברים לעולם הפנסיוני פיננסי שלך</span>
          <!-- <span class="blue--text">זמין יותר</span>
          <div class="box"></div>
          <span class="red--text">פשוט יותר</span>
          <div class="box"></div>
          <span class="green--text">חכם יותר</span> -->
        </div>
        <div class="gap"></div>
        <div class="gap"></div>
        <template v-if="inviteFound">
          <div class="after title">השתמשו בקוד הזמנה להתחיל את הסיור</div>
          <div class="after title">
            <code-input
              v-model="inviteCode"
              pattern="XXXX-XXXX"
              @valid="checkInviteCode"
              :verifying="verifying"
              :error-message="error"
              ref="code"
              auto-focus
            ></code-input>
          </div>
        </template>
        <template v-else>
          <div class="after display-1">
            בשלב זה, ההצטרפות למערכת מותנת בהזמנה בלבד
          </div>
          <div class="after title">
            בדקו את תיבת הדואר שלכם האם קבלתם לינק אישי להזמנה.
          </div>
        </template>
      </div>
      <div class="skip" v-if="!done" @click="finishAnimation(0)">דלג</div>
    </div>
  </v-main>
</template>

<script>
import CodeInput from "@/components/auth/CodeInput.vue";

export default {
  components: { CodeInput },
  data() {
    return {
      questionIndex: 0,
      showTyping: false,
      newQuestion: true,
      interval: 3000,
      done: false,
      interactive: false,
      questions: [
        { text: "איפה כל הקופות שלי מפוזרות?" },
        { text: "מתי אוכל למשוך את הכספים שלי?" },
        { text: "כמה מזומן וכמה פנסיה חודשית אקבל?" },
        { text: "כמה דמי ניהול אני משלם בשנה?" },
        { text: "מה התשואות שעשו הקופות שלי?" },
        { text: "מה אחוז המניות שאני חשוף בקופות שלי?" },
        { text: "דמי ניהול נמוכים מול תשואות נמוכות. מה עדיף?" },
        { text: "למי אפשר לפנות כשצריך?" },
        { text: "האם בני המשפחה שלי מוטבים בתוכניות?" },
        { text: "האם המעסיק שלי משלם מידי חודש?" },
        { text: "האם הכספים יספיקו לי בפנסיה?" },
        { text: "מה אקבל בפנסיה אם אפסיק להפקיד היום?" },
        { text: "כמה כסף אני משלם על ביטוחים?" },
        { text: "כמה ביטוח אני צריך באמת?" },
        { text: "כמה כסף זמין לי היום למשיכה?" },
        { text: "מחפש המלצות לשיפורים?" },
        { text: "איך אפשר להתעדכן בשוטף במקום אחד?" }
      ],
      invite: {
        shem_prati: "",
        shem_mishpacha: "",
        invite_id: "",
        inviter_shem_prati: "",
        inviter_shem_mishpacha: "",
        cellular: "",
        email: ""
      },
      error: "",
      inviteCode: "",
      verifying: false
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    displayedQuestion() {
      return this.questions.slice(0, this.questionIndex);
    },
    inviteFound() {
      return this.invite.invite_id != "";
    }
  },
  methods: {
    checkHash() {
      if (window.location.hash.startsWith("#?")) {
        try {
          let inviteInfo = {};
          let decodedHash = window.atob(
            window.location.hash.substr(2).replace("!", "==")
          );
          decodedHash
            .split("&")
            .map((kv) => kv.split("=", 2))
            .forEach((kvarr) => {
              let key = kvarr[0];
              let value = decodeURIComponent(kvarr[1]);
              inviteInfo[key] = value;
            });

          this.invite.shem_prati = inviteInfo.sp || "";
          this.invite.shem_mishpacha = inviteInfo.sm || "";
          this.invite.inviter_shem_prati = inviteInfo.isp || "";
          this.invite.inviter_shem_mishpacha = inviteInfo.ism || "";
          this.invite.invite_id = inviteInfo.id || "";
          this.invite.cellular = inviteInfo.c || "";
          this.invite.email = inviteInfo.e || "";
        } catch (error) {
          this.$tracing.captureException(error);
        }
        window.location.hash = "";
      }
    },
    finishAnimation(delay) {
      window.setTimeout(() => {
        this.done = true;
        window.setTimeout(() => {
          this.interactive = true;
          if (this.$refs.code) {
            this.$refs.code.focus();
          }
        }, 2000);
      }, delay);
    },
    startTypingAnimation() {
      window.setTimeout(() => {
        this.showTyping = true;
        window.setTimeout(() => {
          this.newQuestion = false;
        }, Math.min(100, this.interval / 4));
      }, this.interval / 2);
    },
    triggerNextQuestion() {
      window.setTimeout(() => {
        this.newQuestion = true;
        this.showTyping = false;
        this.interval = Math.max(this.interval - 100, this.interval / 2, 400);
        this.questionIndex = Math.min(
          this.questionIndex + 1,
          this.questions.length
        );
        if (this.questionIndex < this.questions.length) {
          this.startTypingAnimation();
          this.triggerNextQuestion();
        } else {
          this.finishAnimation(3000);
        }
      }, this.interval);
    },
    async navigateAfterAuth() {
      this.$router.push({ name: "demo-home" });
    },
    async checkInviteCode() {
      try {
        this.verifying = true;
        this.error = "";
        await this.$account.authenticateInvite(
          this.invite.invite_id,
          this.inviteCode
        );
        this.$router.push({ name: "demo-home" });
      } catch (error) {
        this.verifying = false;
        this.error = "קוד הזמנה לא תקין";
        this.inviteCode = "";
        return;
      }
    }
  },
  mounted() {
    this.startTypingAnimation();
    this.triggerNextQuestion();
    this.checkHash();
  }
};
</script>

<style lang="stylus">
.welcome-page {
  background: radial-gradient(transparent 0, rgba(0, 0, 0, 0.8)), #222;
  transition: all 0.3s ease-out;

  .content-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  &.done {
    background: radial-gradient(transparent 30%, rgba(40, 40, 70, 0.3)), white;
  }

  .questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    transition: all 0.7s ease-out;
  }

  .questions.done {
    opacity: 0;
    transform: scale(0.1);
  }

  .logo {
    opacity: 0;
    transition: all 0.7s ease-out;
    transform: scale(0.5);

    .under-image {
      font-size: 40px;
      margin-top: -60px;

      .logo-grey {
        color: #868686;
        letter-spacing: 1px;
      }
    }

    .under-image.mobile {
      font-size: 20px;
      line-height: 24px;

      .box {
        width: 16px;
        height: 16px;
        line-height: 15px;
        margin: 0 10px;
      }
    }

    .after {
      opacity: 0;
      transition: opacity 0.7s ease-out;
    }

    .box {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #b3b3bf;
      margin: 0 20px;
    }

    .gap {
      margin-top: 50px;
    }

    &.done {
      opacity: 1;
      transform: scale(1);
    }

    &.done.interactive {
      .after {
        opacity: 1;
      }
    }
  }

  .question {
    display: block;
    height: 4rem;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #0b7aa5;
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    max-width: 600px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 1;
    align-self: center;
    animation: messageFade 15s forwards;

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
      transform: translateY(-0.1rem) scale(1.04);
      transition: all 0.2s ease;
      background: #14a8e1;
    }

    &.typing {
      max-width: 100px;
    }

    &.new {
      max-width: 0;
      transform: scale(0.5);
      opacity: 0;
    }
  }

  .skip {
    position: fixed;
    bottom: 20px;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }

  .skip:hover {
    color: #0b7aa5;
  }
}

.welcome-page.mobile {
  overflow-x: hidden;
  position: relative;

  .content-box {
    align-items: start;
    margin-top: 20px;
    overflow: auto;
    overflow-x: hidden;
  }

  .question {
    font-size: 1rem;
    padding: 1.2rem;
  }

  .logo {
    .gap {
      margin-top: 30px;
    }

    .under-image {
      margin-top: 0px;
      font-size: 20px;
      line-height: 24px;

      .logo-grey {
        font-size: 14px;
        letter-spacing: 0px;
        position: relative;
        top: -20px;
      }

      .box {
        width: 16px;
        height: 16px;
        line-height: 15px;
        margin: 0 10px;
      }
    }
  }

}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  animation: dotTyping 1.2s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  16.667% {
    box-shadow: 9984px -5px 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  50% {
    box-shadow: 9984px 0 0 0 white, 9999px -7px 0 0 white, 10014px 0 0 0 white;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px -5px 0 0 white;
  }

  100% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
}

@keyframes messageFade {
  0% {
    height: 0;
    opactiy: 0;
  }

  3% {
    height: 4rem;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
    margin: 10px 15px;
  }

  60% {
    height: 4rem;
  }

  70% {
    transform: scale(1);
    padding: 1rem;
    opacity: 0;
  }

  100% {
    height: 0rem;
    transform: scale(0.2);
    opacity: 0;
    margin: 0;
    padding: 0;
  }
}
</style>
