import { lookupField } from "./utils";

let recommendationsResolver = {
  id: { display: "מזהה המלצה" },
  index: { display: "מספר מעקב" },
  polisa_index: { display: "מספר מוצר" },

  title: { display: "כותרת" },
  shem_yatzran: { display: "חברה מנהלת" },
  mispar_heshbon: { display: "מספר חשבון", ltr: true },
  sug_mutzar: lookupField(
    "סוג מוצר",
    {
      1: "ביטוח מנהלים",
      2: "קרן פנסיה",
      3: "קופת גמל",
      4: "קרן השתלמות",
      5: "ביטוח מנהלים",
      6: "ביטוח חיים",
      7: "ביטוח חיים",
      8: "ביטוח קולקטיב",
      9: "קופת גמל להשקעה"
    },
    {
      getValue(item) {
        if (item.sug_mutzar == 5) {
          return 1;
        } else {
          return item.sug_mutzar;
        }
      }
    }
  ),
  text: { display: "תאור המצלה" },
  priority: lookupField("עדיפות", {
    "": "ללא עדיפות",
    low: "נמוכה",
    info: "לידיעה",
    "non-urgent": "לא דחוף",
    notice: "לתשומת לבך",
    important: "חשוב",
    urgent: "דחוף"
  }),
  status: lookupField("סטאטוס", {
    completed: "הושלם",
    stuck: "תקוע",
    inProgress: "עובדים על זה",
    "": "ללא סטאטוס"
  }),
  clientApproval: lookupField("אישור לקוח", {
    "": "ללא עדיפות",
    approved: "לקוח אישר",
    decliend: "לקוח סירב"
  }),
  action: lookupField("פעולה", {
    transfer: "לנייד",
    modify: "לשנות מאפיינים",
    cash: "לפדות",
    cancel: "לבטל",
    freeze: "להקפיא",
    select: "להשאיר מצב קיים",
    "no change": "להשאיר מצב קיים",
    new: "מוצר חדש"
  })
};

export default recommendationsResolver;
