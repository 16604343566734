const tsuaCardResolver = {
  supported(card) {
    return card.type == "TSUA_MASLUL";
  },
  resolveCard(context, card) {
    let filteredContext = context.applyFilterByConditions(
      card.context,
      card.conditions
    );
    return {
      field: "tsua_mitztaberet",
      ...card,
      title: filteredContext.interpolateElements(card.title),
      row: filteredContext.contexts[card.context][0]
    };
  }
};

export default tsuaCardResolver;
