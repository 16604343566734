<template>
  <div class="full-screen" :style="containerStyle" ref="container">
    <div class="background"></div>
    <div
      class="main-content"
      :style="contentStyle"
      :class="fixed ? 'fixed' : ''"
      ref="content"
    >
      <div class="main-content-scroll">
        <div v-if="hasHeader" ref="header">
          <slot name="header"></slot>
        </div>
        <div :style="bodyStyle">
          <slot></slot>
        </div>
        <div v-if="hasFooter" ref="footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
    <div v-if="hasActions" class="bottom-actions elevation-8" ref="bottom">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: "100%" },
    maxWidth: { type: [Number, String] },
    fixed: { type: Boolean, default: true }
  },
  data() {
    return {
      containerStyle: {
        width: this.width,
        maxWidth: this.maxWidth,
        margin: "0 auto"
      },
      contentStyle: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        width: "100%"
      },
      bodyStyle: {
        flex: "1 1 100%"
      }
    };
  },
  computed: {
    hasHeader() {
      return !!this.$slots.header;
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
    hasActions() {
      return !!this.$slots.actions;
    }
  },
  methods: {
    fixStyles() {
      if (!this.fixed) {
        this.contentStyle = {
          position: "relative"
        };
        this.bodyStyle.flex = "1 1 auto";
        return;
      }

      let container = this.$refs.container;

      const { bar, top } = this.$vuetify.application;

      let { left, width } = container.getBoundingClientRect();

      let bottom = 0;
      if (this.hasActions) {
        bottom = this.$refs.bottom.clientHeight;
      }

      this.contentStyle = {
        position: "fixed",
        top: `${top + bar}px`,
        bottom: `${bottom}px`,
        left: `${left}px`,
        width: `${width}px`
      };

      let extra = 0;
      if (this.hasHeader) {
        extra += this.$refs.header.clientHeight;
      }
      if (this.hasFooter) {
        extra += this.$refs.footer.clientHeight;
      }

      this.bodyStyle.flex = `1 1 calc(100% - ${extra}px)`;
    }
  },
  mounted() {
    this.fixStyles();
    this.id = setInterval(() => {
      this.fixStyles();
    });
  },
  beforeDestroy() {
    clearInterval(this.id);
  }
};
</script>

<style lang="stylus">
.full-screen {
  .background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: radial-gradient(transparent 30%, rgba(40, 40, 70, 0.2)), white;
  }

  .main-content-scroll {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .main-content-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  .main-content-scroll::-webkit-scrollbar-thumb {
    padding: 1px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .bottom-actions {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
