import moment from "moment";

export default function calculateSignedForms(reports) {
  let importantForms = {
    "client_fv/credit-card-validation.pdf": "אימות לקוח - כרטיס אשראי",
    "client_fv/a_fill.pdf": "נספח א - חד פעמי",
    "client_fv/a-fill-2.pdf": "נספח א - חד פעמי",
    "client_fv/a-fill-2-plus.pdf": "נספח א - מתמשך",
    "client_fv/image_upload_id_old.pdf": "צילום תז ישנה",
    "client_fv/image_upload_bio.pdf": "צילום תז ביומטרית",
    "client_fv/image_upload_id_old_or_bio.pdf": "צילום תז ישנה או ביומטרית",
    "client_fv/image-upload-id-old-or-bio+sefach.pdf": "צילום תז ישנה או ביומטרית וספח",
    "client_fv/b1_fill.pdf": "נספח ב - יפוי כוח"
  };

  let allSignedForms = {};

  for (let report of reports) {
    if (report.forms && report.forms.formsSignedTime) {
      for (let form in report.forms.formsSignedTime) {
        allSignedForms[form] = {
          time: report.forms.formsSignedTime[form],
          reportId: report.id
        };
      }
    }
  }

  let signedForms = {};
  for (let from in importantForms) {
    if (allSignedForms[from]) {
      signedForms[from] = {
        name: importantForms[from],
        ...allSignedForms[from]
      };
    }
  }
  return signedForms;
}

export function canRequestData(signedForms) {
  let formA =
    signedForms["client_fv/a_fill.pdf"] ||
    signedForms["client_fv/a-fill-2.pdf"];
  let formA10Years = signedForms["client_fv/a-fill-2-plus.pdf"];

  let now = moment();
  if (formA) {
    let sigend = moment(formA.time);
    if (now.diff(sigend, "months", true) <= 3) {
      return true;
    }
  }
  if (formA10Years) {
    let sigend = moment(formA10Years.time);
    if (now.diff(sigend, "years", true) <= 10) {
      return true;
    }
  }

  return false;
}
