import calculateSignedForms from "./store-modules/signedForms";
import cardsMoulde from "./store-modules/cards";
import dataContextModule from "./store-modules/dataContext";
import enums from "./Enums";
import fields from "../utils/fields";
import flowsModule from "./store-modules/flows";
import mdiIcons from "../utils/mdi-icons.json";
import referenceItemsModule from "./store-modules/referenceItems";
import registrationModule from "./store-modules/registration";
import signPackageModule from "./store-modules/signPackage";
import siteContentMoulde from "./store-modules/siteContent";

export default {
  install(Vue) {
    const registration = registrationModule(Vue);
    const signPackage = signPackageModule(Vue);
    const flows = flowsModule(Vue);
    const dataContext = dataContextModule(Vue);
    const siteContent = siteContentMoulde(Vue);
    let cards = cardsMoulde(Vue);
    let referenceItems = referenceItemsModule(Vue);

    let state = {
      current: {
        //ids
        clientId: undefined,
        reportId: undefined,

        //state
        client: undefined,
        report: undefined,
        forms: undefined,
        analyzed: undefined,

        //api
        clientApi: undefined,
        reportApi: undefined,

        //messages
        messageThreads: [],
        unreadMessageThreads: []
      },
      clients: [],
      clientsById: {},
      files: [],
      filesById: {},

      //TODO: remove this
      addedvalueServer: "https://app.addedvalue.co.il",
      calendlyLink: "https://calendly.com/addedvalue/30min",

      clientSearch: "",
      clientSearchActiveOnly: false,
      // clientWithActionsSearch: "",
      mislakaSearch: "",

      //used for nav controls in adminlayout
      context: {},
      //{ routename: MenuItems[] }
      customMenuItems: {},

      forms: [],
      formsByCategory: [],
      formsRules: [],
      maslulim: {},
      maslulimDate: {},
      bituachMaslulim: {},

      savedItems: [],
      filters: {},
      hanmakaLines: [],
      openDialogs: [],
      lastSignature: {},
      signatureTimeToLive: 24 * 60 * 60 * 1000, //one day

      enums: enums,
      fields: fields,
      mdiIcons: mdiIcons,
      registration: registration,
      signPackage: signPackage,
      flows: flows,
      cards: cards,
      dataContext: dataContext,
      siteContent: siteContent,
      referenceItems: referenceItems,
    };

    Vue.prototype.$store = new Vue({
      data() {
        return state;
      },
      computed: {
        filtersAsItems() {
          let filtersByGroups = {};
          for (let filter in this.filters) {
            let filterDetails = this.filters[filter];
            let group = filterDetails.group || "כללי";
            if (!(group in filtersByGroups)) {
              filtersByGroups[group] = [];
            }
            filtersByGroups[group].push(filter);
          }
          let items = [];
          for (let group in filtersByGroups) {
            let filters = filtersByGroups[group];
            items.push({ header: group }, { divider: true }, ...filters);
          }
          return items;
        }
      },
      methods: {
        resetState() {
          this.current.clientId = undefined;
          this.current.reportId = undefined;

          //state
          this.current.client = undefined;
          this.current.report = undefined;
          this.current.forms = undefined;
          this.current.analyzed = undefined;

          //api
          this.current.clientApi = undefined;
          this.current.reportApi = undefined;

          //messages
          this.current.messageThreads = [];
          this.current.unreadMessageThreads = [];
        },
        calculateSignedForms(reports) {
          return calculateSignedForms(reports);
        },
        async reloadUnreadMessageThreads() {
          this.current.unreadMessageThreads =
            await this.$actions.messages.loadUnread();
        },
        async reloadAllMessageThreads() {
          this.current.messageThreads = await this.$actions.messages.load();
        },
        openCalendlyPopup() {
          let values = {
            name: `${this.$account.info.shem_prati} ${this.$account.info.shem_mishpacha}`,
            email: this.$account.info.email,
            location: "+972" + this.$account.info.cellular
          };
          let query = Object.entries(values)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");

          let url = this.$store.calendlyLink;
          if (query) {
            url += "?" + query;
          }

          window.Calendly.initPopupWidget({ url });
        }
      }
    });

    Vue.prototype.$store.$account.$on("logout", () =>
      Vue.prototype.$store.resetState()
    );

    window.$store = Vue.prototype.$store;
  }
};
