import { dateField, lookupField, moneyField, percentField } from "./utils";

let periodsResolvers = {
  id: { display: "מזהה תקופה" },

  description: lookupField(
    "תאור תקופה",
    {
      prisha: "היום עד גיל פרישה",
      star_1: "תקופת פרישה ראשונה",
      star_2: "תקופה פרישה שניה",
      star_3: "תקופה פרישה שלישית"
    },
    {
      getValue(item) {
        return item.id;
      }
    }
  ),

  rating: lookupField("דרוג תקופה", {
    1: "לא טוב",
    2: "מאוזן מינוס",
    3: "תקציב מאוזן",
    4: "מאוזן פלוס",
    5: "מעולה"
  }),

  gap: moneyField('סה"כ פער לכל התקופה'),
  honi_products: { display: "מספר מוצרים כולל הוצאות חד פעמיות" },
  length_in_months: { display: "מספר חודשי תקופה" },
  period_start: dateField("תאריך תחילת תקופה"),
  period_end: dateField("תאריך סיום תקופה"),

  start_age: { display: "גיל תחילת תקופה", fieldType: "age" },
  start_expenses_cost_of_living: moneyField("עלות מחייה חודשית בתחילת התקופה"),
  start_expenses_insurance: moneyField("עלות ביטוחים חודשית בתחילת התקופה"),
  start_expenses_maskanta: moneyField("עלות משכנתא חודשית בתחילת התקופה"),
  start_expenses_other: moneyField("עלויות חודשיות אחרות בתחילת התקופה"),
  start_honi_and_bank: moneyField(
    'סה"כ כספיים הוניים ויתרה בבנק בתחילת התקופה'
  ),
  start_honi_and_bank_overdraft: moneyField(
    'סה"כ משיכת עודף מהבנק בתחילת התקופה',
    {
      getValue(item) {
        return Math.max(0, -item.start_honi_and_bank);
      }
    }
  ),
  start_incomes_bituach_lemui: moneyField(
    "קצבת ביטוח לאומי חודשית בתחילת התקופה"
  ),
  start_incomes_extra_monthly_income: moneyField(
    "הכנסות נוספות חודשיות בתחילת התקופה"
  ),
  start_incomes_kitzba_neto: moneyField("קצבת פניסה חודשיות נטו בתחילת התקופה"),
  start_incomes_sachar_neto: moneyField("שכר נטו בתחילת התקופה"),
  start_total_monthly_expense: moneyField('סה"כ הוצאות חודשיות בתחילת התקופה'),
  start_total_monthly_income: moneyField('סה"כ הכנסות חודשיות בתחילת התקופה'),
  start_withdraw_bank: moneyField('סה"כ משיכה חודשית מהבנק בתחילת התקופה'),
  start_withdraw_gap: moneyField("פער חודשי בתחילת התקופה"),
  start_withdraw_honi: moneyField(
    'סה"כ משיכה חודשית מכספיים הוניים בתחילת התקופה'
  ),
  start_yerusha: moneyField('סה"כ כספים לירושה בתחילת התקופה'),

  end_age: { display: "גיל בסיום התקופה", fieldType: "age" },
  end_expenses_cost_of_living: moneyField("עלות מחייה חודשית בסיום התקופה"),
  end_expenses_insurance: moneyField("עלות ביטוחים חודשית בסיום התקופה"),
  end_expenses_maskanta: moneyField("עלות משכנתא חודשית בסיום התקופה"),
  end_expenses_other: moneyField("עלויות חודשיות אחרות בסיום התקופה"),
  end_honi_and_bank: moneyField('סה"כ כספיים הוניים ויתרה בבנק בסיום התקופה'),
  end_honi_and_bank_available: moneyField(
    'סה"כ כספיים הוניים ויתרה בבנק נזילים בסיום התקופה'
  ),
  end_honi_and_bank_available_with_yerusha: moneyField(
    'סה"כ כספיים לירושה + נכסים הוניים נזילים בסיום התקופה',
    {
      getValue(item) {
        return item.end_honi_and_bank_available + item.end_yerusha;
      }
    }
  ),
  end_honi_and_bank_overdraft: moneyField(
    'סה"כ משיכת עודף מהבנק בסיום התקופה',
    {
      getValue(item) {
        return Math.max(0, -item.end_honi_and_bank);
      }
    }
  ),
  end_incomes_bituach_lemui: moneyField("קצבת ביטוח לאומי חודשית בסיום התקופה"),
  end_incomes_extra_monthly_income: moneyField(
    "הכנסות נוספות חודשיות בסיום התקופה"
  ),
  end_incomes_kitzba_neto: moneyField("קצבת פניסה חודשיות נטו בסיום התקופה"),
  end_incomes_sachar_neto: moneyField("שכר נטו בסיום התקופה"),
  end_total_monthly_expense: moneyField('סה"כ הוצאות חודשיות בסיום התקופה'),
  end_total_monthly_income: moneyField('סה"כ הכנסות חודשיות בסיום התקופה'),
  end_withdraw_bank: moneyField('סה"כ משיכה חודשית מהבנק בסיום התקופה'),
  end_withdraw_gap: moneyField("פער חודשי בסיום התקופה"),
  end_withdraw_honi: moneyField(
    'סה"כ משיכה חודשית מכספיים הוניים בסיום התקופה'
  ),
  end_yerusha: moneyField('סה"כ כספים לירושה בסיום התקופה'),

  average_expense: moneyField('סה"כ הוצאות חודשיות ממוצעות לתקופה'),
  average_expense_with_honi: moneyField(
    'סה"כ הוצאות חודשיות ממוצעות לתקופה כולל הוצאות חד פעמיות'
  ),
  average_gap: moneyField("פער חודשי ממוצע לתקופה"),
  average_income: moneyField('סה"כ הכנסות חודשיות ממוצעות לתקופה'),
  average_income_with_honi: moneyField(
    'סה"כ הכנסות חודשיות ממוצעות לתקופה כולל הכנסות חד פעמיות'
  ),
  average_withdraw: moneyField("משיכת כספים חודשית ממוצעת לתקופה"),
  average_withdraw_with_honi: moneyField(
    "משיכת כספים חודשית ממוצעת לתקופה כולל הוצאות חד פעמיות"
  ),

  total_saving_without_honi: moneyField(
    'סה"כ חסכון לכל התקופה ללא תשלומים חד פעמיים',
    {
      getValue(item) {
        return Math.max(0, item.aggregated_incomes - item.aggregated_expenses);
      }
    }
  ),
  average_saving_without_honi: moneyField(
    "חסכון חודשי ממוצע לתקופה ללא תשלומים חד פעמיים",
    {
      getValue(item) {
        return Math.max(0, item.average_income - item.average_expense);
      }
    }
  ),
  total_gap_without_honi: moneyField(
    'סה"כ פער לכל התקופה ללא תשלומים חד פעמיים',
    {
      getValue(item) {
        return Math.max(0, item.aggregated_expenses - item.aggregated_incomes);
      }
    }
  ),
  average_gap_without_honi: moneyField(
    "פער חודשי ממוצע לתקופה ללא תשלומים חד פעמיים",
    {
      getValue(item) {
        return Math.max(0, item.average_expense - item.average_income);
      }
    }
  ),

  cost_of_living_hit: percentField("אחוז פגיעה באיכות חיים לתקופה"),
  cost_of_living_percent: percentField("אחוז איכות חיים לתקופה", {
    getValue(item) {
      return 100 - item.cost_of_living_hit;
    }
  }),
  expexed_cost_of_living: moneyField("עלות רמת חיים מותאמת ממוצעת לתקופה", {
    getValue(item) {
      return (item.average_expense * (100 - item.cost_of_living_hit)) / 100;
    }
  }),
  expexed_cost_of_living_hit: moneyField("עלות פגיעה ברמת חיים ממוצעת לתקופה", {
    getValue(item) {
      return (item.average_expense * item.cost_of_living_hit) / 100;
    }
  }),

  aggregated_expenses: moneyField('סה"כ הוצאות לאורך כל התקופה'),
  aggregated_expenses_with_honi: moneyField(
    'סה"כ הוצאות לאורך כל התקופה כולל הוצאות חד פעמיות'
  ),
  aggregated_honi_expenses: moneyField('סה"כ הוצאות חד פעמיות לאורך כל התקופה'),
  aggregated_honi_incomes: moneyField('סה"כ הכנסות חד פעמיות לאורך כל התקופה'),
  aggregated_incomes: moneyField('סה"כ הכנסות לאורך כל התקופה'),
  aggregated_incomes_with_honi: moneyField(
    'סה"כ הכנסות לאורך כל התקופה כולל הוצאות חד פעמיות'
  ),
  aggregated_withdraws: moneyField('סה"כ משיכת מוזמנים לאורך כל התקופה'),

  monthly_saving_to_fill_gap: moneyField(
    "חסכון חודשי נדרש להשלמת פער באיכות חיים"
  ),
  monthly_saving_to_fill_gap_ratio_from_income: percentField(
    "אחוז משכר שנדרש לחסכון חודשי להשלמת פער באיכות חיים"
  )
};

export default periodsResolvers;
