import bituachFields from "./bituach_fields";
import clientFields from "./client_fields";
import halvaaFields from "./halvaa_fields";
import rowFields from "./row_fields";

export default {
  fieldsByType: {
    polisa: rowFields,
    row: rowFields,
    client: clientFields,
    bituach: bituachFields,
    halvaa: halvaaFields
  },
  isMissing(field, value) {
    if (field.status != "required") {
      return false;
    }
    if (field.type == "select") {
      return !field.values.some((v) => v.value == value);
    } else if (field.type == "combobox" && field.strictCombobox) {
      return !field.values.some((v) => v == value);
    } else {
      return value === undefined || value === "";
    }
  }
};
