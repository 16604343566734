<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <field-selector
            label="קיבוץ שורות לפי שדה"
            v-model="card.groupBy"
            :context="card.context"
            nullable
          ></field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <field-selector
            label="קיבוץ שורות בטבלה לפי שדה"
            v-model="card.tableGroupBy"
            :context="card.context"
            nullable
          ></field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <ordered-list-editor
            key="list"
            :list="card.fields"
            new-item-label="הוסף עמודה"
            :create-new-item="newListField"
          >
            <template v-slot="{ item }">
              <v-row dense>
                <v-col>
                  <field-selector
                    v-model="item.field"
                    :context="card.context"
                  ></field-selector>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <aggregation-selector
                    v-model="item.aggregation"
                  ></aggregation-selector>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    outlined
                    v-model="item.sort"
                    :items="[
                      { value: '', text: '-' },
                      { value: 'asc', text: 'עולה' },
                      { value: 'desc', text: 'יורד' }
                    ]"
                    label="מיון"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense
                ><v-col>
                  <v-text-field
                    dense
                    outlined
                    v-model="item.display"
                    label="כותרת"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    outlined
                    v-model="item.width"
                    label="רוחב"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </ordered-list-editor>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AggregationSelector from "../AggregationSelector.vue";
import FieldSelector from "../FieldSelector.vue";
import OrderedListEditor from "../OrderedListEditor.vue";

export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {
    FieldSelector,
    AggregationSelector,
    OrderedListEditor
  },
  data() {
    return {};
  },
  methods: {
    newListField() {
      let context = this.$store.dataContext.getContextType(this.card.context);
      let field = context.keyField;
      return {
        field: field,
        aggregation: "first"
      };
    }
  }
};
</script>

<style></style>
