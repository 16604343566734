const overviewCardResolver = {
  supported(card) {
    return card.type == "OVERVIEW";
  },
  resolveCard(context, card) {
    let resolved = {
      ...card,
      title: context.interpolateElements(card.title)
    };

    if (card.subject == "PRISHA_COST_OF_LIVING") {
      resolved.periods = context.contexts["periods_before"];
    }

    if (card.subject == "TSUA") {
      let fields = [
        "tsua_mitztaberet_6_hodashim",
        "tsua_mitztaberet_12_hodashim",
        "tsua_mitztaberet_24_hodashim",
        "tsua_mitztaberet_36_hodashim",
        "tsua_mitztaberet_60_hodashim"
      ];
      let companies = [
        "514956465",
        "512065202",
        "512267592",
        "513026484",
        "513173393",
        "511880460"
      ];
      let keys = ["current", ...companies];
      let mutzarim = context.contexts.mutzarim_before.filter(
        (mutzar) => [3, 4, 9].indexOf(mutzar.sug_mutzar) != -1
      );

      let total_saving = 0;
      let tsuot = {};
      for (let company of keys) {
        tsuot[company] = { changes: 0, mutazrim: mutzarim.length };
        for (let field of fields) {
          tsuot[company][field] = 0;
          tsuot[company][field + "_pure"] = true;
        }
      }

      for (let mutzar of mutzarim) {
        let mutzar_saving = +mutzar.total_saving;
        total_saving += mutzar_saving;
        for (let field of fields) {
          tsuot.current[field] += +mutzar[field] * mutzar_saving;
        }
        for (let company of companies) {
          let changed = false;
          for (let field of fields) {
            let reference =
              (mutzar.explain &&
                mutzar.explain.references.filter(
                  (ref) => ref.num_hevra == company && field in ref
                )) ||
              [];
            if (reference.length) {
              let maxTsua = Math.max(...reference.map((ref) => ref[field]));
              tsuot[company][field] += maxTsua * mutzar_saving;
              tsuot[company].shm_hevra_menahelet =
                reference[0].shm_hevra_menahelet;
              changed = true;
            } else {
              tsuot[company][field] += +mutzar[field] * mutzar_saving;
              tsuot[company][field + "_pure"] = false;
            }
          }
          if (changed) {
            tsuot[company].changes += 1;
          }
        }
      }

      for (let company of keys) {
        for (let field of fields)
          tsuot[company][field] = tsuot[company][field] / total_saving;
      }

      resolved.tsuot = tsuot;

      for (let field of fields) {
        resolved[field] = context.resolveValueAndDisplay(
          "mutzarim_before",
          field,
          "average"
        );
      }
    }

    if (card.subject == "DEMI_NIHUL") {
      resolved.dmei_nihul_shiur_tzvira = context.resolveValueAndDisplay(
        "mutzarim_before",
        "dmei_nihul_shiur_tzvira",
        "average"
      );
      resolved.dmei_nihul_gil_prisha = context.resolveValueAndDisplay(
        "mutzarim_before",
        "dmei_nihul_gil_prisha",
        "sum"
      );

      if (!resolved.subTitle) {
        resolved.subTitle = [
          'סה"כ דמי ניהול עד לפרישה ',
          {
            context: "mutzarim_before",
            field: "dmei_nihul_gil_prisha",
            aggregation: "sum"
          }
        ];
      }
    }

    if (card.subject == "PERFORMANCE") {
      //nothing yet
    }

    resolved.hasExpenses =
      context.resolveValue("expenses", "schum_tashlum", "sum") > 0;

    if (resolved.subTitle) {
      resolved.subTitle = context.interpolateElements(resolved.subTitle);
    }

    return resolved;
  }
};

export default overviewCardResolver;
