const fieldCardResolver = {
  supported(card) {
    return card.type == "FIELD";
  },
  resolveCard(context, card) {
    let filteredContext = context.applyFilterByConditions(
      card.context,
      card.conditions
    );

    let result = filteredContext.resolveValueAndDisplay(
      card.context,
      card.field,
      card.aggregation
    );

    let title = card.title || result.display;

    return {
      ...card,
      title: filteredContext.interpolateElements(title),
      fieldValue: result.fieldValue,
      fieldType: result.fieldType,
      fieldDisplay: result.fieldDisplay,
      ltr: result.ltr
    };
  }
};

export default fieldCardResolver;
