export class BackendError extends Error {
  constructor(response, message) {
    super(message);
    this.response = response;
  }
}

async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export default {
  install(Vue) {
    let _token = undefined;

    const backend = new Vue({
      data() {
        return {
          url: "/api"
        };
      },

      methods: {
        resolveUrl(relativeUrl) {
          let url = `${this.url}/${relativeUrl.replace(/^\//, "")}`;
          return url;
        },
        async fetch(relativeUrl, options, retryCount = 3) {
          let url = this.resolveUrl(relativeUrl);

          let fetchOptions = Object.assign(
            {
              headers: {}
            },
            options
          );

          if (_token) {
            this.$account.refreshToken(_token);
          }

          this.applyToken(fetchOptions.headers);

          let response = await window.fetch(url, fetchOptions);

          if (response.status == 403) {
            if (response.headers.get("x-reason") == "Token-Expired") {
              this.$bus.$emit("token-expired");
              throw new BackendError(response, "token-expired");
            }
          }

          if (response.status == 500) {
            if (response.headers.get("x-reason") == "Proxy-Error") {
              await delay(1000 * (4 - retryCount));
              if (retryCount > 0) {
                return this.fetch(relativeUrl, options, retryCount - 1);
              }
            }
          }

          return response;
        },

        get(relativeUrl, options) {
          if (options && options.params) {
            relativeUrl = this.applyParams(relativeUrl, options.params);
          }

          return this.fetch(
            relativeUrl,
            {
              method: "GET"
            },
            options
          );
        },

        async getJson(relativeUrl, options) {
          let response = await this.get(relativeUrl, options);
          if (!response.ok) {
            throw new BackendError(response, "failed to get " + relativeUrl);
          }
          return await response.json();
        },

        post(relativeUrl, body, options) {
          if (options && options.params) {
            relativeUrl = this.applyParams(relativeUrl, options.params);
          }

          if (body instanceof FormData) {
            return this.fetch(relativeUrl, {
              method: "POST",
              body: body
            });
          } else {
            return this.fetch(relativeUrl, {
              method: "POST",
              body: JSON.stringify(body),
              headers: { "Content-Type": "application/json" }
            });
          }
        },

        async postJson(relativeUrl, body, options) {
          let response = await this.post(relativeUrl, body, options);
          if (!response.ok) {
            throw new BackendError(response, "failed to post " + relativeUrl);
          }
          return await response.json();
        },

        async delete(relativeUrl, options) {
          if (options && options.params) {
            relativeUrl = this.applyParams(relativeUrl, options.params);
          }
          let response = await this.fetch(relativeUrl, {
            method: "DELETE"
          });
          if (!response.ok) {
            throw new BackendError(response, "failed to delete " + relativeUrl);
          }
          return await response.text();
        },

        async deleteJson(relativeUrl, options) {
          if (options && options.params) {
            relativeUrl = this.applyParams(relativeUrl, options.params);
          }

          let response = await this.fetch(relativeUrl, {
            method: "DELETE"
          });
          if (!response.ok) {
            throw new BackendError(response, "failed to delete " + relativeUrl);
          }
          return await response.json();
        },

        setToken(token) {
          _token = token;
        },

        getToken() {
          return _token;
        },

        applyToken(headers) {
          if (_token) {
            headers.Authorization = `Bearer ${_token}`;
          }
        },

        applyParams(relativeUrl, params) {
          let searchQuery = new URLSearchParams();
          for (let key in params) {
            let value = params[key];
            if (value !== undefined) {
              searchQuery.append(key, params[key]);
            }
          }
          return relativeUrl + "?" + searchQuery.toString();
        }
      }
    });

    Vue.prototype.$backend = backend;
  }
};
