export default function install(Vue) {
  let storeModule = new Vue({
    data() {
      return {
        //package id
        packageId: undefined,
        //who this package id belong to
        info: undefined,
        api: undefined,
        //package publish information
        publishInfo: undefined,

        flatForms: undefined,

        loadingProgress: {
          loading: false,
          loaded: false,
          error: false,
          message: "",
          progress: 0
        },

        signingProgress: {
          started: false,
          completed: false
        },

        // uploadProgress: {
        //   uploading: false,
        //   uploaded: false,
        //   error: false,
        //   message: "",
        //   progress: 0
        // },

        lastSignature: undefined
      };
    },
    computed: {
      flatFormsStats() {
        if (!this.flatForms) {
          return [];
        }
        return this.flatForms.map((form, index) => {
          let signatures = this.filterFormSignatures(form);
          let empty = signatures.filter((sig) => !sig.image);
          return {
            index,
            form: form.form,
            display: form.display,
            icon: form.icon,
            signatures: signatures.length,
            emptySignatures: empty.length,
            completed: empty.length == 0
          };
        });
      },
      formsCount() {
        return this.flatFormsStats.length;
      },
      completedFormsCount() {
        let completed = this.flatFormsStats.filter((form) => {
          return form.completed;
        });
        return completed.length;
      },
      notCompletedFormsCount() {
        return this.flatFormsStats.filter((form) => !form.completed).length;
      },
      signaturesCount() {
        let sum = 0;
        for (let form of this.flatFormsStats) {
          sum += form.signatures;
        }
        return sum;
      },
      completedSignaturesCount() {
        let sum = 0;
        for (let form of this.flatFormsStats) {
          sum += form.signatures - form.emptySignatures;
        }
        return sum;
      },
      nextNoneCompleteForm() {
        for (let form of this.flatFormsStats) {
          if (!form.completed) {
            return form.index + 1;
          }
        }
        return 0;
      },
      isLastForm() {
        return this.completedFormsCount == this.formsCount;
      },
      signingPerson() {
        if (this.publishInfo && this.publishInfo.people) {
          return this.publishInfo.people.find(
            (person) => person.id == this.$account.mispar_zihuy_lakoach
          );
        }
        return undefined;
      },
      signingPersonIsTheClient() {
        if (!this.signingPerson) return false;
        return this.signingPerson.id == this.info.mispar_zihuy_lakoach;
      },
      otherPeople() {
        if (
          !this.signingPerson ||
          !this.publishInfo ||
          !this.publishInfo.people
        ) {
          return [];
        }
        return this.publishInfo.people.filter(
          (p) => p.id !== this.signingPerson.id
        );
      }
    },
    methods: {
      async loadPackage(packageId) {
        //reset
        this.lastSignature = undefined;
        this.signingProgress.started = false;
        this.signingProgress.completed = false;

        this.loadingProgress.loading = true;
        this.packageId = packageId;
        this.loadingProgress.message = "מחפש טפסים...";
        this.loadingProgress.progress = 0;
        this.startLoadingProgressLoading(100);

        try {
          let signPackage = await this.$backend.getJson(
            "/sign-package/" + this.packageId
          );

          this.publishInfo = signPackage.publishInfo;
          this.info = signPackage.info;
          this.flatForms = signPackage.forms;

          this.completeLoadWithMessage();
        } catch (error) {
          //sentry.captureException(error);
          this.completeLoad(true, "לא נמצאו טפסים לחתימה");
        }
      },

      completeLoadWithMessage() {
        let message;
        switch (this.notCompletedFormsCount) {
          case 0:
            message = "כל הטפסים נחתמו";
            break;
          case 1:
            message = "נמצא טופס אחד לחתימה";
            break;
          default:
            message = `נמצאו ${this.notCompletedFormsCount} טפסים לחתימה`;
            break;
        }

        this.completeLoad(false, message);
      },

      startSign() {
        this.signingProgress.started = true;
      },

      completeSigning() {
        this.signingProgress.completed = true;
      },

      startLoadingProgressLoading(target) {
        if (
          this.loadingProgress.progress < target &&
          this.loadingProgress.loading
        ) {
          let delta = (target - this.loadingProgress.progress) * 0.1;
          this.loadingProgress.progress += Math.min(2, delta);
          window.setTimeout(
            () => this.startLoadingProgressLoading(target),
            100
          );
        }
      },

      completeLoad(error, message) {
        this.loadingProgress.message = message;
        this.loadingProgress.progress = 100;
        if (error) {
          this.loadingProgress.error = true;
        } else {
          this.loadingProgress.loaded = true;
        }
        window.setTimeout(() => {
          this.loadingProgress.loading = false;
        }, 200);
      },

      filterSignature(signature) {
        let filter = (signature) =>
          signature.visible !== false &&
          (signature.name == "client_signature" ||
            signature.name.startsWith("image_upload_"));

        if (
          this.signingPerson &&
          this.signingPerson.id !== this.info.mispar_zihuy_lakoach
        ) {
          filter = (signature) =>
            signature.visible !== false &&
            this.signingPerson.signatures.names.indexOf(signature.name) !== -1;
        }

        return filter(signature);
      },

      filterFormSignatures(form) {
        return form.signatures.filter(this.filterSignature);
      },

      async updateSignatures(form) {
        let updatedForm = await this.$backend.postJson(
          "/sign-package/" + this.packageId + "/form/" + form.uuid,
          form
        );
        for (let i = 0; i < this.flatForms.length; i++) {
          if (updatedForm.uuid == this.flatForms[i].uuid) {
            this.flatForms[i] = updatedForm;
          }
        }
      },

      gotoNextForm(router) {
        if (this.isLastForm) {
          this.completeSigning();
          router.push({
            name: "sign-complete",
            params: { packageId: this.packageId }
          });
        } else {
          let nextForm = this.nextNoneCompleteForm;
          router.push({
            name: "sign-form",
            params: {
              formIndex: nextForm,
              packageId: this.packageId
            }
          });
        }
      }
    }
  });

  return storeModule;
}
