<template>
  <v-card :class="$attrs.class" :width="width">
    <slot name="title">
      <v-card-title>אימות לקוח</v-card-title>
    </slot>
    <v-card-text class="subtitle-1 px-4">
      <v-container class="pt-3 px-0">
        <template v-if="passwordSent">
          <v-row>
            <v-col v-if="!genertingPassword">
              לצורך שמירה על פרטיותך, <br />
              קוד סודי זמני נשלח {{ method == "sms" ? "לנייד" : "למייל" }} שלך
              המעודכן אצלנו במערכת:
              <div class="font-weight-bold">
                {{ target }}
              </div>
              נא להזין את הקוד הזמני שקיבלת <br />(הקוד תקף ל-3 דקות)
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col style="max-width: 300px">
              <code-input
                v-model="password"
                pattern="XXXXXX"
                inputmode="numeric"
                @valid="verifyPassword"
                :verifying="verifying"
                ref="code"
                auto-focus
              ></code-input>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2" v-if="passwordSent">
            <v-col class="text-center" v-if="!justSentPassword">
              <a
                href="#"
                @click.prevent="sendPassword()"
                v-bind:disabled="genertingPassword"
                >לא קבלתי את הקוד, שלח שנית</a
              >
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col>
              אנא הכנס מס' ת.ז. ואמצעי תקשורת לקבלת קוד סודי זמני {{ purpose }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                key="id"
                label="ת'ז"
                v-model="mispar_zihuy_lakoach"
                :rules="[validations.id]"
                pattern="[0-9]*"
                inputmode="numeric"
                required
                autofocus
                @keypress.enter="sendPassword()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="require_mfa && method == 'sms'" dense>
            <v-col>
              <v-text-field
                outlined
                label="נייד"
                key="mobile"
                ref="mobile"
                v-model="cellular"
                :rules="[validations.mobile]"
                pattern="[0-9\-\(\)\s]*"
                inputmode="numeric"
                required
                @input="
                  autoSwitchMethodIfNeeded(cellular, validations.email, 'email')
                "
                @keypress.enter="sendPassword()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="require_mfa && method == 'email'" dense>
            <v-col>
              <v-text-field
                outlined
                label="אי-מייל"
                key="email"
                ref="email"
                v-model="email"
                :rules="[validations.email]"
                required
                @input="
                  autoSwitchMethodIfNeeded(email, validations.mobile, 'sms')
                "
                @keypress.enter="sendPassword()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="require_mfa && method == 'silent'" dense>
            <v-col>
              <v-text-field
                outlined
                label="אי-מייל"
                v-model="email"
                :rules="[validations.email]"
                required
                @keypress.enter="sendPassword()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline" dense>
            <v-col class="text-right"> ברצוני לקבל קוד ל- </v-col>
            <v-col class="text-left">
              <v-btn-toggle v-model="method" color="blue" mandatory>
                <v-btn value="sms" class="px-5">נייד</v-btn>
                <v-btn value="email" class="px-5">מייל</v-btn>
                <v-btn v-if="silentMode" value="silent" class="px-5"
                  >ללא קוד</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>
        </template>
        <v-row v-if="error">
          <v-col class="error--text">
            <v-alert
              color="error"
              outlined
              icon="mdi-information"
              border="left"
              >{{ error }}</v-alert
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-container class="pa-2">
        <v-row no-gutters>
          <!-- <v-col v-if="passwordSent">
            <v-btn
              large
              block
              color="primary"
              @click="verifyPassword()"
              :loading="verifying"
              :disabled="!canVerify"
              >אימות קוד סודי</v-btn
            >
          </v-col> -->
          <v-col v-if="!passwordSent">
            <v-btn
              large
              block
              color="primary"
              @click="sendPassword()"
              :loading="genertingPassword"
              :disabled="!canSendPassword"
              >שלח קוד</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters v-if="canRegister">
          <v-col class="text-center caption">
            <router-link :to="{ name: 'register' }"> משתמש חדש</router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import validations from "@/utils/validations.js";
import CodeInput from "./CodeInput.vue";

const demoId = '123456789';

export default {
  components: { CodeInput },
  props: {
    canRegister: { type: Boolean, default: false },
    width: { type: [Number, String], default: "auto" },
    purpose: { type: String, default: "לכניסה למערכת" },
    default_mispar_zihuy_lakoach: { type: String, default: undefined },
    require_mfa: { type: Boolean, default: true }
  },
  data() {
    return {
      genertingPassword: false,
      justSentPassword: false,
      passwordSent: false,
      verifying: false,
      mispar_zihuy_lakoach:
        this.default_mispar_zihuy_lakoach ||
        this.$account.suggest_mispar_zihuy_lakoach ||
        "",
      error: "",
      method: window.location.hostname == "localhost" ? "silent" : "sms",
      cellular: "",
      email: "",
      password: "",
      lastPassword: "",
      validations
    };
  },

  computed: {
    canSendPassword() {
      if (
        //this.packageId &&
        this.mispar_zihuy_lakoach &&
        (validations.id(this.mispar_zihuy_lakoach) === true || this.mispar_zihuy_lakoach == demoId)
      ) {
        if (!this.require_mfa) {
          return true;
        }
        if (this.method == "sms") {
          return validations.mobile(this.cellular) === true;
        }
        if (this.method == "email" || this.method == "silent") {
          return validations.email(this.email) === true;
        }
        return false;
      }
      return false;
    },
    canVerify() {
      return (
        this.canSendPassword &&
        this.password &&
        this.password.trim().length == 6
      );
    },
    silentMode() {
      return (
        this.$route.query.silent || window.location.hostname == "localhost"
      );
    },
    fullscreen() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  methods: {
    async sendPassword() {
      if (!this.canSendPassword) {
        return;
      }
      try {
        this.genertingPassword = true;
        this.password = "";
        this.lastPassword = "";
        let result = await this.$account.generatePassword(
          this.mispar_zihuy_lakoach.trim(),
          this.method.trim(),
          this.cellular.trim(),
          this.email.trim()
        );
        this.genertingPassword = false;
        this.passwordSent = true;
        this.error = "";
        this.justSentPassword = true;
        this.target = result.target;
        setTimeout(() => {
          this.justSentPassword = false;
        }, 3000);
      } catch (error) {
        console.error(error);
        this.genertingPassword = false;
        this.passwordSent = false;
        this.error = "בעיה בשליחת קוד";
        if (error.message == "not valid destination details") {
          if (this.method == "sms") {
            this.error = "נייד לא תואם את הנתונים במערכת";
          } else if (this.method == "email") {
            this.error = "אי-מייל לא תואם את הנתונים במערכת";
          }
        }
      }
    },
    async verifyPassword() {
      if (!this.canVerify) {
        return;
      }
      try {
        this.verifying = true;
        await this.$account.signIn(
          this.mispar_zihuy_lakoach.trim(),
          this.password.trim()
        );
        this.$emit("verification-passed");
      } catch (error) {
        this.verifying = false;
        this.error = "אימות נכשל";
        this.password = "";
        this.$emit("verification-failed");
        return;
      }
    },
    autoVerifyPasswordIfNeeded() {
      if (this.canVerify && !this.lastPassword) {
        this.lastPassword = this.password;
        this.verifying = true;
        this.$nextTick(() => this.verifyPassword());
      }
    },
    autoSwitchMethodIfNeeded(value, validation, method) {
      if (validation(value) === true) {
        this.method = method;
        switch (method) {
          case "email":
            this.email = value;
            this.cellular = "";
            this.$nextTick(() => this.$refs.email.focus());
            break;
          case "sms":
            this.cellular = value;
            this.email = "";
            this.$nextTick(() => this.$refs.mobile.focus());
            break;
        }
      }
    }
  }
};
</script>

<style></style>
