<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <v-select
            label="שדה"
            v-model="card.field"
            :items="tsuaFields"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select
            label="היסטוריה"
            v-model="card.maxHistory"
            :items="historyLength"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field
            label="סטיית רמת סיכון"
            v-model="card.threshold"
            type="number"
            prefix="%"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {},
  data() {
    return {
      tsuaFields: [
        { value: "tsua_mitztaberet", text: "תשואה מצטברת" },
        { value: "tsua_history", text: "תשואה חודשית" }
      ],
      historyLength: [
        { value: 6, text: "6 חודשים" },
        { value: 12, text: "12 חודשים" },
        { value: 24, text: "שנתיים" },
        { value: 36, text: "3 שנים" },
        { value: 62, text: "5 שנים" },
        { value: 120, text: "10 שנים" }
      ]
    };
  },
  methods: {
    newListField() {
      let context = this.$store.dataContext.getContextType(this.card.context);
      let field = context.keyField;
      return {
        field: field,
        aggregation: "first"
      };
    }
  }
};
</script>

<style></style>
