import fieldStatus from "./field_status";
import rules from "@/utils/validations";

let fields = [
  {
    name: "change_status",
    category: "details",
    status: fieldStatus.nonrelevant
  },
  {
    name: "mispar_heshbon",
    display: "מספר חשבון",
    category: "details",
    class: "ltr"
  },

  {
    name: "sug_mutzar",
    display: "סוג מוצר",
    category: "details",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "ביטוח מנהלים (1)" },
      { value: 2, text: "קרן פנסיה (2)" },
      { value: 3, text: "קופת גמל (3)" },
      { value: 4, text: "קרן השתלמות (4)" },
      { value: 5, text: "ביטוח מנהלים (5)" },
      { value: 6, text: "ביטוח חיים (6)" },
      { value: 7, text: "ביטוח חיים (7)" },
      { value: 8, text: "ביטוח קולקטיב (8)" },
      { value: 9, text: "קופת גמל להשקעה (9)" }
    ]
  },

  {
    name: "shem_yatzran",
    display: "חברה מנהלת",
    category: "details",
    computed: true
  },

  {
    name: "kod_mezahe_yatzran",
    display: "ח.פ חברה מנהלת",
    category: "details",
    computed: true
  },

  {
    name: "taarich_tchilat_kisuy",
    display: "תאריך תחילת כיסוי",
    category: "details"
  },

  {
    name: "taarich_tom_kisuy",
    display: "תאריך תום כיסוי",
    category: "details"
  },

  {
    name: "active",
    display: "פעיל/לא פעיל",
    category: "details",
    type: "select",
    values: [
      { value: 0, text: "לא פעיל (0)" },
      { value: 1, text: "פעיל (1)" }
    ]
  },

  {
    name: "tags",
    display: "קטגוריות",
    category: "details",
    type: "combobox",
    multiple: true,
    chips: true,
    values: ["השלמה", "ירושה", "בן/בת זוג", "עודף", "לברור"]
  },

  {
    name: "sug_tochnit_o_cheshbon",
    display: "סטאטוס עצמאי/שכיר/בעל שליטה",
    category: "details",
    forceNumeric: true,
    type: "select",
    values: [
      { value: 1, text: "שכיר (1)" },
      { value: 2, text: "עצמאי (2)" },
      { value: 3, text: "בעל שליטה (3)" },
      { value: 4, text: "אחר (4)" },
      { value: 5, text: "פרט (5)" }
    ]
  },

  {
    name: "general-details-titlke",
    display: "פרטי מערכת",
    category: "details",
    title: true
  },
  { name: "file", display: "קובץ XML", category: "details", computed: true },
  { name: "index", display: "מס מעקב", category: "details", computed: true },
  {
    name: "maslul_index",
    display: "אינדקס מסלול השקעה",
    category: "details",
    computed: true
  },

  {
    name: "taarich_nechonut",
    display: "תאריך נכונות מידע",
    category: "details"
  },

  {
    name: "polisa_str",
    category: "details",
    display: "מספר מערכת",
    computed: true,
    class: "ltr"
  },

  {
    name: "status",
    display: "פעיל/לא פעיל",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "sug_keren_pensia",
    category: "details",
    display: "סוג קרן פנסיה",
    status: fieldStatus.nonrelevant
  },
  {
    name: "pensia_vatika_o_hadasha",
    category: "details",
    display: "קרן פנסיה (חדשה/ותיקה)",
    status: fieldStatus.nonrelevant
  },

  {
    name: "str_sug_mutzar",
    display: "סוג מוצר",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "main_product",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "product",
    display: "מוצר",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "sug_kisui_bituchi",
    display: "כיסוי",
    category: "bituach",
    computed: true
  },

  {
    name: "sug_kisui",
    display: "סוג כיסוי",
    category: "bituach",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כיסוי למקרה מוות (1)" },
      { value: 2, text: "נכות מקצועית (2)" },
      { value: 3, text: "מוות מתאונה (3)" },
      { value: 4, text: "נכות מתאונה (4)" },
      { value: 5, text: "אובדן כושר עבודה (5)" },
      { value: 5.99, text: "מטריה - אובדן כושר עבודה (5.99)" },
      { value: 6, text: "שחרור (6)" },
      { value: 7, text: "מחלות קשות (7)" },
      { value: 8, text: "תוכנית משולבת חיסכון (8)" },
      { value: 9, text: "מוות + אבדן כושר עבודה (9)" },
      { value: 10, text: "אחר (10)" },
      { value: 2.1, text: "פנסיית שארים (2.1)" },
      { value: 2.5, text: "פנסיית נכות (2.5)" }
    ]
  },

  {
    name: "shem_mutzar",
    display: "שם מוצר",
    category: "bituach"
  },
  {
    name: "sachar_mevutach",
    display: "שכר מבוטח",
    category: "bituach",
    type: "number",
    prefix: "₪",
    rules: [rules.positive]
  },

  {
    name: "achoz_me_sachar",
    display: "אחוז שכר מבוטח",
    category: "bituach",
    type: "number",
    prefix: "%",
    rules: [rules.positive]
  },

  {
    name: "kisui_bituchi",
    display: "כיסוי ביטוחי ב-₪",
    category: "bituach",
    type: "number",
    prefix: "₪",
    rules: [rules.positive]
  },

  {
    name: "alut_chodshei",
    display: "עלות חודשית ב-₪",
    category: "bituach",
    type: "number",
    prefix: "₪",
    rules: [rules.positive]
  },

  {
    name: "total_premia_zfoya",
    display: "התפתחות עלות כוללת לתקופה ב-₪",
    category: "bituach",
    type: "number",
    prefix: "₪",
    computed: true
  },

  {
    name: "sug_hafrashot",
    display: "סוג הפרשות",
    category: "bituach",
    type: "combobox",
    strictCombobox: true,
    values: [
      "עובד-שכיר",
      "מעסיק-שכיר",
      "עובד+מעסיק-שכיר",
      "עובד-עצמאי",
      "מעסיק-עצמאי",
      "עובד+מעסיק-עצמאי"
    ]
  },

  {
    name: "sug_kisui_etzel_yatzran",
    display: "סוג כיסוי אצל יצרן",
    category: "bituach",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "ביטוח יסודי (1)" },
      { value: 2, text: "נספח (ריידר) (2)" }
    ]
  },

  {
    name: "mevutach_ids",
    display: "מבוטחים",
    category: "bituach",
    type: "combobox",
    multiple: true,
    chips: true
  },

  {
    name: "lakoach_mevutah",
    display: "לקוח מוגדר כמבוטח",
    category: "bituach",
    type: "select",
    tooltip: 'האם הלקוח מבוטח ע"י מוצר זה',
    forceNumeric: true,
    values: [
      { value: 0, text: "לא (0)" },
      { value: 1, text: "כן (1)" }
    ]
  },

  {
    name: "sug_mevutach",
    display: "סוג מבוטח",
    category: "bituach",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "ראשי (1)" },
      { value: 2, text: "משני (2)" },
      { value: 3, text: "ילד (3)" },
      { value: 4, text: "הדדי (4)" },
      { value: 5, text: "אחר (5)" }
    ]
  },

  {
    name: "ofen_tashlum",
    display: "אופן תשלום",
    category: "bituach",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "סכום חד פעמי (1)" },
      { value: 2, text: "קצבה (2)" },
      { value: 3, text: "לשיעורין (3)" },
      { value: 4, text: "אחר (4)" }
    ]
  },

  {
    name: "kalul_bamutzar_pensioni_yes_no",
    display: "כלול במוצר פנסיוני",
    category: "bituach",
    type: "combobox",
    strictCombobox: true,
    values: ["כן", "לא"]
  },

  {
    name: "kod_maslul_bituach",
    display: "קוד מסלול ביטוח",
    category: "bituach"
  },
  {
    name: "polisa_kidud_achid",
    display: "קידוד אחיד",
    category: "bituach"
  },

  {
    name: "nechut_mitpatahat",
    display: "פנסיית נכות מתפתחת",
    category: "nechut",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" }
    ]
  },
  {
    name: "kolel_prenzisa",
    display: "פרנציזה (נכות כפולה)",
    category: "nechut",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" }
    ]
  },
  {
    name: "nechut_vitor_gil_60_and_above",
    display: "ויתור פנסיית נכות מגיל 60",
    category: "nechut",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" },
      { value: 3, text: "לא רלוונטי (3)" }
    ]
  },

  {
    name: "fixes",
    display: "תיקונים",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  }
];

let categories = [
  { name: "details", display: "פרטים כללים", fields: [] },
  //{ name: "mutzar_details", display: "פרטים כללים תוכנית", fields: [] },
  { name: "bituach", display: "פרטי כיסוי ביטוחי", fields: [] },
  { name: "nechut", display: "כיסוי נכות", fields: [] }
];

//index fields
let fieldByName = {};
fields.forEach((field) => (fieldByName[field.name] = field));

//index categories
let categoryByName = {};
categories.forEach((category) => (categoryByName[category.name] = category));

//populate categories fields
fields.forEach((field) => categoryByName[field.category].fields.push(field));

export default {
  fields,
  fieldByName,
  categories,
  categoryByName
};
