const listCardResolver = {
  supported(card) {
    return card.type == "LIST";
  },
  resolveCard(context, card) {
    let filteredContext = context.applyFilterByConditions(
      card.context,
      card.conditions
    );

    let items = filteredContext.groupContextBy(card.context, card.groupBy);

    let allFields = card.fields.slice();
    if (card.groupBy) {
      allFields.unshift({
        groupBy: true,
        field: card.groupBy,
        aggregation: "first"
      });
    }

    if (card.tableGroupBy) {
      allFields.unshift({
        groupBy: true,
        field: card.tableGroupBy,
        aggregation: "first"
      });
    }

    let fields = filteredContext.enrichFieldsWithDetails(
      card.context,
      allFields
    );

    let finalItems = items.map((itemsGroup) =>
      filteredContext.extractFieldsForItems(card.context, fields, itemsGroup)
    );

    let headers = fields
      .filter((field) => !field.groupBy)
      .map((field) => ({
        text: field.display,
        value: field.field,
        fieldType: field.fieldType,
        ltr: field.ltr,
        width: field.width
      }));

    let sortBy = [];
    let sortDesc = [];

    for (let field of fields) {
      if (field.sort) {
        sortBy.push(field.field);
        sortDesc.push(field.sort == "desc");
      }
    }

    return {
      items: finalItems,
      headers,
      ...card,
      title: filteredContext.interpolateElements(card.title),
      sortBy,
      sortDesc
    };
  }
};

export default listCardResolver;
