import { moneyField } from "./utils";

let budgetItemsResolvers = {
  bituach_death_coverage: moneyField('סה"כ ביטוח חיים קיים'),
  bituach_death_coverage_cancelable: moneyField("כיסוי ביטוחי שניתן לבטל"),
  bituach_death_coverage_mashkanta: moneyField("כיסוי משכנתא קיים"),
  bituach_death_pv: moneyField('סה"כ חיסכון נוכחי במוצרים פיננסיים'),
  bituach_death_total_assest: moneyField("סה״כ נכסים לצורך ביטוחי"),
  bituach_leumi_coverage: moneyField('סה"כ כיסוי ביטוח לאומי'),
  extra_assets_value: moneyField("נכסים פננסים נוספים / עו״ש"),
  extra_income_end_of_insurance: moneyField(
    "עודף הכנסות בסוף תקופת התכנון (הכנסות שלא נוצלו לטובת הגנה ביטוחית)"
  ),
  gap: moneyField("פער ביטוחי"),
  kitzba_bituach_leumi: moneyField("קצבת ביטוח לאומי"),
  mashkanta: moneyField("כיסוי משכנתא דרוש"),
  mashkanta_gap: moneyField("פער ביטוחי למשכנתא"),
  penisa_not_active_shearim_coverage: moneyField(
    'סה"כ כיסוי שארים (פנסיה לא פעילה)'
  ),
  penisa_shearim_coverage: moneyField('סה"כ כיסוי פנסיית שארים'),
  total_cost_of_living: moneyField("סה״כ הגנה ביטוחית הדרושה בכל תקופת התכנון"),
  total_income: moneyField("סה״כ הכנסות חודשיות בתקופת התכנון"),
  total_income_honi: moneyField("סה״כ הכנסות חד פעמיות בתקופת התכנון"),
  total_required_insurance: moneyField(
    "השלמת הגנה ביטוחית לאחר התחשבות בהכנסות"
  ),
  total_required_insurance_without_honi: moneyField(
    "(ללא הכנסות חד פעמיות) השלמת הגנה ביטוחית לאחר התחשבות בהכנסות"
  )
};

export default budgetItemsResolvers;
