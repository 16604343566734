<template>
  <v-autocomplete
    dense
    outlined
    :value="value"
    :items="contextTypes"
    :label="label"
    @change="$emit('input', $event)"
    hide-details
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    value: { type: String },
    label: { type: String, default: "קטגוריה" },
    nullable: { type: Boolean, default: false }
  },
  computed: {
    contextTypes() {
      let contexts = this.$store.dataContext.contextTypesDisplay;
      if (this.nullable) {
        return [{ value: null, text: "ללא" }, ...contexts];
      }
      return contexts;
    }
  }
};
</script>

<style></style>
