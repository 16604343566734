import Vue from "vue";
import moment from "moment";

function isNan(value) {
  return value !== value;
}

Vue.filter("percent", (value, digits = 2, zero = "") => {
  if (!isNan(+value)) {
    return (+value).toFixed(digits) + "%";
  } else {
    return zero;
  }
});
Vue.filter("round", function (value, digit) {
  if (!value && value !== 0) return "-";
  return value.toLocaleString("us", {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit
  });
});

Vue.filter("reverse", function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse();
});

Vue.filter("money", (value, digit = 0) => {
  if (value || value === 0) {
    if (Math.abs(value) < 1) {
      digit = digit || 2;
    }
    return (
      value.toLocaleString("us", {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit
      }) + " \u20AA"
    );
  }
  return "";
});

Vue.filter("money2", (value, digit = 0) => {
  if (value || value === 0) {
    if (Math.abs(value) < 1) {
      digit = digit || 2;
    }
    return (
      "\u20AA " +
      value.toLocaleString("us", {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit
      })
    );
  }
  return "";
});

Vue.filter("moneyGap", (value, digit = 0) => {
  if (value || value === 0) {
    if (Math.abs(value) < 1) {
      digit = digit || 2;
    }
    return (
      (value > 0 ? "(חוסר)" : "(עודף)") +
      " \u20AA " +
      Math.abs(value).toLocaleString("us", {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit
      })
    );
  }
  return "";
});

Vue.filter("date", (value) => {
  let looksLikeDate = /\d\d\d\d-\d\d-\d\d/;
  if ((value && looksLikeDate.test(value)) || value instanceof Date) {
    return moment(value).format("DD/MM/YYYY");
  } else {
    return value;
  }
});

Vue.filter("datetime", (value) => {
  let looksLikeDate = /\d\d\d\d-\d\d-\d\d/;
  if (value && looksLikeDate.test(value)) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  } else {
    return value;
  }
});
