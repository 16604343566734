<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <v-select
            label="נושא"
            v-model="card.subject"
            :items="subjects"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense
        ><v-col>
          <complex-string-editor
            v-model="card.subTitle"
            @start-edit="startEditComplexString(card, 'subTitle')"
            label="כותרת משנית"
          ></complex-string-editor>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ComplexStringEditor from "../ComplexStringEditor.vue";
export default {
  props: {
    card: { type: Object, required: true }
  },
  components: { ComplexStringEditor },
  data() {
    return {
      subjects: [
        { value: "PRISHA_COST_OF_LIVING", text: "תחזית איכות חיים בפרישה" },
        { value: "PERFORMANCE", text: "ביצועי קופות" },
        { value: "TSUA", text: "תשואות" },
        { value: "DEATH_COVERAGE", text: "כיסוי ביטוחי למקרה מוות" },
        { value: "DEMI_NIHUL", text: "דמי ניהול" }
      ]
    };
  },
  methods: {
    startEditComplexString(item, field) {
      this.$emit("edit-complex-string", item, field);
    }
  }
};
</script>

<style></style>
