const required = (value) => {
  if (!value || `${value}`.trim() == "") {
    return "שדה חובה";
  }
  return true;
};

const date = (value) => {
  if (!value) {
    return true;
  }
  if (/^\d\d-\d\d-\d\d\d\d$/.test(value)) {
    return "תאריך בפרומט הפוף";
  }
  if (!/\d\d\d\d-\d\d-\d\d/.test(value)) {
    return "תאריך לא חוקי";
  }
  let [year, month, day] = value.split("-").map((part) => +part);
  if (year < 1900) {
    return "תאריך חייב להיות אחרי 1900";
  }
  if (month < 1 || month > 12) {
    return "מס חודש לא חוקי";
  }
  if (day < 1 || day > 31) {
    return "יום בחודש לא חוקי";
  }
  return true;
};

date.reformat = (value) => {
  if (/^\d\d-\d\d-\d\d\d\d$/.test(value)) {
    let parts = value.split("-");
    return parts.reverse().join("-");
  }
  if (/^\d\d\/\d\d\/\d\d\d\d$/.test(value)) {
    let parts = value.split("/");
    return parts.reverse().join("-");
  }
  return value;
};

const range = (min, max) => {
  return (value) => {
    if (+value < min) {
      return "ערך חייב להיות גדול מ-" + min;
    }
    if (+value > max) {
      return "ערך חייב להיות קטן מ-" + max;
    }
    return true;
  };
};

const alphaOnly = (value) => {
  if (!value) {
    return true;
  }
  if (/^[a-zA-Z\u0590-\u05FF`'-,. ]+$/.test(value)) {
    return true;
  }
  return "שם לא חוקי";
};

const email = (value) => {
  if (!value) {
    return true;
  }
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
      value
    )
  ) {
    return true;
  }
  return "אימייל לא חוקי";
};

const mobile = (value) => {
  if (!value) {
    return true;
  }
  if (/^[(]?05[0-9][)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value)) {
    return true;
  }
  return "טלפון סלולרי לא חוקי";
};

const id = (value) => {
  if (!value) {
    return true;
  }
  let digits = "" + value;
  if (digits.length != 9) {
    return "תז צריכה להיות 9 ספרות";
  }
  let sum = 0;
  let factor = [1, 2, 1, 2, 1, 2, 1, 2, 1];
  for (let i = 0; i < 9; i++) {
    let d = digits[i] * factor[i];
    if (d >= 10) {
      d = Math.floor(d / 10) + (d % 10);
    }
    sum += d;
  }
  if (sum % 10 != 0) {
    return "תז לא חוקית";
  }
  return true;
};

const oneOf = (values, customErrorMessage) => {
  return (value) => {
    if (values.some((v) => v == value)) {
      return true;
    }
    return customErrorMessage || "ערכים חוקיים: " + values.join(", ");
  };
};

const positive = (value) => {
  if (value < 0) {
    return "ערך לא יכול להיות שלילי";
  }
  return true;
};

const isNumber = (value) => {
  if (isNaN(+value)) {
    return "שדה חייב להיות מספר";
  }
  return true;
};

const lengthRange = (min, max) => {
  return (value) => {
    let length = ("" + value).length;
    if (min == max && length != min) {
      return "ערך חייב להיות בן " + min + " ספרות";
    } else if (length < min) {
      return "ערך חייב להיות לפחות " + min + " ספרות";
    } else if (length > max) {
      return "ערך חייב להיות עד " + max + " ספרות";
    }
    return true;
  };
};

function assert(rules, object) {
  for (let key in rules) {
    let keyRules = rules[key];
    let value = object[key];

    for (let rule of keyRules) {
      let ruleResult = rule(value);
      if (ruleResult !== true) {
        return ruleResult;
      }
    }
  }
  return true;
}

export default {
  required,
  date,
  range,
  lengthRange,
  id,
  oneOf,
  alphaOnly,
  positive,
  isNumber,
  email,
  mobile,
  assert
};
