<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <field-selector
            v-model="card.field"
            :context="card.context"
          ></field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <aggregation-selector
            v-model="card.aggregation"
          ></aggregation-selector>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AggregationSelector from "../AggregationSelector.vue";
import FieldSelector from "../FieldSelector.vue";

export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {
    FieldSelector,
    AggregationSelector
  },
  data() {
    return {};
  }
};
</script>

<style></style>
