<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <ordered-list-editor
            key="multi"
            :list="card.fields"
            new-item-label="הוסף שדה"
            :create-new-item="newMutliField"
          >
            <template v-slot="{ item }">
              <v-row dense>
                <v-col>
                  <context-selector v-model="item.context"></context-selector>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <conditions-editor
                    v-model="item.conditions"
                  ></conditions-editor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <field-selector
                    v-model="item.field"
                    :context="item.context"
                  ></field-selector>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <aggregation-selector
                    v-model="item.aggregation"
                  ></aggregation-selector>
                </v-col>
              </v-row>
              <v-row dense
                ><v-col>
                  <complex-string-editor
                    v-model="item.title"
                    @start-edit="startEditComplexString(item, 'title')"
                    label="כותרת"
                  ></complex-string-editor>
                </v-col>
              </v-row>
              <v-row dense
                ><v-col>
                  <complex-string-editor
                    v-model="item.subTitle"
                    @start-edit="startEditComplexString(item, 'subTitle')"
                    label="כותרת משנית"
                  ></complex-string-editor>
                </v-col>
              </v-row>
              <v-row dense
                ><v-col>
                  <v-btn
                    block
                    @click="startEditTooltip(item)"
                    v-if="item.tooltip"
                    color="primary"
                  >
                    <v-icon left>mdi-tooltip</v-icon>עדכן tooltip
                  </v-btn>
                  <v-btn block @click="startEditTooltip(item)" v-else>
                    <v-icon left>mdi-tooltip-plus</v-icon>הוסף tooltip
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </ordered-list-editor>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AggregationSelector from "../AggregationSelector.vue";
import ComplexStringEditor from "../ComplexStringEditor.vue";
import ConditionsEditor from "../ConditionsEditor.vue";
import ContextSelector from "../ContextSelector.vue";
import FieldSelector from "../FieldSelector.vue";
import OrderedListEditor from "../OrderedListEditor.vue";

export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {
    FieldSelector,
    AggregationSelector,
    OrderedListEditor,
    ConditionsEditor,
    ContextSelector,
    ComplexStringEditor
  },
  data() {
    return {};
  },
  methods: {
    newMutliField() {
      let context = this.$store.dataContext.getContextType("clients");
      let field = context.keyField;
      return {
        context: context.id,
        field: field,
        aggregation: "first",
        conditions: [],
        title: "",
        subTitle: ""
      };
    },
    startEditComplexString(item, field) {
      this.$emit("edit-complex-string", item, field);
    },
    startEditTooltip(item) {
      this.$emit("edit-tooltip", item);
    }
  }
};
</script>

<style></style>
