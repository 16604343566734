export default {
  install(Vue, options) {
    let Sentry = options.Sentry;

    const tracing = new Vue({
      methods: {
        captureException(error) {
          if (Sentry) {
            Sentry.captureException(error);
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      }
    });

    Vue.prototype.$tracing = tracing;
  }
};
