const fieldCardResolver = {
  supported(card) {
    return card.type == "MULTIFIELD";
  },
  resolveCard(context, card) {
    let resolvedFields = [];

    for (let field of card.fields) {
      let fieldContext = context.applyFilterByConditions(
        field.context,
        field.conditions
      );

      let result = fieldContext.resolveValueAndDisplay(
        field.context,
        field.field,
        field.aggregation
      );

      result.title = fieldContext.interpolateElements(field.title);
      result.subTitle = fieldContext.interpolateElements(field.subTitle);
      result.tooltip = field.tooltip;

      resolvedFields.push(result);
    }

    return {
      ...card,
      title: context.interpolateElements(card.title),
      fields: resolvedFields
    };
  }
};

export default fieldCardResolver;
