<template>
  <v-main class="subheading page-flow">
    <v-container fluid>
      <router-view v-if="ready"></router-view>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "print",
  data() {
    return {
      ready: false
    };
  },
  async mounted() {
    let token = undefined;
    if (window.location.hash.startsWith("#?token=")) {
      token = window.location.hash.replace("#?token=", "");
      window.location.hash = "";
      await this.$account._verifyToken(token);
    } else {
      await this.$account.verifyAuth("user", "admin");
    }

    this.ready = true;
  }
};
</script>

<style lang="stylus">
.page-flow {
  .page {
    font-size: 10pt;
    font-family: David;
    position: relative;
  }

  .page.flex {
    display: flex;
    flex-direction: column;
    max-height: 29.7cm;
  }

  .page .page-header, .page .page-title {
    font-size: 12pt;
  }

  .page .footer {
    position: absolute;
    bottom: 0;
  }

  .page.print-background * {
    -webkit-print-color-adjust: exact !important;
  }

  .arial {
    .page {
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0.5cm;
  }

  .page-flow {
    .container  {
      padding: 0;
      margin: 0;
    }
    .page {
      box-shadow: none;
      margin: 0;
      padding: 0;
    }

    .page:not(first-of-type)  {
      page-break-before: always;
    }

    .page:not(.landscape) {
      margin-top: 1cm;
    }

    .page.landscape {
      page-break-before: always;
      transform: rotate(-90deg) scale(0.8);
      transform-origin: 66.5% 38%;
      width: 35.5cm;
      height: 26cm;
    }
  }
}

@media screen {
  .page-flow {
    background-color: #ddd;

    .page {
      background: white;
      width: 21cm;
      min-height: 29.7cm;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.5cm;
      padding: 1cm;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }

    .page.with-footer {
      padding-bottom: 100px;
    }

    .page .footer {
      left: 1cm;
      right: 1cm;
    }

    .page.landscape {
      min-height: 21cm;
      width: 29.7cm;
    }
  }
}
</style>
