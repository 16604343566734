import bituchimResolver from "./resolvers/bituchimResolver";
import budgetItemsResolver from "./resolvers/budgetItemsResolver";
import clientsResolver from "./resolvers/clientsResolver";
import deathCoverageResolver from "./resolvers/deathCoverageResolver";
import halvaotResolver from "./resolvers/halvaotResolver";
import mutzarimResolver from "./resolvers/mutzarimResolvers";
import periodsResolver from "./resolvers/periodsResolver";
import prishaMonthsResolver from "./resolvers/prishaMonthsResolver";
import recommendationsResolver from "./resolvers/recommendationsResolver";
import relativesResolver from "./resolvers/relativesResolver";

let resolvers = {
  clients: clientsResolver,
  mutzarim_before: mutzarimResolver,
  bituchim_before: bituchimResolver,
  halvaot_before: halvaotResolver,
  mutzarim_after: mutzarimResolver,
  bituchim_after: bituchimResolver,
  halvaot_after: halvaotResolver,
  mutzarim_reference: mutzarimResolver,
  bituchim_reference: bituchimResolver,
  halvaot_reference: halvaotResolver,
  incomes: budgetItemsResolver,
  expenses: budgetItemsResolver,
  relatives: relativesResolver,
  periods_before: periodsResolver,
  periods_after: periodsResolver,
  recommendations: recommendationsResolver,
  coverage_before: deathCoverageResolver,
  coverage_after: deathCoverageResolver,
  months_before: prishaMonthsResolver,
  months_after: prishaMonthsResolver
};

export default resolvers;
