export default {
  install(Vue) {
    let ready = undefined

    Vue.prototype.$recaptcha = {
      async execute(action) {
        if (!ready) {
          ready = new Promise((resolve, reject) => {
            if (window.grecaptcha) {
              window.grecaptcha.ready(function () {
                resolve();
              });
            } else {
              reject('no recaptcha');
            }
          });
        }

        try {
          await ready;
        } catch (error) {
          console.log(error);
          return "no recaptcha";
        }

        let token = await window.grecaptcha.execute(
          "6LeIEPUiAAAAAK7cVwlBr8ybu1Mx6pQzzY_MXm2D",
          { action }
        );
        return token;
      }
    };
  }
};
