import {
  dateField,
  dateFromGil,
  lookupField,
  moneyField,
  percentField,
  timeInYearsBetweenField
} from "./utils";

let gilField = timeInYearsBetweenField("גיל", "taarich_leyda");
let gilAtTaarichNechonutField = timeInYearsBetweenField(
  "גיל בתאריך נכונות מידע",
  "taarich_leyda",
  "taarich_nechonut"
);
let clientsResolver = {
  shem_prati: { display: "שם פרטי" },
  shem_mishpacha: { display: "שם משפחה" },
  full_name: {
    display: "שם",
    getValue(item) {
      return `${item.shem_prati} ${item.shem_mishpacha}`;
    }
  },
  taarich_nechonut: dateField("תאריך נכונות מידע"),
  gil: gilField,
  gil_btaarich_nechonout: gilAtTaarichNechonutField,
  mispar_zihuy_lakoach: { display: "ת'ז" },
  min: lookupField("מין", { 1: "זכר" }, { 2: "נקבה" }),
  taarich_leyda: dateField("תאריך לידה"),
  gil_prisha: {
    display: "גיל פרישה",
    fieldType: "age"
  },
  gil_prisha_bafoal: {
    display: "גיל לחישוב הפנסיה",
    getValue(item) {
      let gil = gilAtTaarichNechonutField.getValue(item);
      return Math.max(item.gil_prisha, gil);
    }
  },
  taarich_prisha: dateFromGil("תאריך פרישה", "gil_prisha"),
  tochelet_haim: {
    display: "גיל תוחלת חיים",
    fieldType: "age"
  },

  matzav_mishpachti: {
    display: "מצב משפחתי",
    resolveDisplayValue(value, context) {
      let min = context.resolveValue("clients", "min", "first");
      if (min == 1) {
        switch (+value) {
          case 0:
            return "לא רלוונטי";
          case 1:
            return "רווק";
          case 2:
            return "נשוי";
          case 3:
            return "גרוש";
          case 4:
            return "אלמן";
          case 5:
            return "לא ידוע";
          case 6:
          case 90:
            return "ידוע בציבור";
        }
      } else {
        switch (+value) {
          case 0:
            return "לא רלוונטי";
          case 1:
            return "רווקה";
          case 2:
            return "נשואה";
          case 3:
            return "גרושה";
          case 4:
            return "אלמנה";
          case 5:
            return "לא ידוע";
          case 6:
          case 90:
            return "ידועה בציבור";
        }
      }
      return undefined;
    }
  },
  insurance_age_for_spouse: {
    display: "גיל סיום תכנון ביטוחי לבן/בת זוג",
    fieldType: "age"
  },
  spouse_percent_of_living_cost: percentField(
    "אחוז איכות חיים לבן/בת זוג משכר"
  ),
  insurance_age_for_kids: {
    display: "גיל סיום תכנון ביטוחי לילדים",
    fieldType: "age"
  },
  mispar_yeladim: { display: "מספר ילדים" },

  telephone: { display: "טלפון" },
  cellular: { display: "סלולרי" },
  cellular2: { display: "סלולרי נוסף" },
  email: { display: "אי-מייל" },
  email2: { display: "אי-מייל נוסף" },
  eretz: { display: "ארץ" },
  shem_yishuv: { display: "שם ישוב" },
  shem_rechov: { display: "שם רחוב" },
  mispar_bait: { display: "מס בית" },
  mispar_knisa: { display: "מס כניסה" },
  mispar_dira: { display: "מס דירה" },
  mikud: { display: "מיקוד" },
  ta_doar: { display: "תא דואר" },

  address: {
    display: "כתובת",
    getValue(item) {
      let shem_rechov = item.shem_rechov;
      let mispar_bait = item.mispar_bait;
      let mispar_knisa = item.mispar_knisa;
      let mispar_dira = item.mispar_dira;
      let shem_yishuv = item.shem_yishuv;
      let mikud = item.mikud;
      let eretz = item.eretz;

      let address = shem_rechov;
      if (mispar_bait && mispar_bait != 0) {
        address += " " + mispar_bait;
      }
      if (mispar_knisa && mispar_knisa != 0) {
        address += " מספר כניסה " + mispar_knisa;
      }
      if (mispar_dira && mispar_dira != 0) {
        address += " מספר דירה " + mispar_dira;
      }
      if (shem_yishuv) {
        address += ", " + shem_yishuv;
      }
      if (mikud) {
        address += " " + mikud;
      }
      if (eretz && eretz != "ישראל" && eretz.toLowerCase() != "israel") {
        address += ", " + eretz;
      }

      return address;
    }
  },

  matzav_briouti: { display: "מצב בריאותי" },

  kod_ishun: lookupField(
    "סטאטוס עישון",
    { 1: "מעשן/ת" },
    { 2: "לא מעשן/ת" },
    { 3: "לא ידוע" }
  ),

  ishun_stop_date: dateField("תאריך הפסקת עישון"),

  sachar_bruto: moneyField("שכר ברוטו"),
  sachar_neto: moneyField("שכר נטו"),
  extra_assets_value: moneyField("נכסים פננסים נוספים / עו״ש"),

  miktzoa: { display: "מקצוע" },
  tchum_isuk: { display: "עיסוק" },
  shem_maasik: { display: "שם מעסיק" },
  mispar_mezahe_maasik: { display: "ח.פ מעסיק" },
  mispar_tik_nikuiim: { display: "מס׳ תיק ניכויים מעסיק" },
  ish_kesher_massik: { display: "איש קשר" },
  maasik_address: { display: "כתובת מעסיק" },
  maasik_telephone: { display: "מס טלפון מעסיק" },

  midat_ramat_sikun_muadefet: { display: "רמת סיכון מועדפת" },
  midat_ramat_sikun_muadefet_min: percentField("רמת סיכון מועדפת - מינימום", {
    getValue(item) {
      const minRange = {
        "נמוך מאוד": 0,
        נמוך: 10,
        ממוצע: 25,
        גבוה: 40,
        "גבוה מאוד": 80
      };
      return minRange[item.midat_ramat_sikun_muadefet];
    }
  }),
  midat_ramat_sikun_muadefet_max: percentField("רמת סיכון מועדפת - מקסימון", {
    getValue(item) {
      const minRange = {
        "נמוך מאוד": 10,
        נמוך: 25,
        ממוצע: 40,
        גבוה: 80,
        "גבוה מאוד": 100
      };
      return minRange[item.midat_ramat_sikun_muadefet];
    }
  }),
  midat_ramat_sikun_muadefet_range: {
    display: "טווח רמת סיכון מועדפת",
    getValue(item) {
      switch (item.midat_ramat_sikun_muadefet) {
        case "נמוך מאוד":
          return "עד 10% מניות";
        case "נמוך":
          return "10% עד 25% מניות";
        case "ממוצע":
          return "25% עד 40% מניות";
        case "גבוה":
          return "40% עד 80% מניות";
        case "גבוה מאוד":
          return "80% עד 100% מניות";
      }
      return "";
    }
  }
};

export default clientsResolver;
