export function compare(op, a, b) {
  switch (op) {
    case "eq":
    case "==":
      return a == b;
    case "ne":
    case "!=":
      return a != b;
    case "gt":
    case ">":
      return isFinite(a) ? +a > +b : a > b;
    case "ge":
    case ">=":
      return isFinite(a) ? +a >= +b : a >= b;
    case "lt":
    case "<":
      return isFinite(a) ? +a < +b : a < b;
    case "le":
    case "<=":
      return isFinite(a) ? +a <= +b : a <= b;
    case "between":
      return isFinite(a) ? +a >= +b[0] && +a <= +b[1] : a >= b[0] && a <= b[1];
    case "in":
      return b && b.find((v) => v == a) !== undefined;
    default:
      return false;
  }
}

export function checkIfConditionsMatch(items, array) {
  for (let item of items) {
    if (item.startsWith("!")) {
      //negative condition
      if (array.indexOf(item.substr(1)) != -1) {
        return false;
      }
    } else {
      //positive condition
      if (array.indexOf(item) == -1) {
        return false;
      }
    }
  }

  return true;
}
