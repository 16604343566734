export default {
  install(Vue) {
    let storage = window.localStorage;

    let account = new Vue({
      data() {
        return {
          info: {
            mispar_zihuy_lakoach: undefined
          },
          suggest_mispar_zihuy_lakoach: "",
          returnTo: undefined,
          refreshingToken: false
        };
      },

      computed: {
        auth() {
          if (this.info.mispar_zihuy_lakoach) {
            if (this.tokenExp.getTime() > Date.now()) {
              return true;
            }
          }
          return false;
        },
        fullname() {
          if (!this.auth) {
            return undefined;
          }
          return `${this.info.shem_prati} ${this.info.shem_mishpacha}`;
        },
        inviterFullName() {
          return `${this.info.inviter_shem_prati} ${this.info.inviter_shem_mishpacha}`;
        },
        mispar_zihuy_lakoach() {
          return this.info.mispar_zihuy_lakoach;
        },
        role() {
          return (this.info && this.info.role) || "unknown";
        },
        contentPackage() {
          return (this.info && this.info.contentPackage) || "default";
        },
        invites() {
          return (
            (this.info &&
              this.info.openInvites &&
              this.info.openInvites.length) ||
            0
          );
        },
        openInviteId() {
          return this.info && this.info.openInvites && this.info.openInvites[0];
        },
        isAdmin() {
          return this.role == "admin";
        },
        isDemo() {
          return this.role == "demo";
        },
        tokenExp() {
          return new Date(this.info.tokenExp);
        },
        needToBuySubscription() {
          if (this.info.subscription) {
            if (this.info.subscription.type == "none") {
              return false;
            }
            if (this.info.subscription.exp <= new Date().toISOString()) {
              return true;
            }
          }
          return false;
        },
        needToAuthorizeMislaka() {
          if (!this.info.mislakaAuthorization) {
            return true;
          }
          if (
            this.info.mislakaAuthorization.status != "Authorize" ||
            this.info.mislakaAuthorization.exp <= new Date().toISOString()
          ) {
            return true;
          }
          return false;
        },
        actionsNeededInAcount() {
          return this.needToBuySubscription || this.needToAuthorizeMislaka;
        }
      },

      methods: {
        async refreshToken(token) {
          if (!this.auth || this.refreshingToken) {
            return false;
          }
          //check if the token has more than 10sec.
          if (this.tokenExp.getTime() - Date.now() > 10000) {
            return false;
          }

          let sessionAuth = storage.getItem("auth");
          try {
            if (sessionAuth) {
              sessionAuth = JSON.parse(sessionAuth);
            }
          } catch (error) {
            sessionAuth = undefined;
          }
          if (
            !sessionAuth || //no session
            !sessionAuth.exp || //session with no exp
            new Date(sessionAuth.exp).getTime() - Date.now() < 10000 //session that didn't expire yet
          ) {
            return false;
          }
          if (sessionAuth && sessionAuth.token == token) {
            //same token.
            return false;
          }

          //reapply token from storage.
          this.oldId = this.mispar_zihuy_lakoach;

          await this._verifyToken(sessionAuth.token);
          if (this.oldId != this.mispar_zihuy_lakoach) {
            // eslint-disable-next-line no-console
            console.error("different sessions detected");
            this._clearAuth();
          }
          // eslint-disable-next-line no-console
          console.log("token refershed");
          return true;
        },
        async verifyAuth(...roles) {
          if (this.auth) {
            if (roles.length > 0 && roles.indexOf(this.info.role) == -1) {
              this._clearAuth();
            }
            return this.auth;
          }

          let sessionAuth = storage.getItem("auth");
          try {
            if (sessionAuth) {
              sessionAuth = JSON.parse(sessionAuth);
            }
          } catch (error) {
            sessionAuth = undefined;
          }

          if (sessionAuth) {
            try {
              await this._verifyToken(sessionAuth.token);

              if (roles && roles.indexOf(this.info.role) == -1) {
                throw new Error(`role ${roles.join(",")} required`);
              }
            } catch (error) {
              this._clearAuth();
            }
          } else {
            this._clearAuth();
          }

          return this.auth;
        },

        async generatePassword(mispar_zihuy_lakoach, method, cellular, email) {
          let token = await this.$recaptcha.execute("login");

          let response = await this.$backend.post(
            "/account/generate-password",
            {
              m1: mispar_zihuy_lakoach,
              m2: method,
              m3: cellular,
              m4: email,
              m5: token
            }
          );
          if (!response.ok) {
            if (response.status == 403) {
              throw new Error("not valid destination details");
            }
            throw new Error("failed to generate password");
          }
          let result = await response.json();
          return result;
        },

        async getProfile() {
          let profile = await this.$backend.getJson("/account/profile");
          return profile;
        },

        async signIn(mispar_zihuy_lakoach, password) {
          let token = await this._generateToken(mispar_zihuy_lakoach, password);
          await this._verifyToken(token);
        },

        async signInGuest(mispar_zihuy_lakoach) {
          let token = await this.$recaptcha.execute("guest");

          let guestToken = await this.$backend.postJson(
            "/account/verification/generate-guest-token",
            {
              mispar_zihuy_lakoach,
              token,
              action: "guest"
            }
          );

          await this._verifyToken(guestToken);
        },

        async authenticateInvite(inviteId, inviteCode) {
          let token = await this._generateInviteToken(inviteId, inviteCode);
          await this._verifyToken(token);
        },

        async sendInvite(inviteDetails) {
          if (this.openInviteId) {
            await this.$backend.postJson("/account/send-invite", {
              inviteId: this.openInviteId,
              ...inviteDetails
            });
            //remove the invite id from list;
            this.info.openInvites.shift();
          } else {
            throw new Error("no free invite id");
          }
        },

        logout() {
          this._clearAuth();
        },

        async _generateToken(mispar_zihuy_lakoach, password) {
          let token = await this.$backend.postJson("/account/authenticate", {
            a1: mispar_zihuy_lakoach,
            a2: password
          });

          return token;
        },

        async _generateInviteToken(inviteId, inviteCode) {
          let token = await this.$backend.postJson(
            "/account/authenticate-invite",
            {
              a1: inviteId,
              a2: inviteCode.trim().toUpperCase()
            }
          );

          return token;
        },

        _clearAuth() {
          storage.removeItem("auth");
          this.$backend.setToken(undefined);
          this.info = {
            mispar_zihuy_lakoach: undefined
          };
          this.$emit("logout");
        },

        async _noTokenRefresh(callback) {
          try {
            this.refreshingToken = true;
            return await callback();
          } finally {
            this.refreshingToken = false;
          }
        },

        async useToken(token) {
          this.$backend.setToken(token);
          this.info = await this._noTokenRefresh(() => this.getProfile());
          storage.setItem(
            "auth",
            JSON.stringify({ token, exp: this.tokenExp })
          );
          this.$emit("authenticated");

          if (window.clarity) {
            window.clarity("set", "UserID", this.info.mispar_zihuy_lakoach);
          }
        },

        async _verifyToken(token) {
          try {
            await this.useToken(token);
          } catch (error) {
            this._clearAuth();
            throw error;
          }
        }
      }
    });

    Vue.prototype.$account = account;
  }
};
