<template>
  <v-select
    dense
    outlined
    :value="value"
    :items="aggregations"
    @change="$emit('input', $event)"
    :label="label"
    hide-details
  ></v-select>
</template>

<script>
export default {
  props: {
    value: { typ: String },
    label: { type: String, default: "סיכום" }
  },
  computed: {
    aggregations() {
      return this.$store.dataContext.aggregations;
    }
  }
};
</script>

<style></style>
