<template>
  <v-text-field
    v-if="!isComplex"
    dense
    outlined
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    hide-details
    append-outer-icon="mdi-form-textbox"
    @click:append-outer="$emit('start-edit')"
  ></v-text-field>
  <v-text-field
    v-else
    dense
    outlined
    :value="preview"
    readonly
    :label="label"
    hide-details
    append-outer-icon="mdi-calculator"
    @click="$emit('start-edit')"
    @click:append-outer="$emit('start-edit')"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: { required: true },
    label: { required: true }
  },
  computed: {
    isComplex() {
      return Array.isArray(this.value);
    },
    preview() {
      let index = 0;
      return this.value
        .map((element) => {
          if (typeof element == "string") {
            return element;
          } else {
            return `{${index++}}`;
          }
        })
        .join("");
    }
  }  
};
</script>

<style></style>
