function mergeTree(treeA, treeB, defaultValue) {
  let result = {};
  for (let keyA in treeA) {
    let valueA = treeA[keyA];
    if (!(keyA in result)) {
      result[keyA] = {
        groupKey: valueA.groupKey,
        groupDisplay: valueA.groupDisplay
      };
    }
    result[keyA].A = valueA.value;
    result[keyA].groupsA = valueA.groups;
  }
  for (let keyB in treeB) {
    let valueB = treeB[keyB];
    if (!(keyB in result)) {
      result[keyB] = {
        groupKey: valueB.groupKey,
        groupDisplay: valueB.groupDisplay
      };
    }
    result[keyB].B = valueB.value;
    result[keyB].groupsB = valueB.groups;
  }
  for (let key in result) {
    let value = result[key];
    if (!("A" in value)) {
      value.A = defaultValue;
    }
    if (!("B" in value)) {
      value.B = defaultValue;
    }
    value.groups = mergeTree(
      value.groupsA || {},
      value.groupsB || {},
      defaultValue
    );
    delete value.groupsA;
    delete value.groupsB;
  }
  return result;
}

function resolveGroup(filteredContext, category, field, groups) {
  let result = {};

  for (let groupItems of groups.groups) {
    let items = groupItems;
    let subGroups = undefined;

    if (items.items) {
      subGroups = items;
      items = items.items;
    }

    let key = filteredContext.resolveValueAndDisplay(
      category,
      groups.groupBy,
      "first",
      items
    );

    let value = filteredContext.resolveValueAndDisplay(
      category,
      field.field,
      field.aggregation,
      items
    );

    let display = [
      { type: "string", value: key.fieldDisplay || key.fieldValue }
    ];
    if (groups.groupByTitle) {
      display = filteredContext.interpolateElements(groups.groupByTitle, items);
    }

    result[key.fieldValue] = {
      groupKey: key.fieldValue,
      groupDisplay: display,
      value
    };

    if (subGroups) {
      result[key.fieldValue].groups = resolveGroup(
        filteredContext,
        category,
        field,
        subGroups
      );
    }
  }
  return result;
}

const compareCardResolver = {
  supported(card) {
    return card.type == "COMPARE";
  },
  resolveCard(context, card) {
    let filteredContextA = context.applyFilterByConditions(
      card.contextA,
      card.conditions
    );

    let filteredContextB = context.applyFilterByConditions(
      card.contextB,
      card.conditions
    );

    let itemsA = {
      groups: filteredContextA.groupContextBy(card.contextA, card.groupBy),
      groupBy: card.groupBy,
      groupByTitle: card.groupByTitle
    };
    let itemsB = {
      groups: filteredContextB.groupContextBy(card.contextB, card.groupBy),
      groupBy: card.groupBy,
      groupByTitle: card.groupByTitle
    };
    if (card.groupBy2) {
      itemsA.groups = itemsA.groups.map((group) => ({
        items: group,
        groups: filteredContextA.groupContextBy(
          card.contextA,
          card.groupBy2,
          group
        ),
        groupBy: card.groupBy2,
        groupByTitle: card.groupBy2Title
      }));

      itemsB.groups = itemsB.groups.map((group) => ({
        items: group,
        groups: filteredContextB.groupContextBy(
          card.contextB,
          card.groupBy2,
          group
        ),
        groupBy: card.groupBy2,
        groupByTitle: card.groupBy2Title
      }));
    }

    let allFields = card.fields.slice();

    let fields = filteredContextA.enrichFieldsWithDetails(
      card.context,
      allFields
    );

    let values = fields.map((field) => {
      let defaultValue = filteredContextA.resolveValueAndDisplay(
        card.contextA,
        field.field,
        field.aggregation,
        []
      );

      let fieldValues = {
        A: filteredContextA.resolveValueAndDisplay(
          card.contextA,
          field.field,
          field.aggregation
        ),
        B: filteredContextB.resolveValueAndDisplay(
          card.contextB,
          field.field,
          field.aggregation
        )
      };

      if (card.groupBy) {
        let groupsA = resolveGroup(
          filteredContextA,
          card.contextA,
          field,
          itemsA
        );
        let groupsB = resolveGroup(
          filteredContextB,
          card.contextB,
          field,
          itemsB
        );

        fieldValues.groups = mergeTree(groupsA, groupsB, defaultValue);
      }

      return fieldValues;
    });

    return {
      ...card,
      values,
      fields,
      title: filteredContextA.interpolateElements(card.title)
    };
  }
};

export default compareCardResolver;
