const graphCardResolver = {
  supported(card) {
    return card.type == "DOUGHNUT" || card.type == "BARCHART";
  },
  resolveCard(context, card) {
    let filteredContext = context.applyFilterByConditions(
      card.context,
      card.conditions
    );

    let items = card.categoryField
      ? filteredContext.groupContextBy(card.context, card.categoryField)
      : [filteredContext[card.context]];

    let allFields = [];

    if (card.valueFields) {
      allFields.push(...card.valueFields);
    } else if (card.valueField) {
      allFields.push(card.valueField);
    }

    let fields = filteredContext.enrichFieldsWithDetails(
      card.context,
      allFields
    );

    let finalItems = [];
    items.forEach((itemsGroup) => {
      let categoryValue = undefined;
      if (card.categoryField) {
        categoryValue = filteredContext.resolveValueAndDisplay(
          card.context,
          card.categoryField,
          "first",
          itemsGroup
        );
      }

      for (let field of fields) {
        let item = filteredContext.resolveValueAndDisplay(
          card.context,
          field.field,
          field.aggregation,
          itemsGroup
        );

        item.display = filteredContext.interpolate(field.display, itemsGroup);

        if (card.categoryField) {
          item.category = categoryValue.fieldValue;
          let category = categoryValue.fieldDisplay || categoryValue.fieldValue;
          if (allFields.length == 1) {
            item.display = category;
          } else {
            item.display = `${category} - ${item.display}`;
          }

          if (card.categoryTitle) {
            item.display = filteredContext.interpolate(
              card.categoryTitle,
              itemsGroup
            );
          }
        }

        finalItems.push(item);
      }
    });

    return {
      items: finalItems,
      ...card,
      title: filteredContext.interpolateElements(card.title)
    };
  }
};

export default graphCardResolver;
