<template>
  <div class="code-input title d-flex flex-column ltr align-center">
    <v-row dense align="baseline" class="flex-nowrap">
      <v-col v-for="(input, i) in inputs" :key="i">
        <span v-if="input.type == 'space'">{{ input.char }}</span>
        <input
          v-else
          class="input-digit"
          :class="states[i]"
          type="text"
          :inputmode="inputmode"
          outlined
          ref="input"
          :placeholder="input.placeholder"
          @input="update(input, $event)"
          @keydown.delete="deleteChar(input.index, $event)"
          hide-details
        />
      </v-col>
    </v-row>
    <div style="height: 50px; width: 100%; margin-top: 10px">
      <v-progress-linear v-if="verifying" indeterminate></v-progress-linear>
      <div class="error--text" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    pattern: { type: String },
    verifying: { type: Boolean },
    errorMessage: { type: String },
    autoFocus: { type: Boolean },
    inputmode: { type: String, default: "text" }
  },
  computed: {
    inputs() {
      let chars = this.pattern.split("");
      let index = 0;
      return chars.map((c) => {
        switch (c) {
          case "d":
            return { type: "digit", index: index++, placeholder: "1" };
          case "a":
            return { type: "alpha", index: index++, placeholder: "a" };
          case "A":
            return { type: "upper-alpha", index: index++, placeholder: "A" };
          case "X":
            return { type: "upper-any", index: index++ };
          case "-":
            return { type: "space", char: "-" };
          case " ":
            return { type: "space", char: " " };
          default:
            return { type: "any", index: index++ };
        }
      });
    },
    maxLength() {
      return Math.max(...this.inputs.map((input) => input.index || 0)) + 1;
    },
    codeValid() {
      return this.states.every((state) => state == "valid");
    },
    finalCode() {
      let code = "";
      for (let input of this.inputs) {
        switch (input.type) {
          case "space":
            code += input.char;
            break;
          case "upper-alpha":
          case "upper-any":
            if (this.chars[input.index] == "") {
              return code;
            }
            code += (this.chars[input.index] || "").toUpperCase();
            break;
          default:
            if (this.chars[input.index] == "") {
              return code;
            }
            code += this.chars[input.index] || "";
            break;
        }
      }
      return code;
    }
  },
  data() {
    return {
      chars: [],
      states: []
    };
  },
  watch: {
    value(newValue) {
      if (newValue != this.finalCode) {
        this.chars = (newValue || "").split("");
        this.validateChars();
        this.updateTextFields();
        this.emitValidCodeEventIfNeeded();
      }
    }
  },
  methods: {
    update(input, e) {
      this.chars[input.index] =
        input.type == "upper-alpha" || input.type == "upper-any"
          ? e.target.value.toUpperCase()
          : e.target.value;

      this.validateChars();
      this.updateTextFields();
      this.$emit("input", this.finalCode);
      this.emitValidCodeEventIfNeeded();
    },
    validateChars() {
      this.chars = this.chars
        .join("")
        .replace(/[^a-z0-9]/gi, "")
        .split("")
        .slice(0, this.maxLength);

      while (this.chars.length < this.maxLength) {
        this.chars.push("");
      }
    },
    updateStates() {
      this.states = this.inputs.map((input) => this.getInputState(input));
    },
    updateTextFields() {
      this.updateStates();

      for (let c = 0; c < this.chars.length; c++) {
        this.$refs.input[c].value = this.chars[c];
      }
      this.focus();
    },
    deleteChar(index, e) {
      e.preventDefault();
      while (this.chars[index] == "" && index > 0) {
        index--;
      }
      if (index >= 0) {
        this.$nextTick(() => {
          this.chars[index] = "";
          this.updateTextFields();
          this.$emit("input", this.finalCode);
          this.emitValidCodeEventIfNeeded();
        });
      }
    },
    getInputState(input) {
      if (input.type == "space") {
        return "valid";
      }
      let c = this.chars[input.index];
      if (c == "") return "empty";

      switch (input.type) {
        case "digit":
          if (/\d/.test(c)) return "valid";
          else return "not-valid";
        case "alpha":
          if (/[a-zA-Z]/.test(c)) return "valid";
          else return "not-valid";
        case "upper-alpha":
          if (/[A-Z]/.test(c)) return "valid";
          else return "not-valid";
      }
      return "valid";
    },
    emitValidCodeEventIfNeeded() {
      if (this.codeValid) {
        this.$emit("valid", this.finalCode);
      }
    },
    lastCharIndex() {
      let i = 0;
      for (let c of this.chars) {
        if (c == "") {
          return i;
        }
        i++;
      }
      return i - 1;
    },
    focus() {
      this.$refs.input[this.lastCharIndex()].focus();
    }
  },
  mounted() {
    this.chars = (this.value || "").split("");
    this.validateChars();

    if (this.autoFocus) {
      this.$nextTick(() => {
        this.updateTextFields();
      });
    }
  }
};
</script>

<style>
.code-input .input-digit {
  width: 30px;
  border: 1px rgba(0, 0, 0, 0.3) solid;
  padding: 16px 5px;
  text-align: center;
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: black;
  outline: none;
}

.code-input .input-digit::placeholder {
  color: #aaa;
  font-weight: normal;
}

.code-input .input-digit.not-valid:not(:focus) {
  box-shadow: 0 0 5px 1px rgba(204, 34, 49, 0.3);
  border-color: rgb(204, 34, 49);
  caret-color: rgb(204, 34, 49);
  background: rgba(205, 63, 75, 0.066) !important;
  border-width: 2px;
  padding: 15px 4px;
}

.code-input .input-digit:focus {
  box-shadow: 0 0 5px 1px rgba(25, 118, 210, 0.3);
  border-color: rgb(25, 118, 210);
  caret-color: rgb(25, 118, 210);
  border-width: 2px;
  padding: 15px 4px;
}

.mobile .code-input .input-digit {
  font-size: 16px;
  line-height: 20px;
}
</style>
