export default function install(Vue) {
  let localStorageKey = 'reference-items';
  let storeModule = new Vue({
    data() {
      let items = JSON.parse(window.localStorage.getItem(localStorageKey) || '[]');
      return {
        items,
      };
    },
    computed: {},
    methods: {
      addItem(item) {
        let nextId = Math.max(...this.items.map(i => i.$id), 0) + 1;
        this.items = [...this.items, { ...item, $id: nextId }];
        this.save();
      },
      removeItem(item) {
        let index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.save();
      },
      reload() {
        this.items = JSON.parse(window.localStorage.getItem(localStorageKey) || '[]');
      },
      save() {
        window.localStorage.setItem(localStorageKey, JSON.stringify(this.items));
      }
    }
  });

  return storeModule;
}
