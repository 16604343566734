import fieldStatus from "./field_status";
import rules from "@/utils/validations";

let fields = [
  {
    name: "report_type",
    status: fieldStatus.nonrelevant,
    display: "סוג דוח",
    category: "personal",
    type: "select",
    values: [
      { value: "files", text: "דוח מסלקה" },
      { value: "sign-package", text: "טפסים בלבד" },
      { value: "request-data", text: "משיכת מידע" }
    ]
  },
  {
    name: "title",
    status: fieldStatus.nonrelevant,
    display: "כותרת דוח",
    category: "personal"
  },

  {
    name: "description",
    status: fieldStatus.nonrelevant,
    display: "תאור דוח",
    category: "personal"
  },

  {
    name: "shem_prati",
    status: fieldStatus.required,
    display: "שם פרטי",
    category: "personal"
  },

  {
    name: "shem_mishpacha_kodem",
    display: "שם משפחה קודם",
    category: "personal"
  },

  {
    name: "shem_mishpacha",
    status: fieldStatus.required,
    display: "שם משפחה",
    category: "personal"
  },

  {
    name: "mispar_zihuy_lakoach",
    status: fieldStatus.required,
    display: "ת'ז",
    category: "personal",
    class: "ltr",
    rules: [rules.id]
  },

  {
    name: "taarich_hanpaka_mispar_zihuy_lakoach",
    display: 'תאריך הנפקת ת"ז',
    category: "personal",
    type: "date"
  },

  {
    name: "min",
    status: fieldStatus.required,
    display: "מין",
    category: "personal",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "זכר" },
      { value: 2, text: "נקבה" }
    ]
  },

  {
    name: "taarich_leyda",
    status: fieldStatus.required,
    display: "תאריך לידה",
    category: "personal",
    type: "date",
    age: true
  },
  {
    name: "gil_prisha",
    status: fieldStatus.required,
    display: "גיל פרישה",
    category: "personal",
    rules: [rules.range(50, 100)],
    type: "number"
  },
  {
    name: "ribit_prisha",
    display: "תכנון ריבית בפרישה",
    category: "personal",
    rules: [rules.range(0, 4)],
    type: "number",
    prefix: "%"
  },
  {
    name: "tochelet_haim",
    display: "גיל תוחלת חיים צפוייה",
    category: "personal",
    rules: [rules.range(60, 120)],
    type: "number"
  },
  {
    name: "taarich_nechonut",
    status: fieldStatus.required,
    display: "תאריך נכונות נתונים",
    category: "personal",
    type: "date"
  },

  {
    name: "telephone",
    display: "טלפון",
    category: "contact",
    rules: [rules.mobile]
  },

  {
    name: "cellular",
    status: fieldStatus.required,
    display: "סלולרי",
    category: "contact",
    rules: [rules.mobile]
  },

  {
    name: "cellular2",
    display: "סלולרי נוסף",
    category: "contact",
    rules: [rules.mobile]
  },

  {
    name: "email",
    status: fieldStatus.required,
    display: "אי-מייל",
    category: "contact",
    rules: [rules.email],
    type: "email"
  },

  {
    name: "email2",
    display: "אי-מייל נוסף",
    category: "contact",
    rules: [rules.email],
    type: "email"
  },

  {
    name: "eretz",
    display: "ארץ",
    category: "address"
  },

  {
    name: "shem_yishuv",
    display: "שם ישוב",
    category: "address"
  },

  {
    name: "shem_rechov",
    display: "שם רחוב",
    category: "address"
  },

  {
    name: "mispar_bait",
    display: "מס בית",
    category: "address"
  },

  {
    name: "mispar_knisa",
    display: "מס כניסה",
    category: "address"
  },

  {
    name: "mispar_dira",
    display: "מס דירה",
    category: "address"
  },

  {
    name: "mikud",
    display: "מיקוד",
    category: "address"
  },

  {
    name: "ta_doar",
    display: "תא דואר",
    category: "address"
  },

  {
    name: "matzav_mishpachti",
    status: fieldStatus.required,
    display: "מצב משפחתי",
    category: "family",
    forceNumeric: true,
    type: "select",
    values: [
      { value: 0, text: "לא רלוונטי" },
      { value: 1, text: "רווק/ה" },
      { value: 2, text: "נשוי/אה" },
      { value: 3, text: "גרוש/ה" },
      { value: 4, text: "אלמן/נה" },
      { value: 5, text: "לא ידוע" },
      { value: 6, text: "ידוע/ה בציבור (6)" },
      { value: 90, text: "ידוע/ה בציבור (90)" }
    ]
  },

  {
    name: "insurance_age_for_spouse",
    display: "גיל סיום תכנון ביטוחי לבן/בת זוג (ציין גיל של בן/בת זוג)",
    category: "family",
    type: "number"
  },

  {
    name: "spouse_percent_of_living_cost",
    display: "אחוז איכות חיים לבן/בת זוג משכר",
    category: "family",
    rules: [rules.range(0, 100)],
    type: "number",
    prefix: "%"
  },

  {
    name: "mispar_yeladim",
    display: "מספר ילדים",
    category: "family",
    type: "number"
  },

  {
    name: "insurance_age_for_kids",
    display: "גיל סיום תכנון ביטוחי לילדים",
    category: "family",
    rules: [rules.range(18, 35)],
    type: "number"
  },

  {
    name: "matzav_briouti",
    display: "מצב בריאותי",
    category: "health",
    type: "combobox",
    strictCombobox: true,
    values: ["תקין", "לא תקין"]
  },

  {
    name: "kod_ishun",
    status: fieldStatus.required,
    display: "מעשן",
    category: "health",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "מעשן/ת" },
      { value: 2, text: "לא מעשן/ת" },
      { value: 3, text: "לא ידוע" }
    ]
  },
  {
    name: "ishun_stop_date",
    display: "תאריך הפסקת עישון (YYYY-MM-DD)",
    category: "health",
    type: "date"
  },

  {
    name: "budget_planning",
    status: fieldStatus.required,
    display: "תכנון תקציב",
    category: "finance",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "אישי" },
      { value: 2, text: "תא משפחתי" }
    ]
  },

  {
    name: "sachar_bruto",
    display: "שכר ברוטו",
    category: "finance",
    type: "number",
    suffix: "₪",
    rules: [rules.positive]
  },

  {
    name: "sachar_neto",
    display: "שכר נטו",
    category: "finance",
    type: "number",
    suffix: "₪",
    rules: [rules.positive]
  },

  {
    name: "extra_assets_value",
    display: "נכסים פננסים נוספים / עו״ש",
    category: "finance",
    type: "number",
    suffix: "₪",
    rules: [rules.positive]
  },

  {
    name: "employment_status",
    display: "סטאטוס תעסוקה",
    category: "employment",
    type: "combobox",
    strictCombobox: true,
    values: [
      "שכיר",
      "עצמאי",
      "שכיר+עצמאי",
      "לא עובד",
      "בעל שליטה",
      "קטין",
      "פנסיונר"
    ]
  },

  {
    name: "miktzoa",
    display: "מקצוע",
    category: "employment"
  },

  {
    name: "tchum_isuk",
    display: "עיסוק",
    category: "employment"
  },

  {
    name: "shem_maasik",
    display: "שם מעסיק",
    category: "employment"
  },

  {
    name: "mispar_mezahe_maasik",
    display: "ח.פ מעסיק",
    category: "employment",
    rules: [rules.lengthRange(9, 9)],
    class: "ltr"
  },

  {
    name: "mispar_tik_nikuiim",
    display: "מס׳ תיק ניכויים מעסיק",
    category: "employment",
    rules: [rules.lengthRange(9, 9)],
    class: "ltr"
  },

  {
    name: "ish_kesher_massik",
    display: "איש קשר מעסיק",
    category: "employment"
  },

  {
    name: "maasik_address",
    display: "כתובת מעסיק",
    category: "employment"
  },

  {
    name: "maasik_telephone",
    display: "מס טלפון",
    category: "employment"
  },

  {
    name: "citizen_of_us",
    display: 'אזרח ארה"ב',
    category: "taxes",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "לא" },
      { value: 1, text: "כן" }
    ]
  },

  {
    name: "resident_of_us_for_taxes",
    display: 'תושב ארה"ב לצורכי מס',
    category: "taxes",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "לא" },
      { value: 1, text: "כן" }
    ]
  },
  {
    name: "us_tin",
    display: "U.S. TIN",
    category: "taxes"
  },
  {
    name: "resident_of_country_for_taxes",
    display: 'תושב למדינה נוספת לצורכי מס (לא ישראל/ארה"ב)',
    category: "taxes",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "לא" },
      { value: 1, text: "כן" }
    ]
  },
  {
    name: "require_continuous_service",
    display: "נדרש יעוץ מתמשך",
    category: "preference",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "לא" },
      { value: 1, text: "כן" }
    ]
  },
  {
    name: "consultation_fee",
    display: "תעריף פגישת יעוץ ראשונה",
    category: "preference",
    type: "number",
    suffix: "₪",
    rules: [rules.positive]
  }
];

let categories = [
  { name: "personal", display: "פרטיים אישיים", fields: [] },
  { name: "contact", display: "אמצעי תקשורת", fields: [] },
  { name: "address", display: "כתובת", fields: [] },
  { name: "family", display: "מצב משפחתי", fields: [] },
  { name: "health", display: "מצב בריאותי", fields: [] },
  { name: "finance", display: "מצב פיננסי", fields: [] },
  { name: "employment", display: "תעסוקה", fields: [] },
  { name: "taxes", display: "מיסוי", fields: [] },
  { name: "preference", display: "העדפות", fields: [] }
];

//index fields
let fieldByName = {};
fields.forEach((field) => (fieldByName[field.name] = field));

//index categories
let categoryByName = {};
categories.forEach((category) => (categoryByName[category.name] = category));

//populate categories fields
fields.forEach((field) => categoryByName[field.category].fields.push(field));

export default {
  fields,
  fieldByName,
  categories,
  categoryByName
};
