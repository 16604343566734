import fieldStatus from "./field_status";
import rules from "@/utils/validations";

let fields = [
  {
    name: "mispar_heshbon",
    status: fieldStatus.required,
    display: "מספר חשבון",
    category: "details",
    class: "ltr"
  },

  {
    name: "sug_mutzar",
    status: fieldStatus.required,
    display: "סוג מוצר",
    category: "details",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "ביטוח מנהלים (1)" },
      { value: 2, text: "קרן פנסיה (2)" },
      { value: 3, text: "קופת גמל (3)" },
      { value: 4, text: "קרן השתלמות (4)" },
      { value: 5, text: "ביטוח מנהלים (5)" },
      { value: 6, text: "ביטוח חיים (6)" },
      { value: 7, text: "ביטוח חיים (7)" },
      { value: 8, text: "ביטוח קולקטיב (8)" },
      { value: 9, text: "קופת גמל להשקעה (9)" }
    ]
  },

  {
    name: "shem_yatzran",
    status: fieldStatus.required,
    display: "חברה מנהלת",
    category: "details",
    computed: true
  },

  {
    name: "shm_kupa",
    status: fieldStatus.required,
    display: "שם קופה",
    category: "details",
    computed: true
  },

  {
    name: "taarich_techilat_polisa",
    status: fieldStatus.required,
    display: "תאריך פתיחת תוכנית",
    category: "details",
    type: "date"
  },

  {
    name: "sug_tochnit_o_cheshbon",
    status: fieldStatus.required,
    display: "סטאטוס עצמאי/שכיר/בעל שליטה",
    category: "details",
    forceNumeric: true,
    type: "select",
    values: [
      { value: 1, text: "שכיר (1)" },
      { value: 2, text: "עצמאי (2)" },
      { value: 3, text: "בעל שליטה (3)" },
      { value: 4, text: "אחר (4)" },
      { value: 5, text: "פרט (5)" }
    ]
  },

  {
    name: "active",
    status: fieldStatus.required,
    display: "פעיל/לא פעיל",
    category: "details",
    type: "select",
    values: [
      { value: 0, text: "לא פעיל (0)" },
      { value: 1, text: "פעיל (1)" }
    ]
  },

  {
    name: "status",
    display: "פעיל/לא פעיל",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "tags",
    display: "קטגוריות",
    category: "details",
    type: "combobox",
    multiple: true,
    chips: true,
    values: ["השלמה", "ירושה", "בן/בת זוג", "עודף", "לברור"]
  },

  {
    name: "risk_zmani",
    status: fieldStatus.highlighted,
    display: "ריסק זמני",
    defaultValue: 0,
    category: "details",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "לא (0)" },
      { value: 1, text: "כן (1)" }
    ],
    sug_mutzar: [1, 2, 5]
  },

  {
    name: "str_sug_mutzar",
    display: "סוג מוצר",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },
  {
    name: "main_product",
    display: "קטגורית מוצר",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "tikun_190",
    status: fieldStatus.highlighted,
    defaultValue: 2,
    display: "תיקון 190",
    category: "details",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" }
    ],
    sug_mutzar: [1, 3, 5]
  },

  {
    name: "sug_polisa",
    status: fieldStatus.required,
    display: "סוג פוליסה",
    category: "details",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "קלאסי (1)" },
      { value: 2, text: "עדיף (2)" },
      { value: 3, text: "פוליסות משנת 2004 ואילך (3)" },
      { value: 4, text: 'ריסק ואו אכ"ע (4)' }
    ],
    sug_mutzar: [1, 5]
  },

  {
    name: "kod_nispach_kisuy",
    status: fieldStatus.relevant,
    display: "מספר נספח כיסוי",
    category: "details",
    sug_mutzar: [1, 3, 5]
  },

  {
    name: "product",
    display: "מוצר",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "meyupe_koach_title",
    title: true,
    category: "details",
    display: "מיופה כוח"
  },

  {
    name: "kayam_meyupe_koach",
    category: "details",
    display: "קיים מיופה כוח",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" }
    ]
  },

  {
    name: "shem_meyupe_koach",
    category: "details",
    display: "שם מיופה כוח"
  },

  {
    name: "taarich_minuy_meyupe_koach",
    category: "details",
    display: "תאריך מינוי מיופה כוח",
    type: "date"
  },

  {
    name: "sug_zihuy_meyupe_koach",
    category: "details",
    display: "קיים מיופה כוח",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "פרטי (1)" },
      { value: 2, text: "תאגיד (2)" }
    ]
  },

  {
    name: "mispar_zihuy_meyupe_koach",
    category: "details",
    display: "מספר זיהוי מיופה כוח"
  },

  {
    name: "general-details-title",
    display: "פרטי מערכת",
    category: "details",
    title: true
  },

  {
    name: "change_status",
    display: "מקור/אחרי שינוי",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },
  { name: "file", display: "קובץ XML", category: "details", computed: true },
  { name: "index", display: "מס מעקב", category: "details", computed: true },
  {
    name: "maslul_index",
    display: "אינדקס מסלול השקעה",
    category: "details",
    computed: true
  },

  {
    name: "taarich_nechonut",
    status: fieldStatus.required,
    display: "תאריך נכונות מידע",
    category: "details",
    type: "date"
  },
  {
    name: "gil_prisha",
    display: "גיל פרישה",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },
  {
    name: "month_left_to_prisha",
    display: "מספר חודשים לפרישה",
    category: "details",
    computed: true
  },
  {
    name: "polisa_str",
    display: "מספר מערכת",
    category: "details",
    computed: true,
    class: "ltr"
  },

  {
    name: "sachar_polisa",
    status: fieldStatus.required,
    display: "שכר בתוכנית",
    category: "hafkada",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 4, 5]
  },
  {
    name: "hafkada_chodshei",
    status: fieldStatus.required,
    display: "הפקדה חודשית ב-₪",
    category: "hafkada",
    type: "number",
    suffix: "₪",
    rules: [rules.positive]
  },
  {
    name: "hotzahot_bituach_oka_or_risk",
    status: fieldStatus.required,
    display: "הוצאות ביטוח ב-₪ מעבר לתג+פצ'",
    category: "hafkada",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "hotzahot_bituach",
    status: fieldStatus.required,
    display: "הוצאות ביטוח ב-₪",
    category: "hafkada",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "kod_sug_hafkada",
    status: fieldStatus.required,
    display: "סוג הפקדה חודשית",
    category: "hafkada",
    type: "select",
    values: [
      { value: "honi", text: "honi" },
      { value: "kitzba", text: "kitzba" },
      { value: "choice till 2000", text: "choice till 2000" },
      { value: "hafkada without saving", text: "hafkada without saving" }
    ]
  },
  {
    name: "achuz_tagmulim_oved",
    status: fieldStatus.required,
    display: "תגמולי עובד",
    category: "hafkada",
    type: "combobox",
    forceNumeric: true,
    prefix: "%",
    rules: [rules.isNumber, rules.positive, rules.range(0, 20)],
    values: [0, 2.5, 5.0, 5.5, 6.0, 6.5, 7.0, 16, 16.5],
    sug_mutzar: [1, 2, 3, 4, 5]
  },
  {
    name: "achuz_tagmulim_maasik",
    status: fieldStatus.required,
    display: "תגמולי מעביד",
    category: "hafkada",
    type: "combobox",
    forceNumeric: true,
    prefix: "%",
    rules: [rules.isNumber, rules.positive, rules.range(0, 20)],
    values: [0, 5.0, 6.0, 6.5, 7.0, 7.5],
    sug_mutzar: [1, 2, 3, 4, 5]
  },
  {
    name: "achuz_pitzuim",
    status: fieldStatus.required,
    display: "פיצויים מעביד",
    category: "hafkada",
    type: "combobox",
    forceNumeric: true,
    prefix: "%",
    rules: [rules.isNumber, rules.positive, rules.range(0, 20)],
    values: [0, 2.33, 6.0, 8.33],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "sug_hafrashot",
    display: "סוג הפרשות",
    category: "hafkada",
    type: "combobox",
    strictCombobox: true,
    values: [
      "עובד-שכיר",
      "מעסיק-שכיר",
      "עובד+מעסיק-שכיר",
      "עובד-עצמאי",
      "מעסיק-עצמאי",
      "עובד+מעסיק-עצמאי"
    ]
  },

  {
    name: "polisa_kidud_achid",
    display: "קידוד אחיד",
    category: "hanmaka"
  },

  {
    name: "avtacht_tsua",
    status: fieldStatus.required,
    display: "הבטחת תשואה",
    category: "hanmaka",
    type: "combobox",
    strictCombobox: true,
    values: ["כן", "לא"],
    sug_mutzar: [1, 2, 3, 5]
  },

  {
    name: "taarich_cium_avtacht_tesoa",
    display: "תאריך סיום הבטחה",
    category: "hanmaka",
    type: "date",
    sug_mutzar: [1, 2, 3, 5]
  },

  {
    name: "avtacht_tesoa",
    display: "הבטחת תשואה + תאריך סיום הבטחה",
    category: "hanmaka",
    sug_mutzar: [1, 2, 3, 5]
  },

  {
    name: "mekadem_havtachst_tochelet",
    display: "מקדם מגלם הבטחת תוחלת חיים?",
    category: "hanmaka",
    type: "combobox",
    strictCombobox: true,
    values: ["כן", "לא"],
    sug_mutzar: [1, 2, 5]
  },
  {
    name: "mekadem_havtachst_tocheletprisha",
    display: "הבטחת מקדם לכל תוחלת החיים מותנית בבחירת מסלול פרישה",
    category: "hanmaka",
    type: "combobox",
    strictCombobox: true,
    values: ["כן", "לא"],
    sug_mutzar: [1, 2, 5]
  },
  {
    name: "mekadem_havtachat_tsua",
    display: "המקדם מגלם הבטחת תשואה ?",
    category: "hanmaka",
    type: "combobox",
    strictCombobox: true,
    values: ["כן", "לא"],
    sug_mutzar: [1, 2, 5]
  },
  {
    name: "tochelet_mashpia_kitzba",
    display: "שינויים בתוחלת חיים משנים את הקצבה לאחר קבלת הקצבה",
    category: "hanmaka",
    type: "combobox",
    strictCombobox: true,
    values: ["כן", "לא"],
    sug_mutzar: [1, 2, 5]
  },
  {
    name: "tsua_mashpia_kitzba",
    display: "שינויים בתשואה משפיעים על מקדם לאחר קבלת קצבה",
    category: "hanmaka",
    type: "combobox",
    strictCombobox: true,
    values: ["כן", "לא"],
    sug_mutzar: [1, 2, 5]
  },

  {
    name: "kod_mezahe_yatzran",
    display: "ח.פ חברה מנהלת",
    category: "hevra",
    computed: true
  },
  {
    name: "shem_yatzran",
    display: "חברה מנהלת",
    category: "hevra",
    computed: true
  },
  {
    name: "service_quality",
    display: "איכות השירות",
    category: "hevra",
    computed: true
  },
  {
    name: "tifhul_quality",
    display: "איכות תפעול",
    category: "hevra",
    computed: true
  },
  {
    name: "tviyot_quality",
    display: "איכות ניהול תביעות",
    category: "hevra",
    computed: true
  },

  {
    name: "tagmulim_before2000",
    status: fieldStatus.required,
    display: "תג' עד 00",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "pitzuim_before2000",
    status: fieldStatus.required,
    display: "פצ' עד 00",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "tagmulim_honi_between2000and2008",
    status: fieldStatus.required,
    display: "תג' הון 00-08",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "pitzuim_honi_between2000and2008",
    status: fieldStatus.required,
    display: "פצ' הון 00-08",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "tagmulim_kitzba_between2000and2008",
    status: fieldStatus.required,
    display: "תג' קצבה 00-08",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "pitzuim_kitzba_between2000and2008",
    status: fieldStatus.required,
    display: "פצ' קצבה 00-08",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "tagmulim_kitzba_after2008",
    status: fieldStatus.required,
    display: "תג' קצבה מ-08",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "pitzuim_kitzba_after2008",
    status: fieldStatus.required,
    display: "פצ' קצבה מ-08",
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "honi_4_or_9",
    status: fieldStatus.required,
    display: 'הון (עבור קה"ש)',
    category: "yitrot",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [4, 9]
  },
  {
    name: "total_saving",
    display: 'סה"כ חסכון',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪"
  },
  {
    name: "saving_schum_bituach",
    display: 'סה"כ חסכון + ביטוח חיים',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "pitzuim_honi",
    display: 'סה"כ חסכון הוני (פיצויים בלבד)',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "tagmulim_honi",
    display: 'סה"כ חסכון הוני (תגמולים בלבד)',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "honi_all",
    display: 'סה"כ חסכון הוני',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "pitzuim_kitzba",
    display: 'סה"כ חסכון קצבה (פיצויים בלבד)',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "tagmulim_kitzba",
    display: 'סה"כ חסכון קצבה (תגמולים בלבד)',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "kitzba_all",
    display: 'סה"כ חסכון קצבה',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "pitzuim_all",
    display: 'סה"כ חסכון פיצויים',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "tagmulim_all",
    display: 'סה"כ חסכון תגמולים',
    category: "yitrot-sum",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "mekadem_hamara",
    display: "מקדם קצבה",
    category: "fv",
    computed: true,
    type: "number",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "mekadem_hamara_override",
    display: "מקדם קצבה ידני",
    tooltip: "שדה זה מאפשר לך לשנות את המקדם קצבה של המוצר בצורה ידנית",
    category: "fv",
    type: "number",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_kitzba_chodshei_im",
    display: "קצבה חודשית (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_kitzba_chodshei_lelo",
    display: "קצבה חודשית (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv-honi-title",
    display: "סך צבירה הונית",
    category: "fv",
    title: true,
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_honi_pitzuim_amount_im",
    display: "סך צבירה פיצויים הוניים (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_honi_pitzuim_amount_lelo",
    display: "סך צבירה פיצויים הוניים (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_honi_tagmulim_amount_im",
    display: "סך צבירה תגמולים הוניים (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_honi_tagmulim_amount_lelo",
    display: "סך צבירה תגמולים הוניים (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_honi_amount_im",
    display: "סך צבירה הונית (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪"
  },
  {
    name: "fv_honi_amount_lelo",
    display: "סך צבירה הונית (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪"
  },
  {
    name: "fv-kitzba-title",
    display: "סך צבירה למטרת קצבה",
    category: "fv",
    title: true,
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_kitzba_pitzuim_amount_im",
    display: "סך צבירה פיצויים למטרת קצבה (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_kitzba_pitzuim_amount_lelo",
    display: "סך צבירה פיצויים למטרת קצבה (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_kitzba_tagmulim_amount_im",
    display: "סך צבירה תגמולים למטרת קצבה (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_kitzba_tagmulim_amount_lelo",
    display: "סך צבירה תגמולים למטרת קצבה (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },

  {
    name: "fv_kitzba_amount_im",
    display: "סך צבירה למטרת קצבה (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_kitzba_amount_lelo",
    display: "סך צבירה למטרת קצבה (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv-tag-and-piz-title",
    display: "סך צבירה פיצויים/תגמולים",
    category: "fv",
    title: true,
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_pitzuim_amount_im",
    display: "סך צבירה פיצויים (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_pitzuim_amount_lelo",
    display: "סך צבירה פיצויים (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_tagmulim_amount_im",
    display: "סך צבירה תגמולים (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "fv_tagmulim_amount_lelo",
    display: "סך צבירה תגמולים (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },

  {
    name: "amount_to_prisha",
    display: "סה-כ כספים לגיל פרישה (כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪"
  },

  {
    name: "amount_to_prisha_lelo",
    display: "סה-כ כספים לגיל פרישה (לא כולל הפקדות)",
    category: "fv",
    computed: true,
    type: "number",
    suffix: "₪"
  },

  {
    name: "dmei_nihul_shiur_hafkada",
    status: fieldStatus.required,
    display: "דמי ניהול מפרמיה",
    category: "demi_nihul",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 20)],
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "dmei_nihul_shiur_tzvira",
    status: fieldStatus.required,
    display: "דמי ניהול מצבירה",
    category: "demi_nihul",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 20)]
  },
  {
    name: "dmei_nihul_shiur_mirevach",
    status: fieldStatus.required,
    display: "דמי ניהול מרווחים",
    category: "demi_nihul",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 20)],
    sug_mutzar: [1, 5]
  },
  {
    name: "dmei_nihul_shiur_tzvira_bafoal",
    display: "דמי ניהול צבירה בפעול",
    category: "demi_nihul",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 20)],
    computed: true
  },
  {
    name: "schum_max_dnhl_hafkada",
    status: fieldStatus.required,
    display: "מקס' דמי ניהול",
    category: "demi_nihul",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 5]
  },
  {
    name: "dmei_nihul_acherim",
    status: fieldStatus.required,
    display: "דמי ניהול אחרים",
    category: "demi_nihul",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 20)],
    sug_mutzar: [1, 5]
  },
  {
    name: "amlat_nihul_hitzoni",
    display: "עמלת ניהול חיצוני",
    category: "demi_nihul",
    computed: true,
    type: "number",
    suffix: "%",
  },
  {
    name: "shiur_alot_shnatit",
    display: "שיעור עלות שנתית (הנמקה)",
    category: "demi_nihul",
    computed: true,
    type: "number",
    suffix: "%",
  },
  {
    name: "sach_dmei_nihul_acherim",
    status: fieldStatus.required,
    display: "הוצאות  חודשיות אחרות",
    category: "demi_nihul",
    type: "number",
    suffix: "₪",
    rules: [rules.positive],
    sug_mutzar: [1, 5]
  },
  {
    name: "dmei_nihul_chodshei",
    display: 'ד"נ דמי ניהול חודשיים ב-₪',
    category: "demi_nihul",
    computed: true,
    type: "number",
    suffix: "₪"
  },
  {
    name: "dmei_nihul_shnati",
    display: 'ד"נ דמי ניהול שנתיים ב-₪',
    category: "demi_nihul",
    computed: true,
    type: "number",
    suffix: "₪"
  },
  {
    name: "dmei_nihul_gil_prisha",
    display: 'ד"נ דמי ניהול לגיל פרישה ב-₪',
    category: "demi_nihul",
    computed: true,
    type: "number",
    suffix: "₪"
  },
  {
    name: "dmei_nihul_hafkada",
    display: "דמי ניהול הפקדה",
    category: "demi_nihul",
    computed: true,
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 5]
  },
 


  {
    name: "sachar_kovea_le_nechut_ve_sheerim",
    display: "ההכנסה הקובעת לנכות ושאירים",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [2]
  },

  {
    name: "gil_prisha_lepensiyat_zikna",
    display: "תום תקופת ביטוח",
    category: "bituchim",
    forceNumeric: true,
    type: "select",
    values: [
      { value: 60, text: "60" },
      { value: 62, text: "62" },
      { value: 64, text: "64" },
      { value: 67, text: "67" }
    ],
    sug_mutzar: [1, 2, 5]
  },

  {
    name: "shaerim_all",
    display: 'סה"כ שארים',
    category: "bituchim",
    computed: true,
    sug_mutzar: [2]
  },

  {
    name: "sheerim_sug_vitor",
    display: "ביטוח שארים",
    category: "bituchim",
    forceNumeric: true,
    type: "select",
    values: [
      { value: 0, text: "ביטוח אלמן/אלמנה ויתום (0)" },
      { value: 1, text: "ללא ביטוח שארים בכלל (1)" },
      { value: 3, text: "ביטוח לאלמן/אלמנה בלבד (3)" },
      { value: 4, text: "ביטוח ליתום בלבד (4)" }
    ],
    sug_mutzar: [2]
  },

  {
    name: "sheerim_vitor_gil_60_and_above",
    display: "ויתור פנסיית שארים מגיל 60",
    category: "bituchim",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" },
      { value: 3, text: "לא רלוונטי (3)" }
    ],
    sug_mutzar: [2]
  },

  {
    name: "pansia_nechut",
    display: "פנסיית נכות",
    category: "bituchim",
    status: fieldStatus.nonrelevant,
    type: "number",
    suffix: "₪"
  },
  {
    name: "alman",
    display: "שארים אלמנה",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [2]
  },
  {
    name: "yatom",
    display: "שאירי יתום",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [2]
  },
  {
    name: "alman_alut",
    display: "שארים אלמנה עלות",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [2]
  },
  {
    name: "yatom_alut",
    display: "שארים יתום עלות",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [2]
  },
  {
    name: "kisuim_death",
    status: fieldStatus.required,
    display: "ביטוח מקרה מוות",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 3, 5]
  },
  {
    name: "death_alut",
    status: fieldStatus.required,
    display: "ב.מ.מ עלות",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 3, 5]
  },
  {
    name: "kisuim_nechut",
    status: fieldStatus.required,
    display: "אובדן כושר עבודה (א.כ.ע) / פנסיית נכות",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 5]
  },
  {
    name: "nechut_alut",
    status: fieldStatus.required,
    display: "עלות א.כ.ע / פנסיית נכות",
    category: "bituchim",
    type: "number",
    suffix: "₪",
    sug_mutzar: [1, 2, 5]
  },
  {
    name: "nechut_mitpatahat",
    status: fieldStatus.required,
    display: "פנסיית נכות מתפתחת",
    category: "bituchim",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" }
    ],
    sug_mutzar: [2]
  },
  {
    name: "kolel_prenzisa",
    status: fieldStatus.required,
    display: "פרנציזה (נכות כפולה)",
    category: "bituchim",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" }
    ],
    sug_mutzar: [1, 2, 5]
  },
  {
    name: "nechut_vitor_gil_60_and_above",
    status: fieldStatus.required,
    display: "ויתור פנסיית נכות מגיל 60",
    category: "bituchim",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "כן (1)" },
      { value: 2, text: "לא (2)" },
      { value: 3, text: "לא רלוונטי (3)" }
    ],
    sug_mutzar: [2]
  },

  {
    name: "kod_maslul_bituach",
    display: "קוד מסלול ביטוח",
    category: "bituchim",
    sug_mutzar: [1, 2, 3, 5]
  },
  {
    name: "maslul_bituach",
    display: "מסלול ביטוח",
    category: "bituchim",
    computed: true,
    sug_mutzar: [1, 2, 3, 5]
  },

  {
    name: "mispar_kupa",
    display: "מס' מס הכנסה",
    category: "maslul",
    computed: true
  },
  {
    name: "mispar_maslul",
    display: "מס' מסלול",
    category: "maslul"
  },
  {
    name: "shm_maslul",
    status: fieldStatus.required,
    display: "מסלול ההשקעה",
    category: "maslul",
    computed: true
  },
  {
    name: "maslul_breyrat_mechdal",
    display: "ברירת מחדל מסלול",
    category: "maslul",
    sug_mutzar: [1, 2, 3, 5],
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "לא (0)" },
      { value: 1, text: "כן (1)" }
    ]
  },
  {
    name: "ramat_sikun_hashkaot",
    display: "רמת סיכון השקעה",
    category: "maslul",
    computed: true,
    status: fieldStatus.nonrelevant
  },
  {
    name: "hitmahut_mishnit",
    display: "קטגורית השקעה",
    category: "maslul",
    computed: true
  },
  {
    name: "divuach_achron",
    display: "תאריך עדכון נתוני מסלול",
    category: "maslul",
    computed: true
  },

  {
    name: "tsua_mi_thilat_shana",
    display: "תשואה מתחילת שנה",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_mitztaberet_6_hodashim",
    display: "תשואה 6 חודשים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_mitztaberet_12_hodashim",
    display: "תשואה 12 חודשים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_mitztaberet_24_hodashim",
    display: "תשואה 24 חודשים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_mitztaberet_36_hodashim",
    display: "תשואה 36 חודשים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_mitztaberet_60_hodashim",
    display: "תשואה 60 חודשים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_memuzaat_2_shanim",
    display: "תשואה ממוצע 2 שנים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_memuzaat_3_shanim",
    display: "תשואה ממוצע 3 שנים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "tsua_memuzaat_5_shanim",
    display: "תשואה ממוצע 5 שנים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "stiat_teken_36_hodashim",
    display: "סטיית תקן 36 חודשים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "stiat_teken_60_hodashim",
    display: "סטיית תקן 60 חודשים",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "mazan_actuari",
    display: "גרעון/עודף אקטוארי",
    category: "maslul",
    computed: true,
    type: "number",
    sug_mutzar: [2]
  },
  {
    name: "sharp_anaf",
    display: "שארפ ענף",
    category: "maslul",
    computed: true,
    type: "number"
  },
  {
    name: "sharp_tsua_hetzyonit_anaf",
    display: "שארפ חציוני ענף",
    category: "maslul",
    computed: true,
    type: "number"
  },
  {
    name: "sharp_ribit_hasrat_sikun",
    display: "שארפ חסר סיכון",
    category: "maslul",
    computed: true,
    type: "number"
  },
  {
    name: "ramat_sikun_menayot",
    display: "אחוז מניות",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "ramat_sikun",
    display: "דרגת סיכון במניות",
    category: "maslul",
    computed: true
  },
  {
    name: "ribit_neto",
    display: "ריבית נטו",
    category: "maslul",
    computed: true,
    type: "number",
    suffix: "%"
  },
  {
    name: "ribit_broto",
    status: fieldStatus.required,
    display: "ריבית ברוטו",
    category: "maslul",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 20)]
  },

  {
    name: "ribit_broto_prisha",
    display: "ריבית ברוטו מגיל פרישה",
    tooltip:
      "אשאר שדה ריק עבור ריבית ברירת מחדל לגיל פרישה. אחרת, קבע את הריבית הרצויה למוצר זה.",
    category: "maslul",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 20)]
  },

  {
    name: "sug_keren_pensia",
    status: fieldStatus.required,
    display: "סוג קרן פנסיה",
    category: "penisa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "יסוד (1)" },
      { value: 2, text: "מקיפה (2)" },
      { value: 3, text: "כללית (3)" }
    ],
    sug_mutzar: [2]
  },
  {
    name: "pensia_vatika_o_hadasha",
    status: fieldStatus.required,
    display: "קרן פנסיה (חדשה/ותיקה)",
    category: "penisa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "ותיקה (1)" },
      { value: 2, text: "חדשה (2)" }
    ],
    sug_mutzar: [2]
  },

  {
    name: "pensia_shiur_kisui_alman_be_prisha",
    display: "שיעור כיסוי לאלמן לאחר פרישה",
    category: "penisa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "0%" },
      { value: 30, text: "30%" },
      { value: 60, text: "60%" },
      { value: 100, text: "100%" }
    ],
    sug_mutzar: [2]
  },

  {
    name: "pensia_tkufat_havtacha_month",
    display: "תקופת הבטחת תשלומים פנסיה",
    category: "penisa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "ללא (0 חודשים)" },
      { value: 60, text: "חודשים 60" },
      { value: 120, text: "חודשים 120" },
      { value: 180, text: "חודשים 180" },
      { value: 240, text: "חודשים 240" }
    ],
    sug_mutzar: [2]
  },

  {
    name: "mispar_months_bakeren_till_now",
    display: "מספר חודשים נוכחי בקרן",
    category: "pensia_vatika",
    type: "number",
    class: "rtl",
    forceNumeric: true,
    suffix: "חודש",
    sug_mutzar: [2]
  },
  {
    name: "mispar_months_bakeren_till_prisha",
    display: "מספר חודשים בקרן עד פרישה",
    category: "pensia_vatika",
    type: "number",
    class: "rtl",
    forceNumeric: true,
    suffix: "חודש",
    computed: true,
    sug_mutzar: [2]
  },
  {
    name: "ahuz_pensiya_tzvura",
    display: "אחוז פנסיה צבורה",
    category: "pensia_vatika",
    type: "number",
    forceNumeric: true,
    suffix: "%",
    sug_mutzar: [2]
  },

  {
    name: "pensia_vatika_gironit_o_meuzenet",
    display: "סוג פוליסה ותיקה",
    category: "pensia_vatika",
    type: "select",
    values: [
      { value: "", text: "" },
      { value: "gironit", text: "גרעונית" },
      { value: "meuzenet", text: "מאוזנת" }
    ],
    sug_mutzar: [2]
  },

  {
    name: "amit_mevutach_tkufat_beynaim",
    display: "תקופת ביניים",
    category: "pensia_vatika",
    type: "number",
    forceNumeric: true,
    suffix: "%",
    sug_mutzar: [2]
  },
  {
    name: "ahuz_vetek_now",
    display: "אחוז ותק נוכחי",
    category: "pensia_vatika",
    computed: true,
    type: "number",
    forceNumeric: true,
    suffix: "%",
    sug_mutzar: [2]
  },
  {
    name: "ahuz_vetek_prisha",
    display: "אחוז ותק עד פרישה",
    category: "pensia_vatika",
    computed: true,
    type: "number",
    forceNumeric: true,
    suffix: "%",
    sug_mutzar: [2]
  },

  {
    name: "sachar_koveaa_lelo",
    display: "שכר קובע ללא הפקדות",
    category: "pensia_vatika",
    type: "number",
    forceNumeric: true,
    suffix: "₪",
    sug_mutzar: [2]
  },
  {
    name: "sachar_koveaa_im",
    display: "שכר קובע כולל הפקדות",
    category: "pensia_vatika",
    type: "number",
    forceNumeric: true,
    suffix: "₪",
    sug_mutzar: [2]
  },

  {
    name: "months_pensia_yesod_lelo",
    display: "חודשי פנסית יסוד לא כולל הפקדה",
    category: "pensia_vatika",
    sug_mutzar: [2]
  },
  {
    name: "months_pensia_yesod_im",
    display: "חודשי פנסית יסוד כולל הפקדה",
    category: "pensia_vatika",
    sug_mutzar: [2]
  },
  {
    name: "months_pensia_mekifa_lelo",
    display: "חודשי פנסיה מקיפה לא כולל הפקדה",
    category: "pensia_vatika",
    status: fieldStatus.nonrelevant,
    sug_mutzar: [2]
  },
  {
    name: "months_pensia_mekifa_im",
    display: "חודשי פנסיה מקיפה כולל הפקדה",
    category: "pensia_vatika",
    status: fieldStatus.nonrelevant,
    sug_mutzar: [2]
  },
  {
    name: "maanak_odfot_lelo",
    display: "מענק שנים עודפות בגיל פרישה לא כולל הפקדות",
    category: "pensia_vatika",
    type: "number",
    forceNumeric: true,
    suffix: "₪",
    sug_mutzar: [2]
  },
  {
    name: "maanak_odfot_im",
    display: "מענק שנים עודפות בגיל פרישה כולל הפקדות",
    category: "pensia_vatika",
    type: "number",
    forceNumeric: true,
    suffix: "₪",
    sug_mutzar: [2]
  },

  /// non-editable fields
  {
    name: "recommendations",
    display: "המלצות",
    category: "details",
    status: fieldStatus.nonrelevant
  },

  {
    name: "nazil",
    status: fieldStatus.highlighted,
    computed: true,
    display: "נזיל",
    category: "nenzilut_halvaot",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 0, text: "לא (0)" },
      { value: 1, text: "כן (1)" }
    ],
    sug_mutzar: [1, 2, 3, 4, 5, 9]
  },

  {
    name: "nazil_amount",
    status: fieldStatus.highlighted,
    computed: true,
    display: 'סה"כ כסף נזיל',
    category: "nenzilut_halvaot",
    type: "number",
    forceNumeric: true,
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 4, 5, 9]
  },

  {
    name: "not_nazil_amount",
    status: fieldStatus.highlighted,
    computed: true,
    display: 'סה"כ כסף נזיל עתידי',
    category: "nenzilut_halvaot",
    type: "number",
    forceNumeric: true,
    suffix: "₪",
    sug_mutzar: [1, 2, 3, 4, 5, 9]
  },
  
  {
    name: "nazil_in_months",
    status: fieldStatus.highlighted,
    computed: true,
    display: "הופך לנזיל עוד # חודשים",
    category: "nenzilut_halvaot",
    type: "number",
    forceNumeric: true,
    sug_mutzar: [1, 2, 3, 4, 5, 9]
  },

  {
    name: "fixes",
    display: "תיקונים",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "mutavim",
    display: "מוטבים",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  }
];

let categories = [
  { name: "details", display: "פרטים כללים", fields: [] },
  { name: "hevra", display: "חברה מנהלת", fields: [] },
  { name: "hafkada", display: "הפקדה חודשית + שיעורי הפרשות", fields: [] },
  { name: "yitrot", display: "סך חסכון נוכחי לעמית (שכבות)", fields: [] },
  { name: "yitrot-sum", display: "סך חסכון נוכחי לעמית (סיכום)", fields: [] },
  { name: "fv", display: "סך חסכון עתידי לעמית לגיל פרישה", fields: [] },
  { name: "demi_nihul", display: "עלויות", fields: [] },
  { name: "bituchim", display: "כיסויים ביטוחיים", fields: [] },
  { name: "maslul", display: "מסלול השקעה, תשואות ורווחים", fields: [] },
  { name: "hanmaka", display: "תוחלת חיים / הבטחת תשואה (הנמקה)", fields: [] },
  { name: "penisa", display: "קרן פנסיה", fields: [] },
  { name: "pensia_vatika", display: "נתונים ידניים פנסיה ותיקה", fields: [] },
  { name: "nenzilut_halvaot", display: "נזילות והלוואות", fields: [] }
];

//index fields
let fieldByName = {};
fields.forEach((field) => (fieldByName[field.name] = field));

//index categories
let categoryByName = {};
categories.forEach((category) => (categoryByName[category.name] = category));

//populate categories fields
fields.forEach((field) => categoryByName[field.category].fields.push(field));

export default {
  fields,
  fieldByName,
  categories,
  categoryByName
};
