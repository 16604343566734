export default function createRemoteResource(Vue, options) {
  let vm = new Vue({
    data() {
      return {
        content: undefined,
        remoteContentJson: undefined,
        loading: false,
        updating: false
      };
    },
    methods: {
      async getRemoteJson() {
        return JSON.stringify(await this.$backend.getJson(options.url));
      },

      async load({ force } = {}) {
        if (!this.content || force) {
          try {
            this.loading = true;
            this.remoteContentJson = await this.getRemoteJson();
          } finally {
            this.loading = false;
          }
          this.content = JSON.parse(this.remoteContentJson);
        }
        return this.content;
      },

      set(newContent, setRemote = false) {
        this.content = newContent;
        if (setRemote) {
          this.remoteContentJson = JSON.stringify(newContent);
        }
        return this.content;
      },

      async update(newContent) {
        newContent = newContent || this.content;
        try {
          this.updating = true;
          await this.$backend.postJson(options.url, newContent);
        } finally {
          this.updating = false;
        }

        return this.set(newContent, true);
      },

      checkIfChanged() {
        let currentJson = JSON.stringify(this.content);
        return currentJson != this.remoteContentJson;
      },

      async checkIfChangedOnServer() {
        let remoteJson = await this.getRemoteJson();
        return remoteJson != this.remoteContentJson;
      }
    }
  });

  return vm;
}
