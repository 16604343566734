import { lookupField, moneyField } from "./utils";

/**
bank: 0
cash_end_of_month: 18000
cash_flow: 18000
cost_of_living: 0
expenses: {cost_of_living: 0, maskanta: 10000, insurance: 0, other: 0, other_honi: 0}
  cost_of_living: 0
  insurance: 0
  maskanta: 10000
  other: 0
  other_honi: 0
honi: 151078.07
honi_and_bank: 151078.07
incomes: {sachar_neto: 28000, extra_monthly_income: 0, kitzba_neto: 0, bituach_lemui: 0, other_honi: 0}
  bituach_lemui: 0
  extra_monthly_income: 0
  kitzba_neto: 0
  other_honi: 0
  sachar_neto: 28000
inflation: 1
month: "2018-07-31"
month_summary: [{type: "income", shem_tashlum: "שכר נטו", amount: 28000},…]
  0: {type: "income", shem_tashlum: "שכר נטו", amount: 28000}
  1: {type: "expense", shem_tashlum: "משכנתא", amount: 10000}
retired: false
total_monthly_expense: 10000
total_monthly_expense_with_honi: 10000
total_monthly_income: 28000
total_monthly_income_with_honi: 28000
tsua_honi: 503.5935666666666
withdraw: {bank: 0, honi: 0, gap: 0}
  bank: 0
  gap: 0
  honi: 0
yerusha: 0
 */

let prishaMonthResolvers = {
  month: { display: "תאריך" },
  month_only: {
    getValue: (item) => item.month.substring(0, 7),
    display: "חודש"
  },
  age: { display: "גיל" },
  retired: lookupField("סטאטוס", {
    false: "לפני פרישה",
    true: "בפרישה"
  }),

  sachar_neto: moneyField("שכר נטו", {
    getValue: (item) => item.incomes.sachar_neto
  }),
  kitzba_neto: moneyField("קצבה חודשית נטו", {
    getValue: (item) => item.incomes.kitzba_neto
  }),
  bituach_lemui: moneyField("ביטוח לאומי", {
    getValue: (item) => item.incomes.bituach_lemui
  }),
  extra_monthly_income: moneyField("הכנסה חודשית נוספת", {
    getValue: (item) => item.incomes.extra_monthly_income
  }),
  total_monthly_income_with_honi: moneyField('סה"כ הכנסה חודשית'),
  cost_of_living: moneyField("עלות מחייה", {
    getValue: (item) => item.expenses.cost_of_living
  }),
  maskanta: moneyField("משכנתא", {
    getValue: (item) => item.expenses.maskanta
  }),
  insurance: moneyField("ביטוח", {
    getValue: (item) => item.expenses.insurance
  }),
  extra_monthly_expenses: moneyField("הוצאות נוספות / חד פעמיות", {
    getValue: (item) =>
      (item.expenses.other || 0) + (item.expenses.other_honi || 0)
  }),
  total_monthly_expense_with_honi: moneyField('סה"כ הוצאות חודשיות'),
  cash_end_of_month: moneyField("מאזן חודשי"),
  bank: moneyField("עו״ש"),
  honi: moneyField("נכסים הוניים"),
  honi_and_bank: moneyField("נכסים הוניים ועו״ש"),
  yerusha: moneyField("נכסים יעודים לירושה"),
  honi_and_bank_and_yerusha: moneyField("נכסים הוניים, עו״ש וכספים לירושה")
};

export default prishaMonthResolvers;
