<template>
  <div>
    <v-autocomplete
      dense
      outlined
      :value="currentField"
      :items="fields"
      :label="label"
      return-object
      @change="$emit('input', $event.value)"
      hide-details
      :append-outer-icon="records.length > 0 ? 'mdi-file-search-outline' : ''"
      @click:append-outer="showCategoryFields()"
    ></v-autocomplete>
    <v-dialog v-model="showCategory" width="80%" scrollable>
      <v-card height="688">
        <v-card-title class="primary white--text">
          <v-container class="pa-0">
            <v-row no-gutters class="title">
              <v-col cols="6"> רשומות עבור קטגוריה {{ categoryName }} </v-col>
              <v-col cols="4">
                <v-btn icon dark @click="changeRecordIndex(-1)"
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
                רשומה {{ recordIndex + 1 }} מתוך {{ records.length }}
                <v-btn icon dark @click="changeRecordIndex(+1)"
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
              </v-col>
              <v-col class="text-left">
                <v-btn dark icon @click="showCategory = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  solo
                  hide-details
                  dense
                  prepend-inner-icon="mdi-magnify"
                  v-model="fieldSearch"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text class="px-0">
          <v-container>
            <v-row
              dense
              v-for="(field, index) in matchResolvedFields"
              :key="index"
              class="title field-row"
              @dblclick="selectField(field.field)"
            >
              <v-col
                >{{ field.display }}
                <div class="caption">{{ field.field }}</div>
              </v-col>
              <v-col class="black--text"
                ><field-content :field="field"></field-content
              ></v-col>
              <v-col cols="1" align-self="center">
                <v-btn color="primary" @click="selectField(field.field)"
                  >בחר</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-btn @click="showCategory = false">סגור</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FieldContent from "./FieldContent.vue";
export default {
  components: { FieldContent },
  props: {
    context: { type: String, required: true },
    value: { type: String },
    nullable: { type: Boolean, default: false },
    label: { type: String, default: "שדה" }
  },
  data() {
    return {
      currentField: undefined,

      resolvedFields: [],
      recordIndex: 0,
      showCategory: false,
      fieldSearch: ""
    };
  },
  inject: {
    dataContextProvider: { default: null }
  },
  computed: {
    fields() {
      let fieldsDetails = this.$store.dataContext.fieldResolvers[this.context];
      if (!fieldsDetails) {
        return [];
      }
      let fields = Object.keys(fieldsDetails).map((field) => ({
        value: field,
        text: fieldsDetails[field].display
      }));
      if (this.nullable) {
        fields.unshift({ value: undefined, text: "ללא" });
      }
      return fields;
    },
    categoryName() {
      return this.$store.dataContext.getContextType(this.context).display;
    },
    contextProvider() {
      return this.dataContextProvider && this.dataContextProvider.context;
    },
    records() {
      return this.contextProvider
        ? this.contextProvider.contexts[this.context]
        : [];
    },
    record() {
      return this.records[this.recordIndex];
    },
    matchResolvedFields() {
      if (this.fieldSearch) {
        return this.resolvedFields.filter(
          (field) =>
            field.display.indexOf(this.fieldSearch) !== -1 ||
            `${field.fieldValue}`.indexOf(this.fieldSearch) !== -1 ||
            `${field.fieldDisplay}`.indexOf(this.fieldSearch) !== -1
        );
      } else {
        return this.resolvedFields;
      }
    }
  },
  watch: {
    value() {
      this.loadFieldDetails();
    }
  },
  methods: {
    loadFieldDetails() {
      let fieldsDetails = this.$store.dataContext.fieldResolvers[this.context];
      if (!fieldsDetails) {
        this.currentField = {
          value: this.value,
          text: this.value
        };
      } else {
        let fieldDetails = fieldsDetails[this.value];
        this.currentField = {
          value: this.value,
          text: fieldDetails ? fieldDetails.display : this.value
        };
      }
    },
    showCategoryFields() {
      this.recordIndex = 0;
      this.resolveFields();
      this.showCategory = true;
    },
    changeRecordIndex(delta) {
      this.recordIndex += delta;
      if (this.recordIndex < 0) {
        this.recordIndex += this.records.length;
      } else if (this.recordIndex >= this.records.length) {
        this.recordIndex -= this.records.length;
      }
      this.resolveFields();
    },
    resolveFields() {
      let fields = Object.keys(
        this.$store.dataContext.fieldResolvers[this.context]
      );
      let resolvedFields = [];
      for (let field of fields) {
        resolvedFields.push(
          this.contextProvider.resolveValueAndDisplay(
            this.context,
            field,
            "first",
            [this.record]
          )
        );
      }
      this.resolvedFields = resolvedFields;
    },
    selectField(field) {
      this.$emit("input", field);
      this.showCategory = false;
    }
  },
  mounted() {
    this.loadFieldDetails();
  }
};
</script>

<style scoped>
.field-row {
  border-radius: 5px;
}

.field-row:not(:hover) button {
  display: none;
}

.field-row:hover {
  background: #efefef;
}
</style>
