export default function install(Vue) {
  let storeModule = new Vue({
    data() {
      return {
        definitions: [],
        loadingDefinitions: {},
        active: []
      };
    },
    computed: {},
    methods: {
      _findDefinitionById(id) {
        return this.definitions.find((def) => def.id == id);
      },
      _findActiveById(id) {
        return this.active.find((flow) => flow.id == id);
      },
      async loadDefinitions() {
        this.definitions = await this.$backend.getJson("/flow/definition");
        return this.definitions;
      },
      async loadDefinitionById(flowId) {
        let flow = this._findDefinitionById(flowId);
        if (!flow) {
          if (!(flowId in this.loadingDefinitions)) {
            this.loadingDefinitions[flowId] = this.$backend.getJson(
              "/flow/definition/" + flowId
            );
          }
          flow = await this.loadingDefinitions[flowId];
          this.definitions.push(flow);
          delete this.loadingDefinitions[flowId];
        }
        return flow;
      },
      async removeDefinition(definition) {
        await this.$backend.delete("/flow/definition/" + definition.id);
        let found = this._findDefinitionById(definition.id);
        if (found) {
          let index = this.definitions.indexOf(found);
          this.definitions.splice(index, 1);
        }
      },
      async updateDefinition(definition) {
        let newDefinition = await this.$backend.postJson(
          "/flow/definition/" + definition.id,
          definition
        );
        let found = this._findDefinitionById(definition.id);
        if (found) {
          let index = this.definitions.indexOf(found);
          this.definitions[index] = newDefinition;
        } else {
          this.definitions.push(newDefinition);
        }
      },
      async createActiveFlowFromDefinition(definition, tags) {
        let newActive = await this.$backend.postJson(
          "/flow/definition/" + definition.id + "/active",
          { tags }
        );
        this.active.push(newActive);
        return newActive;
      },
      async loadActives() {
        this.active = await this.$backend.getJson("/flow/active");
        return this.active;
      },
      async removeActive(active) {
        await this.$backend.delete("/flow/active/" + active.id, active);
        let found = this._findActiveById(active.id);
        if (found) {
          let index = this.active.indexOf(found);
          this.active.splice(index, 1);
        }
      },
      async updateActive(active) {
        let newActive = await this.$backend.postJson(
          "/flow/active/" + active.id,
          active
        );
        let found = this._findActiveById(active.id);
        if (found) {
          let index = this.active.indexOf(found);
          this.active[index] = newActive;
        } else {
          this.active.push(newActive);
        }
        return newActive;
      },
      loadActiveFlowsForFlowId(flowId) {
        return this.$backend.getJson("/flow/active", {
          params: { $flowId: flowId }
        });
      },
      loadActiveFlowsForClient(clientId) {
        return this.$backend.getJson("/flow/active", {
          params: { clientId }
        });
      },

      async addStepLog(activeFlowId, stepId, stepStatus) {
        let newActive = await this.$backend.postJson(
          "/flow/active/" + activeFlowId + "/stepsLog",
          { stepId, stepStatus }
        );
        let found = this._findActiveById(activeFlowId);
        if (found) {
          let index = this.active.indexOf(found);
          this.active[index] = newActive;
        } else {
          this.active.push(newActive);
        }
        return newActive;
      }
    }
  });

  return storeModule;
}
