import { dateField, moneyField, percentField } from "./utils";

import moment from "moment";

let budgetItemsResolver = {
  index: { display: "מספר שורה" },
  category: { display: "קטגוריה" },
  schum_tashlum: moneyField("סכום"),
  shem_tashlum: { display: "תאור" },

  schum_tashlum_shanti: moneyField("סכום שנתי", {
    getValue(item) {
      switch (item.tadirut_tashlum) {
        case "חודשי":
          return +item.schum_tashlum * 12;
        case "רבעוני":
          return +item.schum_tashlum * 4;
        default:
          return +item.schum_tashlum;
      }
    }
  }),

  achuz_tashlum: percentField("אחוז שכלול"),
  achuz_tashlum_death: percentField("אחוז שכלול במקרה מוות"),

  schum_tashlum_bafoal: moneyField("סכום משוכלל לתקציב", {
    getValue(item) {
      if (item.achuz_tashlum) {
        return item.schum_tashlum * item.achuz_tashlum / 100;
      }
      return 0;
    }
  }),

  schum_tashlum_bafoal_death: moneyField("סכום משוכלל לביטוח", {
    getValue(item) {
      if (item.achuz_tashlum_death) {
        return item.schum_tashlum * item.achuz_tashlum_death / 100;
      }
      return 0;
    }
  }),

  taarich_techilat_tashlum: dateField("תאריך התחלה"),
  taarich_sium_tashlum: dateField("תאריך סיום"),
  tadirut_tashlum: { display: "תדירות" },
  ribit: percentField("אחוז הצמדה"),
  changes: {
    display: "שינויים",
    getValue(item) {
      let changes = (item.changes || []).map((change) => {
        let date = moment(change.taarich_shinuy).format("DD/MM/YYYY");
        let percent = (100 * change.schum) / item.schum_tashlum;
        let percent_str = Math.abs(percent).toFixed(2) + "%";
        if (percent > 0) {
          return `מ-${date} הגדלה של ${percent_str}`;
        } else if (percent < 0) {
          return `מ-${date} הקטנה של ${percent_str}`;
        }
      });
      if (item.ribit > 0) {
        changes.unshift("הצמדה של " + (+item.ribit).toFixed(0) + "%");
      }
      return changes.join(", ");
    }
  }
};

export default budgetItemsResolver;
