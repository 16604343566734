<template>
  <div class="ltr d-inline-block" v-if="field.fieldType == 'money'">
    {{ field.fieldValue | money2 }}
  </div>
  <span v-else-if="field.fieldType == 'percent'">{{
    field.fieldValue | percent
  }}</span>
  <span v-else-if="field.fieldType == 'date'">{{
    field.fieldValue | date
  }}</span>
  <span v-else-if="field.fieldType == 'datetime'">{{
    field.fieldValue | datetime
  }}</span>
  <span v-else-if="field.fieldType == 'age'">{{
    field.fieldValue | round(1)
  }}</span>
  <company-logo
    v-else-if="field.fieldType == 'logo'"
    :kod_mezahe_yatzran="field.fieldValue"
    :size="size"
  ></company-logo>
  <v-icon v-else-if="field.fieldType == 'status'" :color="field.fieldValue"
    >mdi-circle</v-icon
  >
  <div v-else-if="field.ltr" class="ltr d-inline-block">
    {{ field.fieldValue }}
  </div>
  <span v-else>{{ field.fieldDisplay || field.fieldValue }}</span>
</template>

<script>
import CompanyLogo from "../CompanyLogo.vue";

export default {
  components: { CompanyLogo },
  props: {
    field: { type: Object, required: true },
    size: { type: Number, default: 64 }
  }
};
</script>

<style></style>
