<template>
  <div :style="style" class="palette">
    <div
      class="step"
      v-for="(step, index) of steps"
      :key="index"
      :style="{ background: step }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    palette: { type: [Object, Array] },
    width: { type: [Number, String] },
    height: { type: [Number, String] }
  },
  computed: {
    steps() {
      if (Array.isArray(this.palette)) {
        return this.palette;
      }
      return [];
    },
    style() {
      if (!this.palette) {
        return {};
      }

      let background;
      if (Array.isArray(this.palette)) {
        background = `trasparent`;
      } else {
        background = `linear-gradient(to right,${this.palette.from} 0%,${this.palette.to} 100%)`;
      }
      return {
        background: background,
        width: this.width,
        height: this.height,
        borderRadius: this.height
      };
    }
  }
};
</script>

<style scoped>
.palette {
  display: flex;
  justify-items: space-between;
  flex-wrap: nowrap;
}

.palette .step {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0px 2px;
}
</style>
