<template>
  <div class="ordered-list">
    <v-row v-for="(item, index) of list" :key="index" dense>
      <v-col>
        <v-card class="item">
          <v-card-text>
            <slot v-bind:item="item" :v-bind:index="index"></slot>
            <slot name="tools" v-bind:item="item" :v-bind:index="index">
              <div class="floating-tools">
                <v-btn x-small icon @click="moveListField(index, -1)"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn
                >
                <v-btn x-small icon @click="moveListField(index, 1)"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
                <v-btn
                  x-small
                  icon
                  color="primary"
                  class="my-2"
                  @click="duplicate(index)"
                  ><v-icon>mdi-content-copy</v-icon></v-btn
                >
                <v-btn
                  x-small
                  icon
                  color="error"
                  @click="removeListField(index)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </slot>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-btn block @click="addNewItem"
          ><v-icon left>mdi-plus</v-icon> {{ newItemLabel }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    list: { type: Array, required: true },
    newItemLabel: { type: String, default: "הוסף" },
    createNewItem: { type: Function, required: true }
  },

  methods: {
    removeListField(index) {
      this.list.splice(index, 1);
    },
    duplicate(index) {
      let clone = JSON.parse(JSON.stringify(this.list[index]));
      this.list.splice(index, 0, clone);
    },
    moveListField(index, delta) {
      let newIndex = index + delta;
      if (newIndex >= 0 && newIndex <= this.list.length - 1) {
        let records = this.list.splice(index, 1);
        this.list.splice(newIndex, 0, ...records);
      }
    },
    addNewItem() {
      let newItem = this.createNewItem();
      this.list.push(newItem);
    }
  }
};
</script>

<style>
.ordered-list .item {
  position: relative;
}
.ordered-list .item .floating-tools {
  position: absolute;
  background: white;
  border: 1px solid #aaa;
  border-radius: 20px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  left: -16px;
  top: calc(50% - 52px);
  z-index: 100;
  opacity: 0;
  transition: all 0.2s ease;
}

.ordered-list .item:hover .floating-tools {
  opacity: 1;
}
</style>
