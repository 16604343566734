import createRemoteResource from "./remoteResource";
import { v4 as uuid } from "uuid";

function segmentsToUrl(segments) {
  return (
    "/" +
    segments
      .map((segment) => segment.path)
      .filter((path) => path)
      .join("/")
  );
}

function segmentsToTitle(segments) {
  return segments.map((segment) => segment.title).join(" > ");
}

function segmentsToDetailsField(segments) {
  for (let segment of segments) {
    if (segment.detailsField) {
      return {
        field: segment.detailsField,
        context: segment.context
      };
    }
  }
  return undefined;
}

function segmentsToVisible(segments) {
  return segments[segments.length - 1].visible;
}

export default function install(Vue) {
  let pagesVm = createRemoteResource(Vue, { url: "/content/pages?package=default" });
  let tooltipsVm = createRemoteResource(Vue, { url: "/content/tooltips?package=default" });

  let storeModule = new Vue({
    data() {
      return {
        currentPackage: "default",

        dashboardClipboard: undefined,

        //used to recall which page should we load on open the page.
        currentPage: undefined,

        //used to edit a specific package.
        packageLocked: false,

        remoteResources: {
          pages: pagesVm,
          tooltips: tooltipsVm
        }
      };
    },

    computed: {
      pages() {
        return this.remoteResources.pages.content || [];
      },
      tooltips() {
        return this.remoteResources.tooltips.content || [];
      },
      lookupMenus() {
        let lookup = {};
        for (let menu of this.pages) {
          lookup[menu.id] = { menu: menu, parent: null };
          for (let subMenu of menu.menuItems) {
            lookup[subMenu.id] = { menu: subMenu, parent: menu };
          }
        }
        return lookup;
      },
      lookupPages() {
        let lookup = {};
        for (let menuId in this.lookupMenus) {
          let menu = this.lookupMenus[menuId].menu;

          for (let page of menu.pages) {
            lookup[page.id] = { page: page, parent: null, menu: menu };
            for (let subPage of page.subPages) {
              lookup[subPage.id] = { page: subPage, parent: page, menu: menu };
            }
          }
        }
        return lookup;
      },
      allPagesUrls() {
        return Object.values(this.lookupPages).map((pageLookup) => {
          let segments = this.getPathToPage(pageLookup.page);
          return {
            url: segmentsToUrl(segments),
            detailsField: segmentsToDetailsField(segments),
            visible: segmentsToVisible(segments),
            title: segmentsToTitle(segments)
          };
        });
      },
      tooltipsLookup() {
        let lookup = {};
        for (let tooltip of this.tooltips) {
          lookup[tooltip.uuid] = tooltip;
        }
        return lookup;
      }
    },

    methods: {
      loadPackages() {
        return this.$backend.getJson("/content/packages");
      },

      lockPackage(packageName) {
        this.packageLocked = true;
        this.loadPackage(packageName);
      },

      async loadPackageBaseOnContent() {
        if (this.packageLocked) {
          return;
        }

        if (this.$account.contentPackage != "default") {
          return this.loadPackage(this.$account.contentPackage);
        }

        if (this.$store.dataContext.reportHasItems) {
          await this.loadPackage("default");
        } else {
          await this.loadPackage("no-data");
        }
      },

      async loadPackage(packageName) {
        if (this.currentPackage != packageName) {
          this.currentPackage = packageName;
          pagesVm = createRemoteResource(Vue, {
            url: "/content/pages?package=" + this.currentPackage
          });
          tooltipsVm = createRemoteResource(Vue, {
            url: "/content/tooltip?package=" + this.currentPackage
          });
          this.remoteResources.pages = pagesVm;
          this.remoteResources.tooltips = tooltipsVm;

          await this.load(true);
        } else {
          await this.load();
        }
      },

      async load(force) {
        if (this.pages.length == 0 || force) {
          let { pages, tooltips } = await this.$backend.getJson(
            "/content/all?package=" + this.currentPackage
          );
          pagesVm.set(pages, true);
          tooltipsVm.set(tooltips, true);
        }
      },

      async updatePages(siteContent) {
        await pagesVm.update(siteContent);
        return this.pages;
      },

      getMenuForPage(page) {
        if (!page) { 
          return undefined;
        }
        let pageLookup = this.lookupPages[page.id];
        return pageLookup.menu;
      },

      getPathToPage(page) {
        let pageLookup = this.lookupPages[page.id];
        if (!pageLookup) {
          return [];
        }
        let menuLookup = this.lookupMenus[pageLookup.menu.id];
        if (!menuLookup) {
          return [];
        }

        let segments = [];
        if (menuLookup.parent) {
          segments.push(menuLookup.parent);
        }
        segments.push(menuLookup.menu);

        if (pageLookup.parent) {
          segments.push(pageLookup.parent);
        }
        segments.push(pageLookup.page);

        return segments;
      },

      buildPageFilters(page, dashboard, baseFilters = undefined) {
        let segments = this.getPathToPage(page);
        if (dashboard) {
          segments.push(dashboard);
        }
        let filters = JSON.parse(JSON.stringify(baseFilters || {}));
        for (let segment of segments) {
          if (
            !segment.context ||
            !segment.conditions ||
            segment.conditions.length == 0
          ) {
            continue;
          }
          if (!(segment.context in filters)) {
            filters[segment.context] = {
              conditions: segment.conditions.slice()
            };
          } else {
            if (!("conditions" in filters[segment.context])) {
              filters[segment.context].conditions = [];
            }

            let existingConditions = filters[segment.context].conditions;

            for (let cond of segment.conditions) {
              if (existingConditions.indexOf(cond) === -1) {
                existingConditions.push(cond);
              }
            }
          }
        }
        return filters;
      },

      buildPageLink(page) {
        return (
          "/" +
          this.getPathToPage(page)
            .map((segment) => segment.path)
            .filter((path) => path)
            .join("/")
        );
      },

      findPageFromPath(path) {
        if (path == "" || (path && path[0] != "/")) {
          path = "/" + path;
        }
        for (let pageId in this.lookupPages) {
          let page = this.lookupPages[pageId].page;
          let pagePath = this.buildPageLink(page);
          if (path == pagePath) {
            return page;
          }
        }

        return undefined;
      },

      findDefaultPage() {
        return this.pages.find((menu) => menu.pages.length > 0).pages[0];
      },

      findDashboardFromPage(page, context) {
        if (!context) {
          context = this.$store.dataContext.getFilteredContext();
        }

        if (page.conditions && page.conditions.length) {
          context = context.applyFilterByConditions(
            page.context,
            page.conditions
          );
        }

        for (let dashboard of page.dashboards) {
          //first, apply conditions
          let dashboardContext = context;
          if (dashboard.conditions && dashboard.conditions.length > 0) {
            dashboardContext = dashboardContext.applyFilterByConditions(
              dashboard.context,
              dashboard.conditions
            );
          }

          let records = dashboardContext.contexts[dashboard.context] || [];

          //apply dashboard rules
          if (dashboard.type == "none" && records.length != 0) {
            continue;
          }

          if (dashboard.type == "has-data" && records.length == 0) {
            continue;
          }

          if (dashboard.type == "single" && records.length != 1) {
            continue;
          }

          if (dashboard.type == "multiple" && records.length <= 1) {
            continue;
          }

          //check if this dashboard is need to be visible
          if (this.isDashboardVisible(dashboard, dashboardContext)) {
            return dashboard;
          }
        }
        return undefined;
      },

      isDashboardVisible(dashboard, context) {
        if (dashboard.visible === false) {
          return false;
        } else if (dashboard.visible) {
          for (let filter of dashboard.visible) {
            try {
              if (!context.checkFieldFilter(filter)) {
                return false;
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
              return false;
            }
          }
        }

        return true;
      },

      populateDashboardCardsFromSubPages(dashboard, context) {
        let cards = [];
        for (let card of dashboard.cards) {
          if (card.type == "EMBED") {
            let page = this.findPageFromPath(card.url);
            if (page) {
              let embededDashboard = this.findDashboardFromPage(page, context);
              if (embededDashboard) {
                cards.push({
                  ...card,
                  dashboard: embededDashboard
                });
                continue;
              }
            }
          }
          cards.push(card);
        }
        return cards;
      },

      createRelatedPageLink(page, context) {
        if (page.visible && this.findDashboardFromPage(page, context)) {
          return {
            id: page.id,
            title: page.title,
            icon: page.icon,
            sub: false,
            url: this.buildPageLink(page)
          };
        }
        return undefined;
      },

      findRelateedMenus(page,context) {
        let currentMenu = this.getMenuForPage(page);
        let currentMenuRelevant = currentMenu && context && context.filters && (currentMenu.context in context.filters) && context.filters[currentMenu.context][currentMenu.detailsField];
       
        let matchedMenus = [];
        if (currentMenuRelevant) {
          matchedMenus.push(currentMenu);
        }
        matchedMenus.push(...this.pages.filter((menu) => menu.visible));

        return matchedMenus;
      },

      findRelatedPages(page, context) {
        let matchedLinks = [];
        let pageLookup = page ? this.lookupPages[page.id] : undefined;
        let topPage = pageLookup && (pageLookup.parent ? pageLookup.parent : pageLookup.page) || page;
        
        for (let menu of this.findRelateedMenus(page,context)) {
          let menuLinks = [];
         
          for (let relatedPage of menu.pages) {
            if (relatedPage.visible === false) {
              continue;
            }
            let link = this.createRelatedPageLink(relatedPage, context);
            if (link) {
              if (menu.detailsField) {
                link.detailedPage = true;
              }  
              if (relatedPage == topPage) {
                link.active = true;
              }
              menuLinks.push(link);
            }
            //show subpages only for the selected page
              for (let subPage of relatedPage.subPages) {
                let subLink = this.createRelatedPageLink(subPage, context);
                
                if (subLink) {
                  if (menu.detailsField) {
                    subLink.detailedPage = true;
                  }
                  if (subPage == page) {
                    subLink.active = true;
                  }
                  menuLinks.push({ ...subLink, sub: true });
                }
              }
          }
          if (menuLinks.length) {
            matchedLinks.push({
              title: menu.title,
              group: true,
            });
            matchedLinks.push(...menuLinks);
          }
        }

        return matchedLinks;
      },

      createDefaultMenuItem() {
        return {
          id: uuid(),
          title: "תפריט",
          icon: "mdi-account",
          visible: true,
          path: "menu",
          context: "clients",
          conditions: [],
          menuItems: [],
          pages: [this.createDefaultPage("clients", "main")]
        };
      },

      createDefaultPage(context, pageType = "normal") {
        context = context || "clients";
        let pageTitles = {
          normal: "דף",
          main: "דף ראשי",
          sub: "דף משני"
        };

        return {
          id: uuid(),
          title: pageTitles[pageType] || "דף",
          icon: "mdi-book-open-page-variant",
          visible: true,
          path: pageType == "main" ? "" : "page",
          context: context,
          conditions: [],
          dashboards: [this.createDefaultDashboard(context)],
          subPages: []
        };
      },

      createDefaultDashboard(context) {
        context = context || "clients";
        return {
          id: uuid(),
          title: "כותרת",
          width: "100%",
          height: "600px",
          cols: 4,
          rows: 4,
          class: "",
          context: context,
          url: "",
          conditions: [],

          cards: [
            {
              type: "HTML",
              body: "הסבר על עמוד",
              width: 4,
              height: 1,
              color: "#DCEDC8",
              class: "pa-2"
            }
          ]
        };
      },

      removeMenu(menu) {
        let lookup = this.lookupMenus[menu.id];
        if (!lookup) {
          throw new Error("failed to find menu with id " + menu.id);
        }
        let list = lookup.parent ? lookup.parent.menuItems : this.pages;
        let index = list.indexOf(menu);
        if (index != -1) {
          list.splice(index, 1);
        }
      },

      moveMenu(menu, delta) {
        let lookup = this.lookupMenus[menu.id];
        if (!lookup) {
          throw new Error("failed to find menu with id " + menu.id);
        }
        let list = lookup.parent ? lookup.parent.menuItems : this.pages;
        let index = list.indexOf(menu);
        if (index != -1) {
          let newIndex = index + delta;
          if (newIndex != -1 && newIndex != list.length) {
            list.splice(index, 1);
            list.splice(newIndex, 0, menu);
          }
        }
      },

      removePage(page) {
        let lookup = this.lookupPages[page.id];
        if (!lookup) {
          throw new Error("failed to find page with id " + page.id);
        }
        let list = lookup.parent ? lookup.parent.subPages : lookup.menu.pages;
        let index = list.indexOf(page);
        if (index != -1) {
          list.splice(index, 1);
        }
      },

      movePage(page, delta) {
        let lookup = this.lookupPages[page.id];
        if (!lookup) {
          throw new Error("failed to find page with id " + page.id);
        }
        let list = lookup.parent ? lookup.parent.subPages : lookup.menu.pages;
        let index = list.indexOf(page);
        if (index != -1) {
          let newIndex = index + delta;
          if (newIndex != -1 && newIndex != list.length) {
            list.splice(index, 1);
            list.splice(newIndex, 0, page);
          }
        }
      },

      findTooltip(id) {
        return this.tooltipsLookup[id];
      },

      visitPages(callback) {
        Object.values(this.lookupPages).forEach((pageLookup) =>
          callback(pageLookup.page)
        );
      },

      visitDashboards(callback) {
        this.visitPages((page) =>
          page.dashboards.forEach((dashboard) => callback(dashboard, page))
        );
      },

      visitCards(callback) {
        this.visitDashboards((dashboard, page) =>
          dashboard.cards.forEach((card) => callback(card, dashboard, page))
        );
      },

      findAllTooltips() {
        let tooltips = [];
        this.visitCards((card, dashboard, page) => {
          if (card.tooltip) {
            tooltips.push({
              tooltip: card.tooltip,
              card: card,
              dashboard: dashboard,
              page: page
            });
          }
          if (card.fields && Array.isArray(card.fields)) {
            for (let field of card.fields) {
              if (field.tooltip) {
                tooltips.push({
                  tooltip: field.tooltip,
                  card: card,
                  dashboard: dashboard,
                  page: page
                });
              }
            }
          }
        });
        return tooltips;
      }
    }
  });

  return storeModule;
}
