<template>
  <img :src="imagePath" :height="size" :style="style" alt="company logo" />
</template>

<script>
export default {
  props: {
    kod_mezahe_yatzran: { type: [Number, String] },
    size: {
      type: Number,
      default: 25,
      validator: (v) => v >= 14 && v <= 64
    },
    middle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageSize() {
      if (this.size > 48) {
        return 64;
      } else if (this.size > 32) {
        return 48;
      } else if (this.size > 25) {
        return 32;
      } else {
        return 25;
      }
    },
    imagePath() {
      return `/images/logo/${this.imageSize}x${this.imageSize}/${this.kod_mezahe_yatzran}.png`;
    },
    style() {
      if (this.middle) {
        return {
          verticalAlign: "middle"
        };
      }
      return {};
    }
  }
};
</script>

<style></style>
