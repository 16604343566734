<template>
  <v-autocomplete
    dense
    hide-details
    :label="label"
    :items="filters"
    multiple
    :value="value"
    @input="$emit('input', $event)"
    outlined
    :value-comparator="compareFilters"
    :readonly="readonly"
  >
    <template v-slot:selection="{ index }">
      <condition-chip
        :value="value[index]"
        :can-remove="!readonly"
        small
        @input="toggleCondition(value[index], $event)"
        @remove="removeCondition(value[index])"
      ></condition-chip>
    </template>
    <template v-slot:item="{ item }">
      <condition-chip small :value="item"></condition-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import ConditionChip from "./ConditionChip.vue";
export default {
  components: { ConditionChip },
  props: {
    value: { type: Array },
    readonly: { type: Boolean, default: false },
    label: { type: String, default: "סינון נתונים" },
    items: { type: Array, default: undefined }
  },
  computed: {
    filters() {
      return this.items || this.$store.filtersAsItems;
    }
  },
  methods: {
    removeCondition(condition) {
      if (!this.readonly) {
        this.$emit(
          "input",
          this.value.filter((c) => c != condition)
        );
      }
    },
    toggleCondition(oldValue, newValue) {
      if (!this.readonly) {
        this.$emit(
          "input",
          this.value.map((c) => (c == oldValue ? newValue : c))
        );
      }
    },
    compareFilters(a, b) {
      if (typeof a == "string" && typeof b == "string") {
        let fixedA = a.startsWith("!") ? a.substr(1) : a;
        let fixedB = b.startsWith("!") ? b.substr(1) : b;
        return fixedA == fixedB;
      }
      return false;
    }
  },
  async mounted() {
    if (!this.filters || this.filters.length == 0) {
      await this.$actions.loadFilters();
    }
  }
};
</script>

<style></style>
