<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <v-select
            dense
            outlined
            v-model="card.palette"
            :items="palettes"
            label="צבע גרף"
            hide-details
            return-object
            :item-value="(palette) => stringify(palette)"
          >
            <template v-slot:selection="{ item }">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-content: center;
                "
              >
                <palette-chip
                  width="100%"
                  height="10px"
                  :palette="item"
                ></palette-chip>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-content: center;
                "
              >
                <palette-chip
                  width="100%"
                  height="10px"
                  :palette="item"
                ></palette-chip>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <field-selector
            label="קיבוץ לפי שדה"
            v-model="card.categoryField"
            :context="card.context"
            nullable
          >
          </field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <complex-string-editor
            v-model="card.categoryTitle"
            @start-edit="startEditComplexString(card, 'categoryTitle')"
            label="כותרת לקבוץ שדה"
          ></complex-string-editor>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-select
            label="מקרא"
            v-model="card.legend"
            :items="legendPositions"
            dense
            outlined
            hide-details
          >
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-switch
            class="mt-0"
            inset
            dense
            hide-details
            :value="card.showValuesInLegend"
            @change="toggleLegendValues()"
            label="הצג ערכים במקרא"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <ordered-list-editor
            key="list"
            :list="card.valueFields"
            new-item-label="הוסף עמודה"
            :create-new-item="newListField"
          >
            <template v-slot="{ item }">
              <v-row dense>
                <v-col>
                  <field-selector
                    v-model="item.field"
                    :context="card.context"
                  ></field-selector>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <aggregation-selector
                    v-model="item.aggregation"
                  ></aggregation-selector>
                </v-col>
              </v-row>
              <v-row dense
                ><v-col>
                  <complex-string-editor
                    v-model="item.display"
                    @start-edit="startEditComplexString(item, 'display')"
                    label="כותרת לקבוץ שדה"
                  ></complex-string-editor>
                </v-col>
              </v-row>
            </template>
          </ordered-list-editor>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AggregationSelector from "../AggregationSelector.vue";
import ComplexStringEditor from "../ComplexStringEditor.vue";
import FieldSelector from "../FieldSelector.vue";
import OrderedListEditor from "../OrderedListEditor.vue";
import PaletteChip from "../PaletteChip.vue";

export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {
    AggregationSelector,
    ComplexStringEditor,
    FieldSelector,
    OrderedListEditor,
    PaletteChip
  },
  data() {
    return {
      palettes: [
        { from: "#1B5E20", to: "#9CCC65" }, //green
        { from: "#B71C1C", to: "#EF5350" }, //red
        { from: "#E65100", to: "#FFA726" }, //orange
        { from: "#01579B", to: "#29B6F6" }, //blue
        { from: "#311B92", to: "#9575CD" }, //purple
        ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"], // palette1
        ["#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800"], // palette2
        ["#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B"], // palette3
        ["#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A"], // palette4
        ["#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7"], // palette5
        ["#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D"], // palette6
        ["#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044"], // palette7
        ["#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD"], // palette8
        ["#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF"], // palette9
        ["#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2"] // palette10
      ],
      legendPositions: [
        { value: false, text: "ללא" },
        { value: "top", text: "למעלה" },
        { value: "bottom", text: "למטה" },
        { value: "left", text: "שמאל" },
        { value: "right", text: "ימין" }
      ]
    };
  },
  methods: {
    newListField() {
      let context = this.$store.dataContext.getContextType(this.card.context);
      let field = context.keyField;
      return {
        field: field,
        aggregation: "first"
      };
    },
    stringify(obj) {
      return JSON.stringify(obj);
    },
    toggleLegendValues() {
      this.$set(this.card, "showValuesInLegend", !this.card.showValuesInLegend);
    },
    startEditComplexString(item, field) {
      this.$emit("edit-complex-string", item, field);
    }
  }
};
</script>

<style></style>
